import React, { Component } from "react";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import SyncAltIcon from '@mui/icons-material/SyncAlt';

class RiderListItemMenu extends Component {
    constructor(props) {
        super(props)
        this.state = {
            
        }
    }

    render() {
        return(
            <li className={"riderListMenu"}> 
                <ul>
                    <li>
                        <span>RIDER ID:&nbsp;</span>{this.props.rider.id}
                    </li>
                    <li>
                        <span>FIRST NAME:&nbsp;</span>{(this.props.rider.firstname).toUpperCase()}
                    </li>
                    <li>
                        <span>LAST NAME:&nbsp;</span>{(this.props.rider.lastname).toUpperCase()}
                    </li>
                </ul>
                <button data-id={this.props.rider.id}
                    data-address-id={this.props.rider.address}
                    title={`Book a trip for ${this.props.rider.lastname}, ${this.props.rider.firstname} ${this.props.rider.middlename == null ? "" : this.props.rider.middlename} (${this.props.rider.id})`.toUpperCase()}
                    onClick={() => {
                        this.props.openFormClick(this.props.rider)
                        }
                    }>
                    BOOK A TRIP FOR RIDER {this.props.rider.id}
                    <AddCircleIcon/>
                </button>
                <button
                    title={`Update Rider ${this.props.rider.lastname}, ${this.props.rider.firstname} ${this.props.rider.middlename == null ? "" : this.props.rider.middlename} (${this.props.rider.id}) Details`.toUpperCase()}
                    onClick={() => {
                        this.props.openUpdateRiderModal(this.props.rider)
                        }
                    }>
                    UPDATE RIDER {this.props.rider.id} PROFILE
                    <SyncAltIcon/>
                </button>
                <fieldset>
                    <button onClick={() => {
                        this.props.openRiderDashboard(this.props.rider)
                    }}>
                        VIEW PT1(s)
                        <DoubleArrowIcon />
                    </button>
                    <button onClick={() => {
                        this.props.addPT1(this.props.rider)
                        this.props.shrinkPanel()
                        }
                    }>
                        ADD PT1
                        <FileUploadIcon />
                    </button>
                </fieldset>
            </li>
        )
    }
}

export default RiderListItemMenu