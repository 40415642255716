import React from 'react'

function LevelOfService(props){
    return (
        <>
            <label htmlFor={"service"}><span>*</span> Service Level:</label>
            <select 
                name={"service"} 
                onChange={(e) => props.onChange(e.target)}
                onBlur={(e) => props.onBlur(e.target)}
                defaultValue={"RTR"}
                required>
                <option value={"DTD"}>Door To Door</option>
                <option value={"CTC"}>Curb To Curb</option>
                <option value={"RTR"}>Room To Room</option>
                <option value={"LTL"}>Lobby To Lobby</option>
            </select>
        </>
    )
}

export default LevelOfService