import React, { Component } from "react"
import axiosInstance from "../../axios";
import LocationModal from "../modals/LocationModal"
import SearchInput from "../partsAndFields/SearchInput"

class LocationPicker extends Component {
    constructor(props) {
        super(props)
        this.state = {
            currentLocations: [],
            modalVisible: false,
            optionsLoaded: false,
            selectedLocation: this.props.defaultLocation,
            selectedLocationId: this.props.defaultLocationId,
            addNewAddressVisible: false,
            typedLocation: "",
            updatedFromDefault: false,
            filterOut: [""],
        }
    }

    componentDidMount() {
        let prevState = this.state
        if (this.state.updatedFromDefault === false){
            this.getCurrentLocations()
            prevState.selectedLocation = this.props.defaultLocation
            prevState.selectedLocationId = this.props.defaultLocationId            
        }
        this.setState(prevState)
    }

    componentDidUpdate(prevProps){        
        if (this.props.filterOut !== undefined 
            && prevProps.filterOut !== this.props.filterOut){
            let prevState = this.state
            prevState.filterOut = [...new Set([...this.props.filterOut, ...prevState.filterOut])]
            this.setState(prevState)
        }        
    }

    /**
     * Pass the target to parent renderer
     * @param {EventTarget} target 
     */
    passToNextChild(target) {
        this.setState({selectedLocation: target}, () => {
            this.props.getLocationFk(target)
        })
    }

    async getCurrentLocations() {
        await axiosInstance.get("places/frequent")
            .then(response => {
                this.setState({ currentLocations: response.data.places })
                
                if (this.props.selectedLocationId !== "" && this.state.updatedFromDefault === false){
                    for (let place of response.data.places){
                        if (parseInt(place.id) === parseInt(this.props.defaultLocationId)){
                            const newLocation = `${place.address0 !== "" ? `${place.address0}, `: ""}${place.address1 !== "" ? `${place.address1}, `: ""}${(place.address2 !== "" ? `${place.address2}, ` : "")}${place.city}, ${place.state} ${place.zipcode}`
                            this.setState({selectedLocation : newLocation})
                        }
                    }
                }
                
                this.setState({ optionsLoaded: true })
            })
            .catch(response => console.log(response))
    }

    /**
     * Change the visibility of the LocationModal
     * @param {Event} addToListButton The button node for opening the modal
     */
    openLocationModal(addToListButton) {
        addToListButton.preventDefault();
        this.setState({ modalVisible: !this.state.modalVisible });
    }

    updateSelectedVisibility(locations, id){
        const newLocation = `${locations.address0 !== "" ? `${locations.address0}, `: ""}${locations.address1 !== "" ? `${locations.address1}, `: ""}${(locations.address2 !== "" ? `${locations.address2}, ` : "")}${locations.city}, ${locations.state} ${locations.zipcode}`
        const prevState = this.state
        prevState.selectedLocation = newLocation
        prevState.selectedLocationId = id
        prevState.updatedFromDefault = true
        this.setState(prevState)
    }


    onAddLocation(){
        this.checkElement(document.getElementsByName(this.props.name)[0], true)
        this.setState({addNewAddressVisible : false})
        this.setState({optionsLoaded : false})
        this.getCurrentLocations()
    }

    /**
     * 
     * @param {Boolean} visibility 
     */
     addNewAddressButton(visibility, typedValue = ""){
        let prevState = this.state
        prevState.addNewAddressVisible = visibility
        prevState.typedLocation = typedValue
        this.setState(prevState);

    }


    checkElement(target, valid=false){
        if (valid){
            target.setCustomValidity("")
            target.classList.remove("errorField")
            target.parentElement.previousSibling.querySelector("strong") !== null && target.parentElement.previousSibling.querySelector("strong").remove()
        } else {
            if (target.value === "" && this.props.required === false){
                target.setCustomValidity("")
                target.classList.remove("errorField")
                target.parentElement.previousSibling.querySelector("strong") !== null && target.parentElement.previousSibling.querySelector("strong").remove()
            } else {
                const selectError = document.createElement("strong")
                selectError.innerHTML = " - You must select an option or add a new address below"
                target.parentElement.previousSibling.querySelector("strong") === null && target.parentElement.previousSibling.appendChild(selectError)
                target.classList.add("errorField")
                target.setCustomValidity("You must select an option or add a new address below")
            }
        }
    }

    render() {
        return (
            <fieldset className={"locationFields"}>
                {this.state.modalVisible && (
                    <LocationModal
                            toggle= {this.state.modalVisible}
                            updateId={this.props.updateId}
                            addressType={this.props.name}
                            handleClick= {this.openLocationModal.bind(this)}
                            updateSelectedState={this.passToNextChild.bind(this)}
                            updateSelectedVisibility={this.updateSelectedVisibility.bind(this)}
                            onAddLocation= {this.onAddLocation.bind(this)} 
                            firstAddress={this.state.typedLocation} />
                )}
                <label htmlFor={"addresses-input"}>{this.props.requiredSymbol && <span>*</span> }{`${this.props.type} Address: `}</label>
                {/* map locations in the state to the datalist abbreviated, data-value will the fk to get */}
                {this.state.optionsLoaded && (
                    <SearchInput
                        name={this.props.name}
                        type={this.props.tripNumber}
                        dataPointer={this.props.dataPointer}
                        onChange={this.passToNextChild.bind(this)}
                        currentValue={this.state.selectedLocation}
                        currentId={this.state.selectedLocationId}
                        insertNewOptionButton={this.addNewAddressButton.bind(this)}
                        errorCheck={this.checkElement.bind(this)}
                        required={this.props.required}
                        options={
                            this.state.currentLocations
                            .filter(location => !this.state.filterOut.includes(location.id))
                            .map(locations => {
                                return(
                                    {
                                        name: `${locations.address0 !== "" ? `${locations.address0}, `: ""}${locations.address1 !== "" ? `${locations.address1}, `: ""}${(locations.address2 !== "" ? `${locations.address2}, ` : "")}${locations.city}, ${locations.state} ${locations.zipcode}`,
                                        value: locations.id
                                    }
                                )}
                            )
                        }/>
                        )}
                {this.state.addNewAddressVisible 
                    && <button value={"Add"} onClick={(e) => this.openLocationModal(e)}>Add New Address</button> }
            </fieldset>
        )
    }
}

export default LocationPicker