import React, { Component } from "react";
import Steps from "../partsAndFields/Steps";
import RiderPT1sPanel from "./RiderPT1sPanel";
import RiderItinsPanel from "./RiderItinsPanel";
import axiosInstance from "../../axios";


class RiderDashboard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            visible:{
                pt1s:true,
                trips:false,
            },
            pt1s:[],
        }
    }
    componentDidMount(){
        this.getPT1s()
    }
    
    addPT1(rider){
        this.props.addPT1(rider)
    }

    toTrips(){
        let newState = this.state

        // update visibility
        newState.visible.pt1s = false
        newState.visible.trips = true

        this.setState(newState)
    }

    toPt1(){
        let newState = this.state

        // update visibility
        newState.visible.pt1s = true
        newState.visible.trips = false

        this.setState(newState)
    }

    bookRider(rider){
        this.props.bookRider(rider)
    }

    setPT1s(pt1s){
        this.setState({pt1s:pt1s})
    }

    async getPT1s(){
        let loader = document.createElement("div")
        loader.classList.add("loader")

        await axiosInstance.get(`pt1/get/rider-fk/${this.props.rider.id}`)
            .then(response => {  
                console.log(response.data.data);
                if (response.data.status === 404){
                    this.setState({ pt1s: false })
                } else {
                    this.setState({ pt1s: response.data.data })
                }                  
                loader.remove()
            })
            .catch(error => {
                console.log(error);
            })
    }

    onClickStep(key){
        switch (key){
            case 1:
                this.toPt1()
                break
            case 2:
                this.toTrips()
                break
            default:
                break
        }
    }

    render() {
        return(
            <section
                className={"riderDashboard"}>                    
                    {this.state.visible.pt1s 
                    && <> 
                        <Steps
                        numbers={false}
                        steps={["PT1'S",
                        "Trips"]}
                        selected={1}
                        onClickStep={this.onClickStep.bind(this)} />
                        <RiderPT1sPanel
                        rider={this.props.rider}
                        addPT1={this.addPT1.bind(this)}
                        PT1s={this.state.pt1s} />                 
                    </>}
                    {this.state.visible.trips
                    && <> 
                        <Steps
                        numbers={false}
                        steps={["PT1'S",
                        "Trips"]}
                        selected={2}
                        onClickStep={this.onClickStep.bind(this)} />
                        <RiderItinsPanel 
                           category={"upcoming"} 
                           rider={this.props.rider}
                           pt1s={this.state.pt1s} 
                           bookRider={this.bookRider.bind(this)} />
                    </>}
           </section>
        )
    }
}

export default RiderDashboard