import React, { Component } from "react";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { jsPDF } from "jspdf";

class FileUpload extends Component {
    constructor(props) {
        super(props)
        this.errors = React.createRef();
        this.state = {
            file:{},
            errors:[],
            success: false
        }
    }

    componentDidUpdate(){
        if (this.state.success){
            this.props.onUploadSuccess(this.state.file)
        }
    }

    isImage(filename){
        if ( /\.(jpe?g|png)$/i.test(filename) ) {
            return true
        }
        return false

    }

    validate(file){
        let newState = this.state
        newState.errors = []
        let validity = false
        if (/\.(jpe?g|png|pdf)$/i.test(file.name)) {
            validity = true
        }else {
            newState.errors.push("File must be in png, jpg, or pdf")
            validity = false
        }

        if (file.size < 10485760){
            validity = true
        } else {
            newState.errors.push("File must be less that 10 MB")
            validity = false
        }

        this.setState(newState)
        return validity
    }

    checkIfCorrectFile(file){
        if (this.validate(file)){
            if (this.isImage(file.name)) {
              this.convertToPDF(file)
            } else{
                this.setState({file:file}, () => {
                    this.setState({success:true})
                })
            }            
        } 
    }

    fitImage(image, document){
        const docWidth = document.internal.pageSize.getWidth();
        const docHeight = document.internal.pageSize.getHeight();
        let fittedDimensions = {
            width:docWidth,
            height: docHeight,}

        if (image.width >= docWidth){
            let ratio = docWidth/image.width
            fittedDimensions.width = docWidth
            fittedDimensions.height = image.height * ratio
        } 
        
        if (fittedDimensions.height > docHeight 
            && image.height >= docHeight){
            let ratio = docHeight/image.height
            fittedDimensions.height = docHeight
            fittedDimensions.width = image.width * ratio
        }
        return fittedDimensions
    }

    async convertToPDF(file){
        let HTMLImage = document.createElement("img")
        let imageLocation = URL.createObjectURL(file);
        
        HTMLImage.src = imageLocation

        HTMLImage.onload = () => {
            const doc = new jsPDF({
                orientation: "portrait",
                unit: "px"
              });
           
            let fittedDimensions = this.fitImage(HTMLImage, doc)
            
            doc.addImage(imageLocation, 
                file.type, 0,0, 
                fittedDimensions.width, 
                fittedDimensions.height)
            
            doc.setProperties({ title: 'pt1' })

            const convertedFile = new Blob([ doc.output("blob") ], { 
                name: 'pt1',
                type : 'application/pdf'})

            convertedFile.name = 'pt1'
            
            this.setState({file:convertedFile}, () => {
                this.setState({success:true})
            })
        }
    }

    render() {
        return(
            <>
                <label 
                    htmlFor={"filename"}
                    className={"fileUploadLabel"}>
                    <FileUploadIcon/>
                    <span>
                        <b>Upload your PT1 in</b> png, jpg, or pdf (10MB max)
                    </span>
                </label>
                <input 
                    type={"file"} 
                    name={"filename"}
                    id={"filename"} 
                    className={"fileUpload"}
                    accept={".png,.jpg,.jpeg,.pdf"}
                    onChange={(e) => {
                        this.checkIfCorrectFile(e.target.files[0])}}/>
                
                <span ref={this.errors}>{this.state.errors.map(error =>{
                    return error
                })}</span>
            </>
        )
    }
}

export default FileUpload