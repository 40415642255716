import React, { Component } from "react";
import DetailsModal from "../modals/DetailsModal";

class ItinRow extends Component {
    constructor(props) {
        super(props)
        this.state = {
            modalVisible:false,
            page:this.props.page,
        }
    }


    toggleModal(){
        this.setState({modalVisible: !this.state.modalVisible})
    }

    updateOnClose(tripId){
        this.props.updateItins(tripId)
        this.toggleModal()
    }

    render(){
        return(
            <tr id={this.props.currentItins.id}>
                <td>
                    <ul>
                        <li>{`${this.props.currentItins.trips[0].rider.lastname},`}</li>
                        <li>{`${this.props.currentItins.trips[0].rider.firstname} ${this.props.currentItins.trips[0].rider.middlename}`}</li>
                    </ul>
                </td>
                <td>{this.props.currentItins.trips[0].tripdate}</td>
                <td>
                    <ul>
                        <li>{`${this.props.currentItins.trips[0].pickup.city}, ${this.props.currentItins.trips[0].pickup.state}`}</li>
                        <li>{`${this.props.currentItins.trips[0].dropoff.city}, ${this.props.currentItins.trips[0].dropoff.state}`}</li>
                    </ul>
                </td>
                {this.props.abbreviateColumns === false && 
                    <td>
                        <ul>
                            <li>{this.props.currentItins.trips[0].ptime !== null ? this.props.currentItins.trips[0].ptime.substring(0,5) : "None"}</li>
                            <li>{this.props.currentItins.trips[0].dtime !== null ? this.props.currentItins.trips[0].dtime.substring(0,5) : "None"}</li>
                        </ul>
                    </td>
                }
                {this.props.abbreviateColumns === false && 
                    <td> {this.props.currentItins.trips[1] ? "RETURN" : "TAKE"} </td>
                    }
                {this.props.abbreviateColumns === false && 
                    <td>{this.props.currentItins.trips[0].status.toUpperCase()}</td>
                }
                <td>
                {this.state.modalVisible && 
                    <DetailsModal 
                        details={this.props.currentItins} 
                        toggle={this.state.modalVisible}
                        id={this.props.currentItins.id}
                        page={this.state.page}
                        handleClick={this.updateOnClose.bind(this)}/>}
                    <button onClick={(e) => this.toggleModal()}>i</button>
                </td>
            </tr>
            )
    }
}

export default ItinRow