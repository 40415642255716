import React, { Component } from "react"

class TimePicker extends Component{
    constructor(props){
        super(props)
        this.state = {
            availableHours: [...Array(24).keys()].slice(1),
            availableMinutes: ["0", "15", "30", "45"],
            timeNow: this.props.currentTime,
            selectedHour: this.props.defaultValue.substr(0,2),
            selectedMinute: this.props.defaultValue.substr(3,2),
            readOnly: this.props.readOnly,
            tabIndex: this.props.readOnly ? -1 : 0,
            noPointClass: this.props.readOnly ? "noPoint" : "",
        }
    }

    componentDidUpdate(){
        let newState = this.state
        if(this.props.readOnly !== this.state.readOnly){
            newState.readOnly = this.props.readOnly
            newState.tabIndex = this.props.readOnly ? -1 : 0
            newState.noPointClass = this.props.readOnly ? "noPoint" : ""
            this.setState(newState)
        }
    }

    async updateParent(target){
        await this.props.collectValue(target.parentElement)
    }

    twentyFourFormantToTwelveHourFormat(hour){
        let ampm = (hour < 12 || hour === 24) ? "AM" : "PM"
        return ` [ ${(hour % 12 || 12)}${hour.toString().substr(2, 3)}:${this.state.selectedMinute === "59" ? "00" : this.state.selectedMinute} ${ampm} ]`
    }

    render(){
        return( 
            <>      
            <fieldset 
                className={"timePicker"} 
                name={this.props.name}
                data-value={`${this.state.selectedHour}:${this.state.selectedMinute}`}
                value={`${this.state.selectedHour}:${this.state.selectedMinute}`}
                data-id={this.props.datasetId}
                readOnly={this.state.readOnly} >


                <label 
                    className={"semanticVisible"} 
                    htmlFor={`${this.props.name}_hour`}>Hour</label>
                <select
                    name={`${this.props.name}_hour`}
                    defaultValue={this.state.selectedHour}
                    onChange= {(e) => this.setState({selectedHour: e.target.value}, 
                        async () => await this.updateParent(e.target)
                    )}
                    required={this.props.required}
                    readOnly={this.state.readOnly} 
                    tabIndex={this.state.tabIndex}
                    className={this.state.noPointClass} >
                    <option disabled value={""}>-- select hour --</option>
                    {this.state.availableHours
                    .filter(hour => hour >= parseInt(this.props.minTime.substr(0,2)))
                    .map(hour => {
                        return(
                            <option 
                                key={hour} 
                                value={hour <= 9 ? `0${hour}` : hour}>{hour <= 9 ? `0${hour}` : hour}</option>)
                        }
                    )}
                </select>

                <span>:</span>

                <label 
                    className={"semanticVisible"} 
                    htmlFor={`${this.props.name}_minute`}>Minute</label>
                <select
                    name={`${this.props.name}_minute`}
                    defaultValue={this.state.selectedMinute}
                    onChange= {(e) => this.setState({selectedMinute: e.target.value},
                        async () => await this.updateParent(e.target)
                    )}
                    required={this.props.required}
                    readOnly={this.state.readOnly}
                    tabIndex={this.state.tabIndex} 
                    className={this.state.noPointClass}>
                        <option disabled value={""}>-- select minute --</option>
                    {(this.state.selectedHour === this.state.timeNow.substr(0,2) )
                    ? 
                        this.state.availableMinutes
                        .filter(minutes => minutes  >= parseInt(this.props.minTime.substr(3,2)))
                        .map(minute => {
                            return(<option key={minute} value={minute <= 9 ? `0${minute}` : minute}>{minute <= 9 ? `0${minute}` : minute}</option>)
                        })
                    : 
                        this.state.availableMinutes
                        .map(minute => {
                            return(<option key={minute} value={minute <= 9 ? `0${minute}` : minute}>{minute <= 9 ? `0${minute}` : minute}</option>)                        })
                    }
                </select>
                {this.state.selectedHour !== "" && <span>{`${this.twentyFourFormantToTwelveHourFormat(this.state.selectedHour)}`}</span>}                
            </fieldset>            
            </>
        )
    }
}

export default TimePicker