import React, { Component } from "react";
import LocationPicker from "../partsAndFields/LocationPicker";
import {
    validateMemberId,
    validateAboveZeroNumber,
} from "../../validation.js"

class AddPT1Form extends Component {
    constructor(props) {
        super(props);
        this.submitForm = this.submitForm.bind(this);
        this.state = {
            // massHealthServices
            newPT1: {
                rider:parseInt(this.props.rider.id),
                member_id:this.props.rider.mh_member_id,
                dob:this.props.rider.dob,
                sex:this.props.rider.sex,
                home_address:"",
                apu_address:"",
                provider_address:"",
                date_created:this.getDate(0),
                require_service_weeks:"",
                require_service_months:"",
                visit_per_week:"",
                visit_per_month:"",
                authorized_expired_date:"",
                authorized_date:this.getDate(0),
                mhsignature:false,
            },
            treatmentDurationType:"",
            treatmentFrequency: "",
        }
    }

    componentDidMount(){
        this.resize()
        document.getElementsByClassName("bookingForm")[0].focus()
        let stateCopy = this.state
        if (this.props.file.name !== undefined){
            stateCopy.newPT1.file = this.props.file
        }
        this.setState(stateCopy)
    }

    resize() {
        if (window.innerWidth <= 760){
            this.setState({mobile: true})
        } else {
            this.setState({mobile: false})
        }
    }

    treatmentTypesArrayToString(){
        let pt1StateCopy = this.state.newPT1
        pt1StateCopy.treatment_type = pt1StateCopy.treatment_type.join()
        this.setState({newPT1:pt1StateCopy})
    }

    async submitForm(formSubmitButton) {
        formSubmitButton.preventDefault()
        const form = formSubmitButton.target.parentElement
        
        if (!this.checkForm(form)){
            formSubmitButton.target.disabled = true
            

            // if (typeof this.state.treatment_type !== 'string'){
            //     await this.treatmentTypesArrayToString()
            // }

            const finalPT1 = this.convertToFormData()
            this.props.submit(finalPT1, formSubmitButton)
        }
    }

    convertToFormData(){
        let formData = new FormData()
        let pt1Data = this.state.newPT1
        for (let data of Object.keys(pt1Data)){
            formData.append(data, pt1Data[data])
        }
        return formData
    }

    checkForm(form){
        let notReady = false

        form.checkValidity() ? notReady = false : notReady = true

        if (this.state.newPT1.approved === false){
            notReady = true   
        }
        
        form.reportValidity()
        return notReady
    }

    /**D
     * Update state with form values
     * @param {EventTarget} target the change target of the form field
     */
    collectFormValues(target) {
        let stateCopy = this.state
        switch (target.name) {
            // section 1
            case "memberId":
                if (validateMemberId(target)){
                    stateCopy.newPT1.member_id = target.value
                }
                break
            case "dob":
                stateCopy.newPT1.dob = target.value
                break
            case "sex":
                stateCopy.newPT1.sex = target.value
                break
            case "homeAddress":
                stateCopy.newPT1.home_address = parseInt(target.dataset.value)
                break
            case "altPickupAddress":
                stateCopy.newPT1.apu_address = parseInt(target.dataset.value)
                break

            case "facilityProviderAddress":
                stateCopy.newPT1.provider_address = parseInt(target.dataset.value)
                break

            // section 2
            case "treatmentDuration":
                stateCopy.treatmentDurationType = target.value
                break
            case "requiredServiceWeeks":                
                if (validateAboveZeroNumber(target)){
                    stateCopy.newPT1.require_service_weeks = parseInt(target.value)
                }
                stateCopy.newPT1.require_service_months = ""
                stateCopy.newPT1.visit_per_month = ""
                break
            case "requiredServiceMonths":
                if (validateAboveZeroNumber(target)){
                    stateCopy.newPT1.require_service_months = parseInt(target.value)
                }
                stateCopy.newPT1.require_service_weeks = ""
                stateCopy.newPT1.visit_per_week = ""
                break
            case "treatmentFrequency":
                stateCopy.treatmentFrequency = target.value
                break
            case "visitPerWeek":
                stateCopy.newPT1.visit_per_week = parseInt(target.value)
                break
            case "visitPerMonth":
                stateCopy.newPT1.visit_per_month = parseInt(target.value)
                break
            
            
            // bottom of sheet
            case "authorizedExpiredDate":
                stateCopy.newPT1.authorized_expired_date = target.value
                break
            case "massHealthSignature":
                stateCopy.newPT1.mhsignature = target.checked
                break
            case "authorizedDate" :
                stateCopy.newPT1.authorized_date = target.value
                break

            default:
                console.log(`error on ${target.name}`)
        }
        this.setState(stateCopy)
    }

    /**
     * 
     * @param {Number} ahead how far ahead in days the date should be
     * @returns String date in 0000-00-00 (y-d-m) format
     */
    getDate(ahead = 0){
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() + ahead);
        return `${currentDate.getFullYear()}-${('0' + (currentDate.getMonth() + 1)).slice(-2)}-${('0' + currentDate.getDate()).slice(-2)}`
    }

    render() {
        return (
            <section className={"form bookingForm addPT1Form "} id={"addPT1Form"}>
               <form 
                    onSubmit={(e) => {
                        this.submitForm(e)
                        }}
                    onKeyPress={e => {
                        e.key === 'Enter' && e.preventDefault(); 
                }}>
                <fieldset>
                    <legend>1. General Information</legend>

                    {/* <label htmlFor={"dob"}
                        id={"dob"}><span>*</span> Date of birth:</label>
                    <input 
                        type={"date"} 
                        name={"dob"} 
                        max={this.getDate(0)}
                        onChange={(e) => this.collectFormValues(e.target)}
                        onBlur={(e) => this.collectFormValues(e.target)}
                        required />
                    
                    <fieldset className={"radioContainer"}>
                            <legend><span>*</span> Sex:</legend>
                            <input type={"radio" }
                                name={"sex" }
                                onChange={(e) => this.collectFormValues(e.target)}
                                onBlur={(e) => this.collectFormValues(e.target)}
                                value={"male"}
                                required={true}/>
                            <label htmlFor={"sex"}>Male</label>

                            <input type={"radio" }
                                name="sex" 
                                onChange={(e) => this.collectFormValues(e.target)}
                                onBlur={(e) => this.collectFormValues(e.target)}
                                value={"female"}/>
                            <label htmlFor={"sex"}>Female</label>
                    </fieldset>

                    <label htmlFor={"memberId"}
                        id={"memberId"}>
                        <span>*</span> MassHealth Member ID:</label>
                    <input 
                        type={"text"} 
                        name={"memberId"} 
                        onChange={(e) => this.collectFormValues(e.target)}
                        onBlur={(e) => this.collectFormValues(e.target)}
                        required /> */}

                    <LocationPicker 
                        type={"Home [Pick-up]"} 
                        tripNumber={1}
                        key={"homeAddress"}
                        requiredSymbol={true}
                        getLocationFk={this.collectFormValues.bind(this)} 
                        name={"homeAddress"}
                        filterOut={[this.state.newPT1.apu_address]}
                        required={true} />
                    
                    <LocationPicker 
                        type={"Alternate Pick-up"} 
                        tripNumber={1}
                        key={"altPickupAddress"}
                        requiredSymbol={false}
                        getLocationFk={this.collectFormValues.bind(this)} 
                        name={"altPickupAddress"}
                        filterOut={[this.state.newPT1.home_address]}
                        required={false} />                    

                    <LocationPicker 
                        type={"Facility Provider [Drop-off]"} 
                        tripNumber={1}
                        key={"facilityProviderAddress"}
                        requiredSymbol={true}
                        getLocationFk={this.collectFormValues.bind(this)} 
                        name={"facilityProviderAddress"}
                        filterOut={[this.state.newPT1.home_address, this.state.newPT1.apu_address]}
                        required={true} />

                    </fieldset>
                    
                    <fieldset>
                        <legend>2. Duration and Frequency of Treatment</legend>
                        <fieldset className={"radioContainer"}>
                            <legend><span>*</span> How long will the MassHealth member require these services?</legend>
                            <input type={"radio" }
                                name={"treatmentDuration" }
                                onChange={(e) => this.collectFormValues(e.target)}
                                onBlur={(e) => this.collectFormValues(e.target)}
                                value={"weeks"}
                                required={true}/>
                            <label htmlFor={"treatmentDuration"}>Week(s)</label>

                            <input type={"radio" }
                                name="treatmentDuration" 
                                onChange={(e) => this.collectFormValues(e.target)}
                                onBlur={(e) => this.collectFormValues(e.target)}
                                value={"months"}/>
                            <label htmlFor={"treatmentDuration"}>Month(s)</label>
                        </fieldset>
                        
                        {this.state.treatmentDurationType === "weeks" 
                        && <>
                        <label htmlFor={"requiredServiceWeeks"}
                            id={"requiredServiceWeeks"}>
                                <span>*</span> week(s):</label>
                        <input 
                            type={"text"} 
                            min={"1"}
                            name={"requiredServiceWeeks"} 
                            onChange={(e) => this.collectFormValues(e.target)}
                            onBlur={(e) => this.collectFormValues(e.target)}
                            required />
                        </>}
                        
                        {this.state.treatmentDurationType === "months" 
                        && <>
                        <label htmlFor={"requiredServiceMonths"}
                            id={"requiredServiceMonths"}>
                                <span>*</span>month(s):</label>
                        <input 
                            type={"text"} 
                            min={"1"}
                            name={"requiredServiceMonths"} 
                            onChange={(e) => this.collectFormValues(e.target)}
                            onBlur={(e) => this.collectFormValues(e.target)}
                            required />
                        </>}  
                                              

                        {this.state.treatmentDurationType !== "" &&
                        <>
                        <fieldset>                            
                            {this.state.treatmentDurationType === "weeks"
                            && <>
                            <legend>How frequently will the MassHealth member be seen for this service?</legend>
                            <label htmlFor={"visitPerWeek"}>
                                    <span>*</span> visit(s) per week:</label>
                            <select 
                                name={"visitPerWeek"} 
                                onChange={(e) => this.collectFormValues(e.target)}
                                onBlur={(e) => this.collectFormValues(e.target)}
                                defaultValue={""}
                                required>
                            <option disabled value={""}> -- select a number -- </option>
                            {(Array.from({length: 7}, (_, i) => i + 1)).map(day => {
                                return <option key={day} value={day}>{day}</option>
                            })}                            
                            </select>
                            </>}
                            
                            {this.state.treatmentDurationType === "months"
                            &&  <>
                            <legend>How frequently will the MassHealth member be seen for this service?</legend>
                            <label htmlFor={"visitPerMonth"}>
                                    <span>*</span> visit(s) per month:</label>
                            <select 
                                name={"visitPerMonth"} 
                                onChange={(e) => this.collectFormValues(e.target)}
                                onBlur={(e) => this.collectFormValues(e.target)}
                                defaultValue={""}
                                required>
                            <option disabled value={""}> -- select a number -- </option>
                            {(Array.from({length: 31}, (_, i) => i + 1)).map(day => {
                                return <option key={day} value={day}>{day}</option>
                            })}                            
                            </select>
                            </>}
                        </fieldset>
                        </> }   
                    </fieldset>

                    <fieldset>
                    <legend>3. Approval Information</legend>

                        <label htmlFor={"authorizedExpiredDate"}><span>*</span> Authorization expires on:</label>
                        <input 
                            type={"date"} 
                            name={"authorizedExpiredDate"} 
                            min={
                                this.state.newPT1.require_service_weeks !== "" 
                                ? this.getDate(parseInt(this.state.newPT1.require_service_weeks) * 7)
                                : this.getDate(parseInt(this.state.newPT1.require_service_months) * 30)}
                            // min={this.getDate(7)}
                            onChange={(e) => this.collectFormValues(e.target)}
                            onBlur={(e) => this.collectFormValues(e.target)}
                            required />
                            
                        <label htmlFor="massHealthSignature"><em>
                            * I certify that this PT1 has been approved by Masshealth and acknowledge PT1s not approved by Masshealth will automatically be denied by ATS</em>
                        <input 
                            type={"checkbox"} 
                            name={"massHealthSignature"} 
                            onChange={(e) => this.collectFormValues(e.target)}
                            onBlur={(e) => this.collectFormValues(e.target)}
                            required />
                        </label>

                            <label htmlFor={"authorizedDate"}
                            id={"authorizedDate"}><span>*</span> Signature Date:</label>
                            <input 
                                type={"date"} 
                                name={"authorizedDate"}
                                max={this.getDate(0)}
                                defaultValue={this.getDate(0)}
                                onChange={(e) => this.collectFormValues(e.target)}
                                onBlur={(e) => this.collectFormValues(e.target)}
                                required />
                    </fieldset>

                    <input 
                        type={"submit"} 
                        value={"Add PT1"} 
                        id={"AddPT1FormSubmit"}
                        onClick={(e) => {
                            this.submitForm(e)
                            }}></input>
                  
                </form>
            </section>
        )
    }
}

export default AddPT1Form