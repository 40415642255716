import React, { Component } from "react"
import CloseIcon from '@material-ui/icons/Close';
import axiosInstance from "../../axios";
import TimePicker from "../partsAndFields/TimePicker";
import LocationPicker from "../partsAndFields/LocationPicker";
import {
    validatePhoneWithExtension,
    validateNotes,
} from "../../validation.js"

class DetailsModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            visibleDetails:[],
            updatedDetails: [],
            page:this.props.page,
        }
    }

    componentDidMount(){
        this.generateStateDetails()
    }

    generateStateDetails(){
        let stateCopy = this.state
        for (let tripIdPosition = 0; tripIdPosition <= (this.props.details.trips.length === 1 ? 0 : 1); tripIdPosition++){
            stateCopy.updatedDetails[tripIdPosition] = {
                id: parseInt(this.props.details.trips[tripIdPosition].id),
                rider_fk: parseInt(this.props.details.trips[tripIdPosition].rider.id),
                pu_place_fk: parseInt(this.props.details.trips[tripIdPosition].pickup.id),
                do_place_fk: parseInt(this.props.details.trips[tripIdPosition].dropoff.id),
                ptime: this.props.details.trips[tripIdPosition].ptime !== null ? this.props.details.trips[tripIdPosition].ptime.substr(0,5) : "",
                dtime: this.props.details.trips[tripIdPosition].dtime !== null ? this.props.details.trips[tripIdPosition].dtime.substr(0,5) : "",
                passengers: parseInt(this.props.details.trips[tripIdPosition].passengers),
                wheelchairs: parseInt(this.props.details.trips[tripIdPosition].wheelchairs),
                bringwheelchair: parseInt(this.props.details.trips[tripIdPosition].bringwheelchair),
                ridealone: this.props.details.trips[tripIdPosition].ridealone,
                notes: this.props.details.trips[tripIdPosition].notes,
                phone1: this.props.details.trips[tripIdPosition].phone1,
                phone2: this.props.details.trips[tripIdPosition].phone2,
                mode: this.props.details.trips[tripIdPosition].mode,
                service: this.props.details.trips[tripIdPosition].service,
                status: this.props.details.trips[tripIdPosition].status,                
            }
        }
        stateCopy.updatedDetails[0].pt1_fk = this.props.details.trips[0].pt1 === null ? "" : this.props.details.trips[0].pt1
        this.setState(stateCopy, () => this.generateDetails())
    }

    changeToEditable(target, fieldType){
        switch(fieldType){
            case "input":
                target.readOnly = false
                target.focus()
                break
            case "select":
                target.disabled = false
                target.focus()
                break
            case "textarea":
                target.readOnly = false
                target.focus()
                break
            default:
                console.log("Error not a field");
        }        
    }

    collectValue(target){
        let stateCopy = this.state
        let field = document.getElementsByName(target.name)[!target.parentElement ? parseInt(target.dataset.id)  : parseInt(target.parentElement.dataset.id)]
        const tripStateNumber = !target.parentElement ? parseInt(target.dataset.id)  : parseInt(target.parentElement.dataset.id)
        let updatedDetailsState = stateCopy.updatedDetails[tripStateNumber]
        switch(target.name){
            case "trip_status":
                break
            case "phone1":
                if (validatePhoneWithExtension(field, target, "phone1Label")){
                    updatedDetailsState.phone1 = target.value
                }
                break
            case "phone2":
                if (validatePhoneWithExtension(field, target, "phone2Label")){
                    updatedDetailsState.phone2 = target.value
                }
                break
            case `do_place_fk${tripStateNumber}`:
                updatedDetailsState.do_place_fk = parseInt(target.dataset.value)         
                break
            case `pu_place_fk${tripStateNumber}`:
                updatedDetailsState.pu_place_fk = parseInt(target.dataset.value)            
                break
            case `dtime${tripStateNumber}`:
                    updatedDetailsState.dtime = target.dataset.value
                break
            case `ptime${tripStateNumber}`:
                    updatedDetailsState.ptime = target.dataset.value
                break
            case "service":
                updatedDetailsState.service = target.value
                break
            case "mode":
                updatedDetailsState.mode = target.value
                break
            case "passengers":
                updatedDetailsState.passengers = target.value
                break
            case "notes":
                if (validateNotes(field, target, "notes")){
                    updatedDetailsState.notes = target.value
                }
                break
            default:
                console.log(`Error on ${target.name}`);
        }
        this.setState(stateCopy)
    }


    async postUpdate(position){
        const finalUpdate = {...this.state.updatedDetails[position]}
        
        if (finalUpdate.ptime === ""){
            finalUpdate.ptime = null
        }

        if (this.checkForm(finalUpdate)){
            let loader = document.createElement("div")
            loader.classList.add("loader")
            document.body.appendChild(loader)
            await axiosInstance.post(
                "trips/update", finalUpdate)
                .then(() => {
                    loader.remove()
                })
                .catch(response => {
                    console.log(response)
                    loader.remove()
                })
        }
    }

    checkForm(finalUpdate){
        let readiness = true

        // if (isTripDateAndTimeAfter2pmAtLeastADayBeforeSelectedDay(finalUpdate.start_date) == true){
        //     readiness = false
        // }

        return readiness
    }

    getTime(){
        const today = new Date();
        const minutes = (Math.round(today.getMinutes()/15) * 15) % 60
        return `${(today.getHours() <= 9 ? `0${today.getHours()}` : today.getHours())}:${minutes === 0 ? `${minutes}0` : minutes}`
    }

    generateDetails(){
         let details = this.props.details.trips.map(key => {
             return(
                <article 
                    key={key.id} 
                    data-id={this.props.details.trips.indexOf(key)} 
                    className={key.status.toUpperCase() === "CANCELLED" ? "canceled" : ""} >
                    <p>Trip ID: {key.id}</p>                     
                    <label htmlFor={"leg"}>Type</label>
                    <input name={"leg"} value={key.leg} disabled/>               
                    <label htmlFor={"trip_status"}>Trip Status</label>
                    <input name={"trip_status"} value={key.status} disabled/> 
                    <label htmlFor={"created"}>Created</label>
                    <input name={"created"} value={`${new Date(key.created).toLocaleString('en-US', {timeZone:'America/New_York'})}`} disabled/>
                    <fieldset data-id={this.props.details.trips.indexOf(key)}>
                        <label htmlFor={"name"}>Name:</label>
                        <input name={"name"} value={`${key.rider.lastname}, ${key.rider.firstname} ${key.rider.middlename == null ? "" : key.rider.middlename} (${key.rider.id})`} disabled/>
                        <label htmlFor={"rider_status"}>Rider Status:</label>
                        <input name={"rider_status"} value={key.rider.status} disabled/>
                        <label htmlFor={"veteran"}>Veteran:</label>
                        <input name={"veteran"} value={key.rider.veteran} disabled/>
                        <label htmlFor={"pt1"}>PT1:</label>
                        <input name={"pt1"} value={key.pt1 !== "" ? key.pt1 : `No PT1 Attached`} disabled/>
                        <label 
                            htmlFor={"phone1"}
                            id={"phone1Label"} >Phone 1:</label>
                        <input 
                            name={"phone1"} 
                            defaultValue={key.phone1}
                            onClick={
                                (e) => {
                                    this.changeToEditable(e.target, "input")
                                }
                            }
                            onBlur={
                                (e) => {
                                    e.target.readOnly = true
                                }
                            }
                            onChange={
                                (e) => {
                                    this.collectValue(e.target)
                                }      
                            }
                            readOnly={true} />
                        <label 
                            htmlFor={"phone2"}
                            id={"phone2Label"}>Phone 2:</label>
                        <input 
                            name={"phone2"} 
                            defaultValue={key.phone2}
                            onClick={
                                (e) => {
                                    this.changeToEditable(e.target, "input")
                                }
                            }
                            onBlur={
                                (e) => {
                                    e.target.readOnly = true
                                }
                            }
                            onChange={
                                (e) => {
                                    this.collectValue(e.target)
                                }      
                            }
                        readOnly={true} />
                    </fieldset>

                    <fieldset data-id={this.props.details.trips.indexOf(key)}>
                        <label htmlFor={"tripdate"}>Date of Transportation</label>
                        <input name={"tripdate"} value={key.tripdate} disabled/>
                        <label 
                            htmlFor={`ptime${this.props.details.trips.indexOf(key)}`}
                            id={`ptime${this.props.details.trips.indexOf(key)}Label`} >Pickup time</label>
                        <TimePicker 
                            currentTime={this.getTime()} 
                            defaultValue={key.ptime !== null ? key.ptime.substr(0,5) : ""}
                            name={`ptime${this.props.details.trips.indexOf(key)}`} 
                            key={`ptime${this.props.details.trips.indexOf(key)}`} 
                            datasetId={this.props.details.trips.indexOf(key)}
                            minTime={
                                this.state.tripDateIsToday ? this.getTime() : "00:00"
                            }
                            collectValue={this.collectValue.bind(this)}
                            required={false}
                            />
                    
                        <LocationPicker 
                            type={"Pickup"} 
                            tripNumber={this.props.details.trips.indexOf(key)}
                            updateId={this.props.details.trips.indexOf(key)}
                            key={`pu_place_fk${this.props.details.trips.indexOf(key)}`}
                            getLocationFk={this.collectValue.bind(this)} 
                            name={`pu_place_fk${this.props.details.trips.indexOf(key)}`}
                            requiredSymbol={false}
                            dataPointer={this.props.details.trips.indexOf(key)}
                            defaultLocationId={key.pickup.id}
                            defaultLocation={`${key.pickup.address0 !== "" ? `${key.pickup.address0}, `: ""}${key.pickup.address1 !== "" ? `${key.pickup.address1}, `: ""}${(key.pickup.address2 !== "" ? `${key.pickup.address2}, ` : "")}${key.pickup.city}, ${key.pickup.state} ${key.pickup.zipcode}`}
                            filterOut={[this.state.updatedDetails[this.props.details.trips.indexOf(key)].do_place_fk]} 
                            />

                        <label 
                            htmlFor={`dtime${this.props.details.trips.indexOf(key)}`}
                            id={`dtime${this.props.details.trips.indexOf(key)}Label`} >Drop off time</label>
                        <TimePicker 
                            currentTime={this.getTime()} 
                            defaultValue={key.dtime !== null ? key.dtime.substr(0,5) : ""}
                            name={`dtime${this.props.details.trips.indexOf(key)}`} 
                            key={`dtime${this.props.details.trips.indexOf(key)}`} 
                            datasetId={this.props.details.trips.indexOf(key)}
                            minTime={
                                this.state.updatedDetails[this.props.details.trips.indexOf(key)].ptime
                            }
                            collectValue={this.collectValue.bind(this)} 
                            required={false} />

                        <LocationPicker 
                            type={"Drop off"} 
                            tripNumber={this.props.details.trips.indexOf(key)}
                            updateId={this.props.details.trips.indexOf(key)}
                            key={`do_place_fk${this.props.details.trips.indexOf(key)}`}
                            getLocationFk={this.collectValue.bind(this)} 
                            name={`do_place_fk${this.props.details.trips.indexOf(key)}`}
                            requiredSymbol={false}
                            dataPointer={this.props.details.trips.indexOf(key)}
                            defaultLocationId={key.dropoff.id}
                            defaultLocation={`${key.dropoff.address0 !== "" ? `${key.dropoff.address0}, `: ""}${key.dropoff.address1 !== "" ? `${key.dropoff.address1}, `: ""}${(key.dropoff.address2 !== "" ? `${key.dropoff.address2}, ` : "")}${key.dropoff.city}, ${key.dropoff.state} ${key.dropoff.zipcode}`}
                            filterOut={[this.state.updatedDetails[this.props.details.trips.indexOf(key)].pu_place_fk]} />

                    </fieldset>

                    <fieldset data-id={this.props.details.trips.indexOf(key)}>                        
                        <label htmlFor={"service"}>Service Level</label>
                        <select 
                            name={"service"} 
                            onBlur={
                                (e) => {
                                    e.target.readOnly = true
                                }
                            }
                            onChange={
                                (e) => {
                                    this.collectValue(e.target)
                                    e.target.blur()
                                }      
                            }
                            defaultValue={key.service}>
                            <option disabled value={""}></option>
                            <option value={"DTD"}>Door To Door</option>
                            <option value={"CTC"}>Curb To Curb</option>
                            <option value={"RTR"}>Room To Room</option>
                            <option value={"LTL"}>Lobby To Lobby</option>
                        </select>

                        <label htmlFor={"mode"}>Mobility Status</label>
                        <select 
                            name={"mode"} 
                            onBlur={
                                (e) => {
                                    e.target.readOnly = true
                                }
                            }
                            onChange={
                                (e) => {
                                    this.collectValue(e.target)
                                    e.target.blur()
                                }      
                            }
                            defaultValue={key.mode}
                            required>
                            <option disabled value={""}></option>
                            <option value={"AMB"}>Ambulatory</option>
                            <option value={"WC"}>Wheelchair</option>
                            <option value={"PWC"}>Power Wheelchair</option>
                        </select>

                        <label htmlFor={"passengers"}>Passengers</label>
                        <select 
                            name={"passengers"} 
                            onBlur={
                                (e) => {
                                    e.target.readOnly = true
                                }
                            }
                            onChange={
                                (e) => {
                                    this.collectValue(e.target)
                                    e.target.blur()
                                }      
                            }
                            defaultValue={key.passengers}
                            required>
                            <option disabled value={""}></option>
                            <option value={"0"}>0</option>
                            <option value={"1"}>1</option>
                            <option value={"2"}>2</option>
                            <option value={"3"}>3</option>
                            <option value={"4"}>4</option>
                            <option value={"5"}>5</option>
                        </select>

                        <label 
                            htmlFor={"notes"}
                            id={"notes"} >Driver Notes:</label>
                        <textarea 
                            name={"notes"} 
                            defaultValue={key.notes}
                            onClick={
                                (e) => {
                                    this.changeToEditable(e.target, "textarea")
                                }
                            }
                            onBlur={
                                (e) => {
                                    e.target.readOnly = true
                                }
                            }
                            onChange={
                                (e) => {
                                    this.collectValue(e.target)
                                }      
                            }
                        readOnly={true} >
                        </textarea>
                    </fieldset>
                    <input
                        name={"cancel"}
                        type={"button"}
                        value={`Cancel Trip ${key.id}`}
                        onClick={
                            (e) => {
                                var cancel = window.confirm("Are you sure you want to cancel?");
                                if (cancel === true){
                                    let stateCopy  = this.state
                                    stateCopy.updatedDetails[parseInt(e.target.parentElement.dataset.id)].status = "Cancelled"
                                    this.setState(stateCopy, () => {
                                        this.postUpdate(parseInt(e.target.parentElement.dataset.id))
                                        const newStatusElement = document.createElement("input")
                                        newStatusElement.name = "trip_status"
                                        newStatusElement.value = "CANCELLED"
                                        newStatusElement.disabled = true
                                        let oldStatusElement = document.getElementsByName("trip_status")[0]
                                        oldStatusElement.parentElement.insertBefore(newStatusElement, oldStatusElement)
                                        oldStatusElement.remove() 
                                        e.target.parentElement.className="canceled"
                                    })
                                }                                
                            }
                        } /> 
                    <input
                        name={"update_trip"}
                        type={"submit"}
                        value={`Update Trip ${key.id}`}
                        onClick={
                            (e) => {
                                e.preventDefault()
                                let propDate = key.ptime === null 
                                ? new Date(`${key.tripdate}T23:59`) 
                                : new Date(`${key.tripdate}T${key.ptime}`)
                                propDate.setDate(propDate.getDate()-2);

                                for (let subButton of  document.getElementsByName("update_trip")){
                                    subButton.setCustomValidity("")
                                }
                                if (key.status === "Cancelled"){
                                    e.target.setCustomValidity("Trip is already cancelled")
                                } else if ((new Date() < propDate)){
                                    this.postUpdate(parseInt(e.target.parentElement.dataset.id))
                                } else {
                                    e.target.setCustomValidity("You can not change a trip within 48 hours of its date")
                                }
                                e.target.parentElement.parentElement.checkValidity()
                                e.target.parentElement.parentElement.reportValidity()
                            }
                        }
                    />
                    <hr></hr>                   
                </article>
            )
        })
        this.setState({visibleDetails : details})
    }

    render() {
        return (
            <dialog open={this.props.toggle} className={"detailsModal"}>
                
                <h1>{`Itinerary ${this.props.details.id} Details`}<input 
                    type={"button"} 
                    value={"Generate Ticket"}
                    onClick={ () => {
                            const ticketWindow = window.open(window.location.origin + `/ticket`, '_blank', 'toolbar=0,location=0,menubar=0')
                            ticketWindow.ticketDetails = this.props.details.trips[1] ? ["trips", this.props.details.trips[0].id, this.props.details.trips[1].id] : ["trips", this.props.details.trips[0].id]
                        }
                    } /> <button onClick={() => this.props.handleClick(this.state.page)}><CloseIcon/></button></h1>
                
                <h2>Booked by: <b>{this.props.details.trips ? this.props.details.trips[0].agn : this.props.details.agn} ({this.props.details.agency})</b> </h2>
                   
                <form>
                    {this.state.visibleDetails}
                </form>
                <p><b>To change extra details please call <a href="tel:781-316-0400">781-316-0400</a></b></p>
            </dialog>
        )
    }
}

export default DetailsModal