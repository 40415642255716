import React, { Component } from "react";
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';


class RiderListItem extends Component {

    toggleMenu(){
        if (this.props.menu === this.props.keyId){
            this.props.closeRiderMenu()
        } else {
            this.props.expandRiderMenu(this.props.rider)
        }
    }

    render() {
        return(
            <li 
                key={this.props.rider.id}
                onClick={(e) => {
                    this.toggleMenu()
                    }}
                className={this.props.menu === this.props.keyId
                ? "riderMenuOpen" 
                : undefined}>
                    <p>{`${this.props.rider.lastname}, ${this.props.rider.firstname} ${this.props.rider.middlename == null ? "" : this.props.rider.middlename} (${this.props.rider.id})`.toUpperCase()}</p>
                    <button>{this.props.menu === this.props.keyId ? <UnfoldLessIcon/> : <UnfoldMoreIcon/>} </button>
            </li>
        )
    }
}

export default RiderListItem