import React, { Component } from "react"
import axiosInstance  from "../../axios"

class Login extends Component {
    constructor(props) {
        super(props)
        this.state = {
           user: {
                username: "",
                password: "",
            },
            envName:process.env.REACT_APP_APP_NAME,
        }
    }

    componentDidMount(){
        if (localStorage.getItem("access_token")){
          this.props.history.push("/ride")   
        }

        this.testEnv()

        setTimeout(() => { 
            const loaders = document.getElementsByClassName("loader");
            if(loaders){
                for (const loader of loaders){
                    loader.remove()
                }
            }
        }, 1000);
      }

      testEnv(){
        const devEnvNames = ["test", "dev", "demo"]
        for (let name of devEnvNames){
            if (process.env.REACT_APP_API_DOMAIN.includes(name)){
                this.setState({envName : "DEV ENV"})
            }
        }
      }


    /**
     * Submit the values in the add location modal. Then re-render the list od locations
     * 
     * @param {Event} submitButton 
     */
    onFormSubmit(submitButton) {
        submitButton.preventDefault();

        if (!this.checkForm())
        {
            let loader = document.createElement("div")
            loader.classList.add("loader")
            document.body.appendChild(loader)
            let newUserFinal = Object.freeze(this.state.user)
            axiosInstance.post(
                "users/login/",
                newUserFinal)
                .then(response => {
                    localStorage.setItem("access_token", response.data.access)
                    localStorage.setItem("refresh_token", response.data.refresh)
                    localStorage.setItem("agn", response.data.agn)
                    localStorage.setItem("user", response.data.username)
                    axiosInstance.defaults.headers["Authorization"] =
                        "Bearer " + localStorage.getItem("access_token")
                    this.props.history.push("/ride")
                    loader.remove()
                })
                .catch(() => {
                    if (document.getElementsByTagName("strong").length === 0){
                        const wrong = document.createElement("strong")
                        wrong.innerHTML = "The username or password you entered is incorrect"
                        document.getElementsByTagName("h1")[0].parentNode.insertBefore(wrong, document.getElementsByTagName("h1")[0].nextSibling);
                        for (const field in this.state.user){
                            let errorField = document.getElementsByName(field)[0]
                            errorField.classList.add("errorField")
                        }
                    }
                    this.setState(
                        {user:  {
                        username: newUserFinal.username,
                        password: newUserFinal.password,
                    }                    
                }, 
                loader.remove())
                }
            )
        }
    }

    checkForm(){
        let notReady = false

        // check for empty fields
        const {...requiredStrings} = this.state.user
        for (const field in requiredStrings){
            let errorField = document.getElementsByName(field)[0]
            errorField.classList.remove("errorField")
            errorField.placeholder = errorField.placeholder.substr(0,8)
            if (requiredStrings[field] === "" || requiredStrings[field] === 0){
                errorField.classList.add("errorField")
                errorField.placeholder += " - Required!"
                errorField.previousSibling.classList.add("errorLabel")
                notReady = true
            }
        }
        return notReady
    }

    /**
     * Update the state with form field values
     * @param {Event} event the input event that is changed
     */
    textInputChange(event) {
        let newUser= this.state.user
        switch (event.target.name) {
            case "username":
                newUser.username = event.target.value
                break   
            case "password":
                newUser.password = event.target.value
                break
            default:
                console.log(`Error on ${event}`)
        }
        this.setState({ user: newUser })
    }

    render() {
        return (
            <section className={"entry"}>
                <form autoComplete={"off"}>
                    <h1>{this.state.envName} Login</h1>
                        <label 
                            htmlFor={"username"}
                            className={"semanticVisible"}
                        >Username</label>
                        <input 
                            type={"text"} 
                            name={"username"}
                            placeholder={"Username"}
                            onChange={(e) => this.textInputChange(e)} 
                            required />

                        <label 
                            htmlFor={"password"}
                            className={"semanticVisible"}
                            >Password</label>
                        <input 
                            type={"password"} 
                            name={"password"} 
                            placeholder="Password" 
                            onChange={(e) => this.textInputChange(e)} 
                            required />

                        <input 
                            type={"submit"} 
                            value={"Login"} 
                            onClick={(e) => this.onFormSubmit(e)} />

                        <p>To reset your password please call <a href="tel:781-316-0400">781-316-0400</a></p>
                </form>
            </section>
        )
    }
}

export default Login