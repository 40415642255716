import React, { Component } from "react"
import axiosInstance from "../../axios";
import CloseIcon from '@material-ui/icons/Close';
import LocationPicker from "../partsAndFields/LocationPicker";
import Phone from '../partsAndFields/Phone'
import {
    validateName,
    validateMassHealthProviderIDOrSL,
} from "../../validation.js"

class AddRiderModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            newRider: {
                id:null,
                firstname: "",
                middlename: "",
                lastname: "",
                address: "",
                mh_member_id:"",
                phone:"",
                dob:"",
                sex:"",
                agency: localStorage.getItem("agn"),
                veteran: false,
            },
            duplicateError:"",
        }
    }

    componentDidMount(){
        document.getElementsByTagName("button")[0].focus()
    }

    /**
     * Submit the values in the add rider modal. Then re-render the list of riders
     * 
     * @param {Event} submitButton 
     */
    async onFormSubmit(e) {
        e.preventDefault();
        let newRiderFinal = this.state.newRider;
        e.target.disabled = true
        
        if (!this.checkForm())
        {
            let loader = document.createElement("div")
            loader.classList.add("loader")
            document.body.appendChild(loader)        
            await axiosInstance.post(
                "riders/create",
                newRiderFinal)
                .then((res) => {
                    this.props.handleClick(e);
                    this.props.onAddRider(e) // will pass up to parent to get the riders again    
                    loader.remove()
                })
                .catch(error => {
                    if (error.response.status === 400){
                        this.setState({duplicateError:"Rider already exists"})
                    }
                    e.target.disabled = false
                    loader.remove()                   
                })
        }
        e.target.disabled = false 
    }

    checkForm(){
        let notReady = false

        // reset server errors
        this.setState({duplicateError:""})

        const fieldApply = (errorField) => {
            errorField.classList.add("errorField")
            errorField.previousSibling.classList.add("errorLabel")
            notReady = true            
        }

        // check for empty fields
        const requiredStrings = ["firstname", "lastname", "dob"]
        for (const field of requiredStrings){
            
            let errorFields = document.getElementsByName(field)
            for (let errorField of errorFields){
                errorField.classList.remove("errorField")
                if (errorField.value === "" || errorField.value === 0){
                    fieldApply(errorField)
                }
            }
        }
        // for radio inputs, as they dont follow standard input/label structure
        let requiredRadios = ["sex"]
        requiredRadios = Array
            .from(new Set(requiredRadios
                .map(string => {
                    return document.getElementsByName(string)[0].parentElement
                })
            )
        )
        for (let radParent of requiredRadios){
            radParent.classList.remove("errorField")
            radParent.children[0].classList.remove("errorLabel")
            let checkedInput = Array.from(radParent.children)
                                .map(elem => {
                                    if (elem.tagName === 'INPUT' 
                                    && elem.checked === true){                                        
                                        return elem
                                    }
                                    return undefined
                                    }).filter(elem => elem !== undefined)
            if (radParent.classList.contains('radioContainer')
            && checkedInput.length !== 1){
                radParent.classList.add("errorField")
                radParent.children[0].classList.add("errorLabel")
            }
        }

        

        return notReady
    }

    /**
     * Update state with form values
     * @param {EventTarget} event the change of the form field
     */
    inputChange(target) {
        let newRiderCopy = this.state.newRider
        switch (target.name) {
            case "firstname":
                newRiderCopy.firstname = ""
                if(validateName(target)){
                    newRiderCopy.firstname = target.value
                }
                break
            case "middlename":
                newRiderCopy.middlename = ""
                if(validateName(target)){
                    newRiderCopy.middlename = target.value
                }
                break
            case "lastname":
                newRiderCopy.lastname = ""
                if(validateName(target)){
                    newRiderCopy.lastname = target.value
                }
                break
            case "address":
                    newRiderCopy.address = parseInt(target.dataset.value)
                    break
            case "mh_member_id":
                newRiderCopy.mh_member_id = ""
                if(validateMassHealthProviderIDOrSL(target)){
                    newRiderCopy.mh_member_id = target.value
                }
                break
            case "phone":
                newRiderCopy.phone = parseInt(target.dataset.value)
                break
            case "sex":
                newRiderCopy.sex = target.value
                break
            case "dob":
                newRiderCopy.dob = target.value
                break
            case "veteran":
                newRiderCopy.veteran = !newRiderCopy.veteran
                break
            default:
                console.log("error")
        }
        this.setState({ newRider: newRiderCopy })
    }

    getDate(ahead = 0){
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() + ahead);
        return `${currentDate.getFullYear()}-${('0' + (currentDate.getMonth() + 1)).slice(-2)}-${('0' + currentDate.getDate()).slice(-2)}`
    }

    render() {
        return (
            <dialog 
                open={this.props.toggle} 
                className={"addRiderModal"}>
                
                <h1>Add rider
                    <button 
                        onClick={this.props.handleClick}><CloseIcon/></button>
                </h1>

                <form className={"modal"}>
                    <label 
                        htmlFor={"firstname"}
                        id={"firstname"} ><span>* </span>First name</label>
                    <input 
                        type={"text"} 
                        name={"firstname"} 
                        placeholder={"ex. Jordan"} 
                        onChange={(e) => this.inputChange(e.target)} 
                        onBlur={(e) => this.inputChange(e.target)} 
                        required/>

                    <label 
                        htmlFor={"middlename"}
                        id={"middlename"}>Middle name</label>
                    <input 
                        type={"text"} 
                        name={"middlename"} 
                        placeholder={""}
                        onChange={(e) => this.inputChange(e.target)}
                        onBlur={(e) => this.inputChange(e.target)} />

                    <label 
                        htmlFor={"lastname"}
                        id={"lastname"} ><span>* </span>Last name</label>
                    <input 
                        type={"text"} 
                        name={"lastname"} 
                        placeholder={"ex. Doe"}
                        onChange={(e) => this.inputChange(e.target)} 
                        onBlur={(e) => this.inputChange(e.target)} 
                        required />
                    
                    <label 
                        htmlFor={"mh_member_id"}
                        id={"mh_member_id"} >MassHealth provider ID/service location</label>
                    <input 
                        type={"text"} 
                        name={"mh_member_id"} 
                        onChange={(e) => this.inputChange(e.target)} 
                        onBlur={(e) => this.inputChange(e.target)} 
                        required />
                    
                    <label htmlFor={"dob"}
                        id={"dob"}><span>*</span> Date of birth:</label>
                    <input 
                        type={"date"} 
                        name={"dob"} 
                        max={this.getDate(0)}
                        onChange={(e) => this.inputChange(e.target)}
                        onBlur={(e) => this.inputChange(e.target)}
                        required />

                    <fieldset className={"radioContainer"}>
                            <legend><span>*</span> Sex:</legend>
                            <input type={"radio" }
                                name={"sex" }
                                onChange={(e) => this.inputChange(e.target)}
                                onBlur={(e) => this.inputChange(e.target)}
                                value={"M"}
                                required/>
                            <label htmlFor={"sex"}>Male</label>

                            <input type={"radio" }
                                name="sex" 
                                onChange={(e) => this.inputChange(e.target)}
                                onBlur={(e) => this.inputChange(e.target)}
                                value={"F"}
                                required/>
                            <label htmlFor={"sex"}>Female</label>
                    </fieldset>

                    <Phone
                        name="phone"
                        displayName="Phone number" 
                        collectValue={this.inputChange.bind(this)}
                        phoneRequired={true}/>

                    
                    <LocationPicker 
                        type={"Default"} 
                        tripNumber={0}
                        requiredSymbol={false}
                        getLocationFk={this.inputChange.bind(this)} 
                        name="address" 
                        required={false} />

                    <label 
                        htmlFor={"veteran"}>Veteran</label>
                    <input 
                        type={"checkbox"} 
                        name={"veteran"} 
                        onChange={(e) => this.inputChange(e.target)} 
                        required />
                    
                    {this.state.duplicateError !== ""
                    && <span className={"errorSpan"} style={{"width":"100%", "float":"left", "margin":"0.5rem 2rem 3rem"}}>
                            {this.state.duplicateError}
                        </span>
                    }
                    

                    <input 
                        type={"submit"} 
                        value={"Add rider"}
                        id={"addRiderSubmit"}
                        onClick={(e) => this.onFormSubmit(e)}/>
                </form>
            </dialog>
        )
    }
}

export default AddRiderModal