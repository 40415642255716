import React, { Component } from "react";
import Steps from "../partsAndFields/Steps";
import FileUpload from "../partsAndFields/FileUpload";
import AddPT1Form from "./AddPT1Form";
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import Rotate90DegreesCwIcon from '@mui/icons-material/Rotate90DegreesCw';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import {axiosInstanceFile } from "../../axios";


class AddPT1 extends Component {
    constructor(props) {
        super(props)
        this.state = {
            visible:{
                step1:true,
                step2:false,
                step3:false,
            },
            fileUpload: false,
            pt1Pdf:{},
            pt1PdfURL:{},
            pdfZoom:1.0,
            pdfRotate:0,
            riderCanFillOut: false,
        }
    }
    componentDidMount(){
        console.log(this.props.rider.mh_member_id)
        if (!(this.props.rider.mh_member_id === null || this.props.rider.mh_member_id === "")){
            this.setState({riderCanFillOut:true})
        } else {
            this.props.expandPanel()
        }
    }    

    toStep2(file={}){
        let newState = this.state
        if (file.name !== undefined){
            // add the uploaded pdf
            newState.fileUpload = true
            newState.pt1Pdf = file 
            newState.pt1PdfURL  = URL.createObjectURL(file);
        }

        // update visibility
        newState.visible.step1 = false
        newState.visible.step2 = true

        this.setState(newState)

    }

    toStep3(){
        let newState = this.state

        // update visibility
        newState.visible.step2 = false
        newState.visible.step3 = true

        this.setState(newState)

    }

    backToStep1(){
        let newState = this.state

        // update visibility
        newState.visible.step1 = true
        newState.visible.step2 = false
        newState.visible.step3 = false

        this.setState(newState)
    }

    async submit(pt1, formSubmitButton){
        // console.log(pt1);
        // this.toStep3()
        let loader = document.createElement("div")
        loader.classList.add("loader")
        document.body.appendChild(loader)
        await axiosInstanceFile.post(
            "pt1/create/",
            pt1)
            .then( async response => {
                this.toStep3()
                console.log(pt1);
                loader.remove()
            })
            .catch(response => {
                formSubmitButton.target.disabled = false
                console.log(response)
                loader.remove()
            })
    }

    zoomIn(){
        if (this.state.pdfZoom <= 5.0){
            this.setState({pdfZoom:this.state.pdfZoom + 1})
        }
    }

    zoomOut(){
        if (this.state.pdfZoom > 1.0){
            this.setState({pdfZoom:this.state.pdfZoom - 1})
        }
    }

    rotate(){
        if (this.state.pdfRotate < 360){
            this.setState({pdfRotate:this.state.pdfRotate + 90})
        } else {
            this.setState({pdfRotate:this.state.pdfRotate + 1})
        }
    }

    onClickStep(key){
        switch (key){
            case 1:
                this.backToStep1()
                break
            case 2:
                this.toStep2()
                break
            default:
                break
        }
    }

    render() {
        return(
            <section
                className={"addPT1"}>
                    {this.state.riderCanFillOut
                    ? (this.state.visible.step1 
                    && <>
                        <Steps
                            numbers={true}
                            steps={["Upload",
                            "Validate",
                            "Confirmation"]}
                            selected={1}
                            clickable={{2:false, 3:false}} />
                        <FileUpload onUploadSuccess={
                            this.toStep2.bind(this)} />
                    </>)
                    : (<section className="noMHId"> 
                        <p>Please update the rider profile of: 
                             {` ${this.props.rider.lastname}, 
                            ${this.props.rider.firstname} (${this.props.rider.id}) `}
                            with a MassHealth ID, in order to proceed. <br/> <br/> You can update this information in the 
                            rider panel to the left, through "UPDATE RIDER {this.props.rider.id} PROFILE".</p>
                    </section>)
                    }

                    {this.state.visible.step2
                    && <>
                        <Steps
                            numbers={true}
                            steps={["Upload",
                            "Validate",
                            "Confirmation"]}
                            selected={2}
                            onClickStep={this.onClickStep.bind(this)}
                            clickable={{3:false}} />
                            <section className={"pt1Validate"}>
                            {this.state.fileUpload && 
                                <>
                                <article>
                                    <Document
                                        file={this.state.pt1PdfURL}
                                        rotate={this.state.pdfRotate}
                                        // onLoadSuccess={""}
                                    >
                                        <Page pageNumber={1} scale={this.state.pdfZoom} />
                                    </Document>
                                </article>
                                <div className={"zoomTools"}
                                        draggable={"true"}>
                                        <button onClick={() => this.zoomIn()}><ZoomInIcon/></button>
                                        <button onClick={() => this.zoomOut()}><ZoomOutIcon/></button>
                                        <button onClick={() => this.rotate()}><Rotate90DegreesCwIcon /></button>
                                </div>
                                </>}
                            <AddPT1Form 
                            file={this.state.pt1Pdf}
                            rider={this.props.rider}
                            submit={this.submit.bind(this)} />
                        </section>
                    </>}

                    {this.state.visible.step3
                    && <>
                        <Steps
                            numbers={true}
                            steps={["Upload",
                            "Validate",
                            "Confirmation"]}
                            selected={3}
                            clickable={{1:false, 2:false, 3:false}}/>
                         <article className={"pt1Confirm"}>
                            <h1>Success! The PT1 for {`${this.props.rider.firstname} ${this.props.rider.lastname}`} is uploaded.</h1>
                            <FactCheckIcon/>
                            <button onClick={() => {
                                    this.props.expandPanel()
                                    this.props.openRiderDashboard(this.props.rider)}}>
                                    View {`${this.props.rider.firstname} ${this.props.rider.lastname}`}'s  PT1's <PlayArrowIcon/>
                            </button>
                            <button
                                onClick={() => {
                                    this.props.expandPanel()
                                    this.props.handleClick()}}>
                                    <PlayArrowIcon style={{transform: "rotate(180deg)"}} /> Trips Dashboard
                            </button>
                         </article>
                    </>}
           </section>
        )
    }
}

export default AddPT1