import React, { Component } from "react";
import FactCheckIcon from '@mui/icons-material/FactCheck';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import AttachFileIcon from '@mui/icons-material/AttachFile';

class PT1Box extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pt1:{},
            boxClass:"pt1Box"
        }
    }

    componentDidUpdate(prevProps){        
        if (this.props.selected !== prevProps.selected){
            const stateCopy = this.state
            if(this.props.selected){
                stateCopy.boxClass = "pt1Box pt1Selected"
            } else {
                stateCopy.boxClass = "pt1Box"
            }
            this.setState(stateCopy)
        }
    }

    componentDidMount(){
        const stateCopy = this.state

        const todayDate = new Date()
        const expiresDate = new Date(stateCopy.pt1.authorized_expired_date)

        stateCopy.pt1 = this.flattenObject(this.props.pt1)
        stateCopy.pt1.pt1_fk = this.props.pt1.id
        if (parseInt(stateCopy.pt1.uses_left) === 0 
            || todayDate.getTime() > expiresDate.getTime()){
            stateCopy.boxClass = "pt1Box pt1BoxExpired"
        } else {
            stateCopy.boxClass = "pt1Box"
        }
        this.setState(stateCopy)
    }
    
    flattenObject(obj){
        const flattened = {}
      
        Object.keys(obj).forEach((key) => {
          const value = obj[key]
      
          if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
            Object.assign(flattened, this.flattenObject(value))
          } else {
            flattened[key] = value
          }
        })
      
        return flattened
    }
    
    async viewMore(){
        // const pt1URI = await makePT1(this.state.pt1)
        const pt1Window = 
            window
            .open(
                window
                .location
                .origin 
                + `/pt1`, '_blank', 'toolbar=0,location=0,menubar=0'
            )
            pt1Window.window.pt1 = this.state.pt1
}

    render() {
        return(
            <article
                className={this.state.boxClass}>
                    <section>
                        <FactCheckIcon />
                        <button onClick={() => {
                        this.viewMore()
                        }}>
                            VIEW MORE
                            <OpenInNewIcon />
                        </button>
                        {(this.props.usePT1 && this.state.pt1.uses_left !== 0 )
                        && <button onClick={() => {
                            this.props.usePT1(this.state.pt1, this.props.id)
                        }}>
                            USE THIS PT1
                            <AttachFileIcon />
                        </button>}
                    </section>
                    <section>
                        <ul>
                            <li>
                                <b>Signed: </b>
                                {this.state.pt1.authorized_date}
                            </li>
                            <li>
                                <b>Expires: </b>
                                {this.state.pt1.authorized_expired_date}
                            </li>
                            <li>
                                <b>Used Visits: </b>
                                {this.state.pt1.use_visit}    
                            </li>
                            <li>
                                <b>Uses Left: </b>
                                {this.state.pt1.visit_left}
                            </li>
                            <li>
                                <b>Facility: </b>
                                {this.state.pt1.provider_address}
                            </li>
                        </ul>
                    </section>
           </article>
        )
    }
}

export default PT1Box