import React, { Component } from "react"
import CloseIcon from '@material-ui/icons/Close';
import axiosInstance from "../../axios";
import TimePicker from "../partsAndFields/TimePicker";
import LocationPicker from "../partsAndFields/LocationPicker";
import {
    validatePhoneWithExtension,
    validateNotes,
} from "../../validation.js"

class DetailsModalRecurring extends Component {
    constructor(props) {
        super(props)
        this.state = {
            visibleDetails:[],
            updatedDetails: [this.props.details],
            page:this.props.page,
        }
    }

    componentDidMount(){
        this.generateDetails()
    }

    changeToEditable(target, fieldType){
        switch(fieldType){
            case "input":
                target.readOnly = false
                target.focus()
                break
            case "select":
                target.disabled = false
                target.focus()
                break
            case "textarea":
                target.readOnly = false
                target.focus()
                break
            default:
                console.log("Error not a field");
        }        
    }

    collectValue(target){
        let stateCopy = this.state
        let field = document.getElementsByName(target.name)[!target.parentElement ? parseInt(target.dataset.id)  : parseInt(target.parentElement.dataset.id)]
        let updatedDetailsState = stateCopy.updatedDetails[0]
        switch(target.name){
            case "trip_status":
                break
            case "phone1":
                if (validatePhoneWithExtension(target, target, "phone1Label")){
                    updatedDetailsState.phone1 = target.value
                }
                break
            case "phone2":
                if (validatePhoneWithExtension(target, target, "phone2Label")){
                    updatedDetailsState.phone2 = target.value
                }
                break
            case "do_place_fk":
                updatedDetailsState.do_place_fk = parseInt(target.dataset.value)            
                break
            case "pu_place_fk":
                updatedDetailsState.pu_place_fk = parseInt(target.dataset.value)            
                break
            case "dtime":
                    updatedDetailsState.dtime = target.dataset.value
                break
            case "ptime":
                    updatedDetailsState.ptime = target.dataset.value
                break
            case "service":
                updatedDetailsState.service = target.value
                break
            case "mode":
                updatedDetailsState.mode = target.value
                break
            case "passengers":
                updatedDetailsState.passengers = target.value
                break
            case "notes":
                if (validateNotes(field, target, "notes")){
                    updatedDetailsState.notes = target.value
                }
                break
            case "last_date":
                updatedDetailsState.last_date = target.value
                break
            default:
                console.log(`Error on ${target.name}`);
        }
        this.setState(stateCopy)
    }


    async postUpdate(){
        const finalUpdate = this.state.updatedDetails[0]
        let loader = document.createElement("div")
        loader.classList.add("loader")
        document.body.appendChild(loader)

        await axiosInstance.post(
            "routines/update", finalUpdate)
            .then(() => {
                loader.remove()
            })
            .catch(response => {
                console.log(response)
                loader.remove()
            })
    }

    getTime(){
        const today = new Date();
        const minutes = (Math.round(today.getMinutes()/15) * 15) % 60
        return `${(today.getHours() <= 9 ? `0${today.getHours()}` : today.getHours())}:${minutes === 0 ? `${minutes}0` : minutes}`
    }

    getMinDate(date){
        let newDate = new Date(`${date}T00:00:00`)
        newDate.setDate(newDate.getDate() + 13)
        return this.formatDate(newDate)
    }

    formatDate(date){
       return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`
    }

    generateDetails(){
        let details = (
                <article 
                    key={this.props.details.id} 
                    data-id={this.props.details.id} 
                    className={this.props.details.status.toUpperCase() === "CANCELLED" ? "canceled" : ""} >
                    <p>Recurring Trip ID: {this.props.details.id}</p>                     
                    <label htmlFor={"leg"}>Type</label>
                    <input name={"leg"} value={this.props.details.leg === "RETURN" ? "RETURN" : "TAKE"} disabled/>               
                    <label htmlFor={"trip_status"}>Recurring Trip Status</label>
                    <input name={"trip_status"} value={this.props.details.status} disabled/> 
                    <label htmlFor={"created"}>Created</label>
                    <input name={"created"} value={`${new Date(this.props.details.created).toLocaleString('en-US', {timeZone:'America/New_York'})}`} disabled/>
                    <fieldset data-id={this.props.details.id}>
                        <label htmlFor={"name"}>Name:</label>
                        <input name={"name"} value={`${this.props.details.rider.lastname}, ${this.props.details.rider.firstname} ${this.props.details.rider.middlename == null ? "" : this.props.details.rider.middlename} (${this.props.details.rider.id})`} disabled/>
                        <label htmlFor={"rider_status"}>Rider Status:</label>
                        <input name={"rider_status"} value={this.props.details.rider.status} disabled/>
                        <label htmlFor={"veteran"}>Veteran:</label>
                        <input name={"veteran"} value={this.props.details.rider.veteran} disabled/>
                        <label 
                            htmlFor={"phone1"}
                            id={"phone1Label"} >Phone 1:</label>
                        <input 
                            name={"phone1"} 
                            defaultValue={this.props.details.phone1}
                            onClick={
                                (e) => {
                                    this.changeToEditable(e.target, "input")
                                }
                            }
                            onBlur={
                                (e) => {
                                    e.target.readOnly = true
                                }
                            }
                            onChange={
                                (e) => {
                                    this.collectValue(e.target)
                                }      
                            }
                            readOnly={true} />
                        <label 
                            htmlFor={"phone2"}
                            id={"phone2Label"}>Phone 2:</label>
                        <input 
                            name={"phone2"} 
                            defaultValue={this.props.details.phone2}
                            onClick={
                                (e) => {
                                    this.changeToEditable(e.target, "input")
                                }
                            }
                            onBlur={
                                (e) => {
                                    e.target.readOnly = true
                                }
                            }
                            onChange={
                                (e) => {
                                    this.collectValue(e.target)
                                }      
                            }
                        readOnly={true} />
                    </fieldset>

                    <fieldset data-id={this.props.details.id}>
                        <label htmlFor={"start_date"}>Start Date</label>
                        <input name={"start_date"} value={this.props.details.start_date} disabled/>


                        <label 
                            id={"last_date_label"}
                            htmlFor={"last_date_date"}>End Date</label>
                        <input 
                            type={"date"}
                            name={"last_date"}
                            defaultValue={this.props.details.last_date !== "null" ? this.props.details.last_date : ""} 
                            min={
                                this.getMinDate(this.props.details.start_date)
                                }
                            onChange={(e) => this.collectValue(e.target)} />

                        <label 
                            htmlFor={`ptime$`}
                            id={`ptimeLabel`} >Pickup time</label>
                        <TimePicker 
                            currentTime={this.getTime()} 
                            defaultValue={this.props.details.ptime !== null ? this.props.details.ptime.substr(0,5) : ""}
                            name={`ptime`} 
                            key={`ptime`} 
                            datasetId={this.props.details.id}
                            minTime={
                                this.state.tripDateIsToday ? this.getTime() : "00:00"
                            }
                            collectValue={this.collectValue.bind(this)}
                            required={false}
                            />
                    
                        <LocationPicker 
                            type={"Pickup"} 
                            tripNumber={1}
                            key={"pu_place_fk"}
                            getLocationFk={this.collectValue.bind(this)} 
                            name={"pu_place_fk"}
                            requiredSymbol={false}
                            dataPointer={this.props.details.id}
                            defaultLocationId={this.props.details.pickup.id}
                            defaultLocation={`${this.props.details.pickup.address0 !== "" ? `${this.props.details.pickup.address0}, `: ""}${this.props.details.pickup.address1 !== "" ? `${this.props.details.pickup.address1}, `: ""}${(this.props.details.pickup.address2 !== "" ? `${this.props.details.pickup.address2}, ` : "")}${this.props.details.pickup.city}, ${this.props.details.pickup.state} ${this.props.details.pickup.zipcode}`}
                            filterOut={[this.state.updatedDetails[0].do_place_fk]} 
                            />

                        <label 
                            htmlFor={`dtime`}
                            id={`dtimeLabel`} >Drop off time</label>
                        <TimePicker 
                            currentTime={this.getTime()} 
                            defaultValue={this.props.details.dtime !== null ? this.props.details.dtime.substr(0,5) : ""}
                            name={`dtime`} 
                            key={`dtime`} 
                            datasetId={this.props.details.id}
                            minTime={
                                this.state.updatedDetails[0].ptime
                            }
                            collectValue={this.collectValue.bind(this)} 
                            required={false} />

                        <LocationPicker 
                            type={"Drop off"} 
                            tripNumber={1}
                            key={"do_place_fk"}
                            getLocationFk={this.collectValue.bind(this)} 
                            name={"do_place_fk"}
                            requiredSymbol={false}
                            dataPointer={this.props.details.id}
                            defaultLocationId={this.props.details.dropoff.id}
                            defaultLocation={`${this.props.details.dropoff.address0 !== "" ? `${this.props.details.dropoff.address0}, `: ""}${this.props.details.dropoff.address1 !== "" ? `${this.props.details.dropoff.address1}, `: ""}${(this.props.details.dropoff.address2 !== "" ? `${this.props.details.dropoff.address2}, ` : "")}${this.props.details.dropoff.city}, ${this.props.details.dropoff.state} ${this.props.details.dropoff.zipcode}`}
                            filterOut={[this.state.updatedDetails[0].pu_place_fk]} />

                    </fieldset>

                    <fieldset data-id={this.props.details.id}>                        
                        <label htmlFor={"service"}>Service Level</label>
                        <select 
                            name={"service"} 
                            onBlur={
                                (e) => {
                                    e.target.readOnly = true
                                }
                            }
                            onChange={
                                (e) => {
                                    this.collectValue(e.target)
                                    e.target.blur()
                                }      
                            }
                            defaultValue={this.props.details.service}>
                            <option disabled value={""}></option>
                            <option value={"DTD"}>Door To Door</option>
                            <option value={"CTC"}>Curb To Curb</option>
                            <option value={"RTR"}>Room To Room</option>
                            <option value={"LTL"}>Lobby To Lobby</option>
                        </select>

                        <label htmlFor={"mode"}>Mobility Status</label>
                        <select 
                            name={"mode"} 
                            onBlur={
                                (e) => {
                                    e.target.readOnly = true
                                }
                            }
                            onChange={
                                (e) => {
                                    this.collectValue(e.target)
                                    e.target.blur()
                                }      
                            }
                            defaultValue={this.props.details.mode}
                            required>
                            <option disabled value={""}></option>
                            <option value={"AMB"}>Ambulatory</option>
                            <option value={"WC"}>Wheelchair</option>
                            <option value={"PWC"}>Power Wheelchair</option>
                        </select>

                        <label htmlFor={"passengers"}>Passengers</label>
                        <select 
                            name={"passengers"} 
                            onBlur={
                                (e) => {
                                    e.target.readOnly = true
                                }
                            }
                            onChange={
                                (e) => {
                                    this.collectValue(e.target)
                                    e.target.blur()
                                }      
                            }
                            defaultValue={this.props.details.passengers}
                            required>
                            <option disabled value={""}></option>
                            <option value={"0"}>0</option>
                            <option value={"1"}>1</option>
                            <option value={"2"}>2</option>
                            <option value={"3"}>3</option>
                            <option value={"4"}>4</option>
                            <option value={"5"}>5</option>
                        </select>

                        <label 
                            htmlFor={"notes"}
                            id={"notes"} >Driver Notes:</label>
                        <textarea 
                            name={"notes"} 
                            defaultValue={this.props.details.notes}
                            onClick={
                                (e) => {
                                    this.changeToEditable(e.target, "textarea")
                                }
                            }
                            onBlur={
                                (e) => {
                                    e.target.readOnly = true
                                }
                            }
                            onChange={
                                (e) => {
                                    this.collectValue(e.target)
                                }      
                            }
                        readOnly={true} >
                        </textarea>
                    </fieldset>
                    <input
                        name={"cancel"}
                        type={"button"}
                        value={`Cancel Trip ${this.props.details.id}`}
                        onClick={
                            (e) => {
                                var cancel = window.confirm("Are you sure you want to cancel?");
                                if (cancel === true){
                                    let stateCopy  = this.state
                                    stateCopy.updatedDetails[parseInt(e.target.parentElement.dataset.id)].status = "Cancelled"
                                    this.setState(stateCopy, () => {
                                        this.postUpdate()
                                        const newStatusElement = document.createElement("input")
                                        newStatusElement.name = "trip_status"
                                        newStatusElement.value = "CANCELLED"
                                        newStatusElement.disabled = true
                                        let oldStatusElement = document.getElementsByName("trip_status")[0]
                                        oldStatusElement.parentElement.insertBefore(newStatusElement, oldStatusElement)
                                        oldStatusElement.remove() 
                                        e.target.parentElement.className="canceled"
                                    })
                                }                                
                            }
                        } /> 
                    <input
                        name={"update_trip"}
                        type={"submit"}
                        value={`Update Trip ${this.props.details.id}`}
                        onClick={
                            (e) => {
                                e.preventDefault()
                                let propDate = this.props.details.ptime === null 
                                    ? new Date(`${this.props.details.start_date}T23:59`) 
                                    : new Date(`${this.props.details.start_date}T${this.props.details.ptime}`)
                                propDate.setDate(propDate.getDate()-2);

                                for (let subButton of  document.getElementsByName("update_trip")){
                                    subButton.setCustomValidity("")
                                }
                                if (this.props.details.status === "Cancelled"){
                                    e.target.setCustomValidity("Trip is already cancelled")
                                } else if ((new Date() < propDate)){
                                    this.postUpdate()
                                } else {
                                    e.target.setCustomValidity("You can not change a trip within 48 hours of its date")
                                }
                                e.target.parentElement.parentElement.checkValidity()
                                e.target.parentElement.parentElement.reportValidity()
                            }
                        }
                    />
                    <hr></hr>                   
                </article>
                    )
        this.setState({visibleDetails : details})
    }

    render() {
        return (
            <dialog open={this.props.toggle} className={"detailsModal"}>
                
                <h1>{`Recurring Trip ${this.props.details.id} Details`}<input 
                    type={"button"} 
                    value={"Generate Ticket"}
                    onClick={ () => {
                            const ticketWindow = window.open(window.location.origin + `/ticket`, '_blank', 'toolbar=0,location=0,menubar=0')
                            ticketWindow.ticketDetails = ["routines", this.props.details.id]
                        }
                    } /> <button onClick={() => this.props.handleClick(this.state.page)}><CloseIcon/></button></h1>
                
                <h2>Booked by: <b>{this.props.details.trips ? this.props.details.trips[0].agn : this.props.details.agn} ({this.props.details.agency})</b> </h2>
                   
                <form>
                    {this.state.visibleDetails}
                </form>
                <p><b>To change extra details please call <a href="tel:781-316-0400">781-316-0400</a></b></p>
            </dialog>
        )
    }
}

export default DetailsModalRecurring