import axios from "axios";

const baseURL = process.env.REACT_APP_API_DOMAIN

const axiosInstance = axios.create({
    baseURL: baseURL,
    timeout: 5000,
    headers: {
        Authorization: localStorage.getItem("access_token")
            ? "Bearer " + localStorage.getItem("access_token")
            : null,
        "Content-Type": "application/json",
        accept: "application/json",
    },
})

export const axiosInstanceFile = axios.create({
    baseURL: baseURL,
    timeout: 300000,
    headers: {
        Authorization: localStorage.getItem("access_token")
            ? "Bearer " + localStorage.getItem("access_token")
            : null,
        "Content-Type": "multipart/form-data",
        accept: "*/*",
        // 'Access-Control-Allow-Origin': '*',
    },
})


export default axiosInstance