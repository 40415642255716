import React, { Component } from "react";
import LocationPicker from "../partsAndFields/LocationPicker";
import TimePicker from "../partsAndFields/TimePicker";
import Phone from "../partsAndFields/Phone";
import LevelOfService from "../partsAndFields/LevelOfService";
import {
    validateTime,
    validateReturnTime,
    validateNotes,
    isTripDateAndTimeAfter2pmAtLeastADayBeforeSelectedDay,
    dateIsValidForBooking,
    isAfter3PMOnFridayForMondayBooking,
} from "../../validation.js"


class PartnerBookingForm extends Component {
    constructor(props) {
        super(props);
        // this.submitForm = this.submitForm.bind(this);
        this.state = {
            newBooking: {
                rider_fk: parseInt(this.props.riderId),
                // itin_fk: 0,
                pu_place_fk: 0,
                do_place_fk: 0,
                tripdate: "",
                ptime: this.getTime(),
                dtime: "23:59",
                passengers: 0,
                wheelchairs: 0,
                bringwheelchair: 0,
                ridealone: true,
                notes: "",
                phone1: "",
                phone2: "",
                mode: "",
                service: "RTR",
                leg: "RETURN",
                next_available: false,
                pt1_fk:"",
            },
            returnTime: true,
            locations: {
                pickup: "",
                dropoff: "",
            },
            phone1Extension:"",
            phone2Extension:"",
            mobile: false,
            tripDateIsToday:false,
            r_ptime: "",
            secondPhoneVisible: false,
            addPT1: false,
        }
    }

    componentDidMount(){
        this.resize()
        document.getElementsByClassName("bookingForm")[0].focus()
    }

    resize() {
        if (window.innerWidth <= 760){
            this.setState({mobile: true})
        } else {
            this.setState({mobile: false})
        }
    }


    async submitForm(formSubmitButton) {
        formSubmitButton.preventDefault()
        const form = formSubmitButton.target.parentElement
        await this.checkForm(form)
        if (!(await this.checkForm(form))){
            formSubmitButton.target.disabled = true
            const finalBooking = this.state.newBooking
            const r_ptime = this.state.r_ptime === "" ? null : this.state.r_ptime

            if (this.props.riderHasPT1s && this.state.addPT1){
                this.props.addPT1(formSubmitButton, finalBooking, r_ptime)
            } else {
                await this.props.submitForm(formSubmitButton, finalBooking, r_ptime)
            }
            
        }
    }

    async checkForm(form){
        let notReady = false

        // check for empty fields
        const requiredStrings = ["phone1", "tripdate", "pu_place_fk", "do_place_fk", 
        "passengers", "leg", "mode", "ptime_hour", "ptime_minute"]

        for (const field of requiredStrings){
            let errorField = document.getElementsByName(field)[0]
            errorField.classList.remove("errorField")
                if (errorField.value === "" || errorField.value === 0){
                    errorField.classList.add("errorField")
                    notReady = true
                }  
        }

        form.checkValidity() ? notReady = false : notReady = true

        if (!(await dateIsValidForBooking(this.state.newBooking.tripdate))) {
            notReady = true
        }
        
        if (isTripDateAndTimeAfter2pmAtLeastADayBeforeSelectedDay(this.state.newBooking.tripdate)) {
            notReady = true
        }
        
        if (isAfter3PMOnFridayForMondayBooking(this.state.newBooking.tripdate)) {
            notReady = true
        }

        form.reportValidity()
        return notReady
    }

    /**
     * Update state with form values
     * @param {EventTarget} target the change target of the form field
     */
    collectFormValues(target) {
        let stateCopy = this.state
        switch (target.name) {
            case "phone1":
                    stateCopy.newBooking.phone1 = target.dataset.value
                break
            case "phone2":
                    stateCopy.newBooking.phone2 = target.dataset.value
                break                
            case "tripdate":
                stateCopy.newBooking.tripdate = target.value
                stateCopy.newBooking.next_available = false
                stateCopy.tripDateIsToday = false
                break
            case "ptime":
                if (validateTime(target, target, "ptime", stateCopy)){
                    stateCopy.newBooking.ptime = target.dataset.value
                }
                break
            case "next_available":
                stateCopy.newBooking.next_available = !stateCopy.newBooking.next_available
                break
            case "do_place_fk":
                stateCopy.newBooking.do_place_fk = parseInt(target.dataset.value)
                stateCopy.locations.dropoff = target.value
                break
            case "pu_place_fk":
                stateCopy.newBooking.pu_place_fk = parseInt(target.dataset.value)
                stateCopy.locations.pickup = target.value
                break
            case "passengers":
                stateCopy.newBooking.passengers = parseInt(target.value)
                break
            case "leg":
                stateCopy.newBooking.leg = target.value
                target.value === "TAKE" ? stateCopy.returnTime = false : stateCopy.returnTime = true
                break
            case "r_ptime":
                if (validateReturnTime(target, target, "r_ptime", stateCopy)){
                    stateCopy.r_ptime = target.dataset.value
                }
                break
            case "mode":
                stateCopy.newBooking.mode = target.value
                break
            case "bringwheelchair":
                stateCopy.newBooking.bringwheelchair = parseInt(target.value)
                break
            case "service":
                stateCopy.newBooking.service = target.value
                break
            case "notes":
                if (validateNotes(target, target, "notes")){
                    stateCopy.newBooking.notes = target.value
                }
                break
            case "pt1_fk":
                stateCopy.newBooking.pt1_fk = parseInt(target.value)
                break
            case "addPT1":
                stateCopy.addPT1 = target.checked
                break
            default:
                console.log(`error on ${target}`)
        }
        this.setState(stateCopy)
    }

    /**
     * 
     * @param {Number} ahead how far ahead in days the date should be
     * @returns String date in 0000-00-00 (y-d-m) format
     */
    getDate(ahead = 0){
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() + ahead);
        return `${currentDate.getFullYear()}-${('0' + (currentDate.getMonth() + 1)).slice(-2)}-${('0' + currentDate.getDate()).slice(-2)}`
    }


    getTime(){
        const today = new Date();
        const minutes = (Math.round(today.getMinutes()/15) * 15) % 60
        return `${(today.getHours() <= 9 ? `0${today.getHours()}` : today.getHours())}:${minutes === 0 ? `${minutes}0` : minutes}`
    }

    render() {
        return (
            <section className={"form bookingForm partnerForm "} id={"partnerForm"}>
               <form 
                    onSubmit={async (e) => {
                        await this.submitForm(e)
                        }}
                    onKeyPress={e => {
                        e.key === 'Enter' && e.preventDefault(); 
                }}>

                    <fieldset className={"fieldTop"}>
                        <button onClick={(e) => {
                            e.preventDefault()
                            this.props.handleClick()
                        }}
                        >Back To Dashboard</button>
                        <h1> Book a Trip for {this.props.riderName}</h1>
                    </fieldset>                    

                    <label htmlFor={"tripdate"}><span>*</span> Date of Transportation:</label>
                    <input 
                        type={"date"} 
                        name={"tripdate"} 
                        min={this.getDate(1)}
                        max={this.getDate(90)}
                        onChange={(e) => this.collectFormValues(e.target)}
                        onBlur={(e) => this.collectFormValues(e.target)}
                        required />


                    <label 
                        htmlFor={"ptime"}
                        id={"ptimeLabel"} ><span>*</span> Pickup Time:</label>

                    <TimePicker 
                        currentTime={this.getTime()} 
                        defaultValue={this.getTime()}
                        name={"ptime"} 
                        key={"ptime"} 
                        minTime={
                            this.state.tripDateIsToday ? this.getTime() : "00:00"
                        }
                        collectValue={this.collectFormValues.bind(this)}
                        required={true}
                        />

                    <LocationPicker 
                        type={"Pickup"} 
                        tripNumber={1}
                        key={"pu_place_fk"}
                        requiredSymbol={true}
                        getLocationFk={this.collectFormValues.bind(this)} 
                        name={"pu_place_fk"}
                        defaultLocationId={this.props.riderAddressId}
                        filterOut={[this.state.newBooking.do_place_fk]} 
                        required={true} />

                    <LocationPicker 
                        type={"Drop-off"} 
                        tripNumber={1} 
                        key={"do_place_fk" }
                        requiredSymbol={true}
                        getLocationFk={this.collectFormValues.bind(this)} 
                        name={"do_place_fk" }
                        filterOut={[this.state.newBooking.pu_place_fk]}
                        required={true} />

                    <label id={"passengersLabel"} htmlFor={"passengers"}><span>*</span> How many escorts will be riding?</label>
                    <select 
                        name={"passengers"} 
                        onChange={(e) => this.collectFormValues(e.target)}
                        onBlur={(e) => this.collectFormValues(e.target)}
                        defaultValue={""}
                        required>
                        <option disabled value={""}> -- select a number -- </option>
                        <option value={"0"}>0</option>
                        <option value={"1"}>1</option>
                        <option value={"2"}>2</option>
                        <option value={"3"}>3</option>
                        <option value={"4"}>4</option>
                        <option value={"5"}>5</option>
                    </select>
                        

                    <label htmlFor={"leg"}><span>*</span> Round Trip or One-Way?</label>
                    <select 
                        name={"leg"} 
                        onChange={(e) => this.collectFormValues(e.target)}
                        onBlur={(e) => this.collectFormValues(e.target)}
                        defaultValue={"RETURN"}
                        required>
                        <option value={"TAKE"}>One-Way Trip</option>
                        <option value={"RETURN"}>Round Trip</option>
                    </select>

                    {/* only show if trip type is round trip  */}
                    {this.state.returnTime &&
                        <fieldset
                            className={"returnFields"}>
                        <legend>RETURN TRIP DETAILS</legend>

                        <label 
                            htmlFor={"r_ptime"}
                            id={"r_ptimeLabel"} >Return Time:</label>

                        <TimePicker 
                            currentTime={this.getTime()} 
                            defaultValue={""}
                            name={"r_ptime"} 
                            key={"r_ptime"} 
                            minTime={
                                this.state.tripDateIsToday ? this.getTime() : "00:00"
                            }
                            collectValue={this.collectFormValues.bind(this)}
                            required={false}
                            />

                            <label htmlFor={"rt_pu_location"}>Return Pickup Address:</label>
                            <input 
                                type={"text"} 
                                name={"rt_pu_location"} 
                                value={this.state.locations.dropoff} 
                                disabled />

                            <label htmlFor={"rt_do_location"}>Return Drop-off Address:</label>
                            <input 
                                type={"text"} 
                                name={"rt_do_location"} 
                                value={this.state.locations.pickup} 
                                disabled />

                        </fieldset>}

                    <label htmlFor={"mode"}><span>*</span> Mobility Status:</label>
                    <select 
                        name={"mode"} 
                        onChange={(e) => this.collectFormValues(e.target)}
                        onBlur={(e) => this.collectFormValues(e.target)}
                        defaultValue={""}
                        required>
                        <option disabled value={""}> -- select an option -- </option>
                        <option value={"AMB"}>Ambulatory</option>
                        <option value={"WC"}>Wheelchair</option>
                        <option value={"PWC"}>Power Wheelchair</option>
                    </select>

                    <LevelOfService 
                            onChange={this.collectFormValues.bind(this)}
                            onBlur={this.collectFormValues.bind(this)} />

                    <Phone
                        name="phone1"
                        displayName="Phone" 
                        collectValue={this.collectFormValues.bind(this)}
                        phoneRequired={true}/>
                    
                    {!this.state.secondPhoneVisible &&
                        <input 
                            type={"button"}
                            id={"secondPhoneButton"}
                            value={"+ Add a second phone"}
                            onClick={ () =>
                                this.setState({secondPhoneVisible : true})
                            }/>
                    }

                    {this.state.secondPhoneVisible &&
                    <Phone
                        name="phone2"
                        displayName="Phone 2" 
                        collectValue={this.collectFormValues.bind(this)}
                        phoneRequired={false}/>
                    }               

                    <label 
                       htmlFor={"notes"}
                       id={"notes"} >Driver Notes:</label>
                    <textarea 
                        name={"notes"}
                        onChange={(e) => this.collectFormValues(e.target)}
                        onBlur={(e) => this.collectFormValues(e.target)} />

                    {this.props.riderHasPT1s && 
                    <>
                        <label htmlFor="addPT1">
                        <input 
                            type={"checkbox"} 
                            name={"addPT1"} 
                            onChange={(e) => this.collectFormValues(e.target)}
                            onBlur={(e) => this.collectFormValues(e.target)} />
                            &nbsp; Add a PT1 Form to this Trip (MassHealth)?
                        </label>
                    </>}
                    

                    <input 
                        type={"submit"} 
                        value={this.state.addPT1 ? "Next" : "Book Trip"} 
                        id={"bookingFormSubmit"}
                        onClick={async (e) => {
                            await this.submitForm(e)}}></input>
                </form>
            </section>
        )
    }
}

export default PartnerBookingForm