function PT1(){
    return (
        <article className={"viewFullPT1"}>
            <h1>ATS PT1 Information</h1>
            <section>
                <h1>General</h1>
                <p><b>Date Of birth</b>: {window.pt1.dob}</p>
                <p><b>Sex</b>: {window.pt1.sex}</p>
                <p><b>MassHealth Member ID</b>: {window.pt1.member_id}</p>
                <p><b>Home Address</b>: {window.pt1.home_address}</p>
                <p><b>Alternate Pick-up Address</b>: {window.pt1.apu_address !== null 
                    ? window.pt1.apu_address
                    : "N/A  "}</p>
                <p><b>Facility Provider (Drop-off) Address</b>: {window.pt1.provider_address}</p>
            </section>
            <section>
                <h1>Duration and Frequency</h1>
                <p><b>Required Service {window.pt1.require_service_weeks !== "" 
                    ? "Week(s)"
                    : "Month(s)"}</b>: {window.pt1.require_service_weeks !== "" 
                    ? window.pt1.require_service_weeks
                    : window.pt1.require_service_months}</p>
                <p><b>Visits per {window.pt1.require_service_weeks !== "" 
                    ? "Week"
                    : "Month"}</b>: {window.pt1.visit_per_week !== "" 
                    ? window.pt1.visit_per_week
                    : window.pt1.visit_per_month}</p>
                <p><b>Used Visits</b>: {window.pt1.use_visit}</p>
                <p><b>Uses Left</b>: {window.pt1.visit_left}</p>
            </section>
            <section>
                <h1>Approval</h1>
                <p><b>Authorization Expires On</b>: {window.pt1.authorized_expired_date}</p>
                <p><b>Signature Date</b>: {window.pt1.authorized_date}</p>
            </section>
        </article>
    )
}


export default PT1