import React, { Component } from "react";
import PT1Box from "../partsAndFields/PT1Box";
import FileUploadIcon from '@mui/icons-material/FileUpload';

class RiderPT1sPanel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            visible:{
                step1:true,
                step2:false,
            },
            visiblePT1s:[],
            noPT1s: false,
            checkedID:0,
        }
    }

    componentDidMount(){
        console.log(this.props.PT1s);
        if (this.props.PT1s.length !== 0
            && this.props.PT1s !== false){
            this.displayPT1s()
        }
    }

    componentDidUpdate(prevProps){
        if ((this.props.PT1s !== prevProps.PT1s 
            && this.props.PT1s.length !== 0) 
            && this.props.PT1s !== false){
            this.displayPT1s()
        }
    }
    

    usePT1(pt1, id){
        this.setState({checkedID:id}, () => {
            this.displayPT1s()
            this.props.usePT1(pt1)
        })        
    }

    mapPT1(pt1sToMap){
        console.log(pt1sToMap);
        if (this.props.usePT1){
            return pt1sToMap.map(
                (pt1, count) =>
                    <PT1Box 
                        key={count}
                        id={count+1}
                        selected={this.state.checkedID === count+1 ? true : false}
                        pt1={pt1}
                        usePT1={this.usePT1.bind(this)} />
            )
        }
        return pt1sToMap.map(
                (pt1, count) =>
                    <PT1Box 
                        key={count}
                        id={count+1}
                        pt1={pt1} />
            )
    }

    displayPT1s(){
        let prevState = this.state

        prevState.visiblePT1s = this.mapPT1(this.props.PT1s)

        this.setState(prevState)
    }

    render() {
        return(
            <section>
                    <section>
                        <h1>{`${this.props.rider.firstname} ${this.props.rider.lastname}'s PT1'S`}</h1> 
                        {!this.props.usePT1 
                            && <button onClick={() => {
                                    this.props.addPT1(this.props.rider)
                                }}>
                                    ADD NEW PT1
                                    <FileUploadIcon />
                            </button>
                        }
                    </section>
                    <section
                        className={"riderPT1s"}>
                        {this.state.noPT1s === false && this.state.visiblePT1s}
                        {this.props.PT1s === false && 
                        <p> No PT1's for this rider, please add a PT1 from the button on the upper right </p>}
                    </section>
           </section>
        )
    }
}

export default RiderPT1sPanel