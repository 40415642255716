import React, { Component } from "react"
import PartnerBookingForm from "../forms/PartnerBookingForm"
import RecurringTripBookingForm from "./RecurringTripBookingForm"
import axiosInstance from "../../axios";
import RiderPT1sPanel from "../screens/RiderPT1sPanel";
import {validatePT1MatchesTripBooking, 
    validatePT1MatchesRecurringTripBooking} from "../../validation"

class BookingFormSelector extends Component{
    constructor(props){
        super(props)
        this.state = {
            view: {
                partnerFormVisible: false,
                partnerPT1Visible: false,
                recurringFormVisible: false,
                recurringPT1Visible: false,
                formSelectorVisible: true,
            },
            partnerBookingSubmissionParts: {},
            recurringBookingSubmissionParts: {},
            riderTripHistory:[],
            errorMessages:"",
            userSelectedAPT1: false,
            pt1s:[],
        }
    }

    componentDidMount(){        
        this.getPT1s()
    }

    backToDashboard(){
        this.props.handleClick()
    }

    changeFormVisibility(form){
        let prevState = this.state
        for (let view of Object.keys(prevState.view)){
            prevState.view[view] = false
        }
        prevState.view[form] = true
        
        this.setState(prevState)
    }

    async getSingleDayTripRiderHistory(){
        await axiosInstance.get(`trips/upcoming/rider-fk/${this.props.rider.id}`)
            .then(async response => { 
                if (response.data.length !== 0){
                    this.filterDates(response.data, "upcoming")
                }
                await axiosInstance.get(`trips/recent/rider-fk/${this.props.rider.id}`)
                    .then(response => { 
                        if (response.data.length !== 0){
                            this.filterDates(response.data, "recent")
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    })
                }
            )
            .catch(error => {
                console.log(error);
            }
        )
    }

    async getRecurringTripRiderHistory(){
        await axiosInstance.get(`routines/rider-fk/${this.props.rider.id}`)
            .then(async response => { 
                if (response.data.length !== 0){
                    this.filterRecurringDates(response.data)
                }
                }
            )
            .catch(error => {
                console.log(error);
            }
        )
    }

    filterRecurringDates(trips){
        let stateCopy = this.state
        // use the getTime method for dates, because they do not EQ properly
        for (let trip of Object.keys(trips)){
            // returns only count as 1 use of a pt1
            const tempDate = new Date(trips[trip].start_date)            
            const tempPT1FK = trips[trip].pt1
            if (tempPT1FK !== "" && tempPT1FK !== null){
                const tempEndDate = new Date(trips[trip].last_date)
                const tempDay =  this.getDayIDFromString(trips[trip].day)
                stateCopy.riderTripHistory.push([tempPT1FK, tempDate.getTime(), tempEndDate.getTime(), tempDay])
            }
        }
        this.setState({state:stateCopy})
    }

    getDayIDFromString(dayString){
        switch (dayString){
            case "SUN":
                return 0
            case "MON":
                return 1
            case "TUE":
                return 2
            case "WED":
                return 3
            case "THU":
                return 4
            case "FRI":
                return 5
            case "SAT":
                return 6
            default:
                break
        }
    }

    filterDates(trips, type){
        const dateToCheck = new Date()
        dateToCheck.setDate(dateToCheck.getDate() - 31);
        let stateCopy = this.state
        // use the getTime method for dates, because they do not EQ properly
        for (let trip of Object.keys(trips)){
            // returns only count as 1 use of a pt1
            if (trips[trip].leg !== "RETURN"){
                const tempDate = new Date(trips[trip].tripdate)
                const tempPT1FK = trips[trip].pt1
                switch (type){
                    case "upcoming":
                        if (tempPT1FK !== "" && tempPT1FK !== null){
                            stateCopy.riderTripHistory.push([tempPT1FK, tempDate.getTime()])
                        }
                        
                        break
                    case "recent":
                        // only add the past trips within a month past
                        // pt1 only covers frequency within a month
                        if (tempDate > dateToCheck){
                            if (tempPT1FK !== "" && tempPT1FK !== null) {
                               stateCopy.riderTripHistory.push([tempPT1FK, tempDate.getTime()])
                            }
                        } 
                        break
                    default:
                        break
                }
            } 
        }
    }

    async submitPartnerForm(formSubmitButton = this.state.partnerBookingSubmissionParts.formSubmitButton,
        finalBooking = this.state.partnerBookingSubmissionParts.finalBooking,
        r_ptime = this.state.partnerBookingSubmissionParts.r_ptime ){        
        
        if (finalBooking.leg === "RETURN"){
            finalBooking.leg = "TAKE"
            await axiosInstance.post(
                "trips/create",
            finalBooking)
                .then( async response => {
                    let secondBooking = {...finalBooking}
                    secondBooking.leg = "RETURN"
                    secondBooking.ptime = r_ptime
                    secondBooking.next_available = false
                    secondBooking.itin_fk = response.data.trip.itinerary
                    secondBooking.pu_place_fk = finalBooking.do_place_fk
                    secondBooking.do_place_fk = finalBooking.pu_place_fk
                    secondBooking.pt1_fk = ""
                    await axiosInstance.post(
                        "trips/create",
                        secondBooking)
                        .then(() => this.backToDashboard())
                        .catch(response => {
                            formSubmitButton.target.disabled = false
                        })
                })
                .catch(response => {
                    formSubmitButton.target.disabled = false
                    console.log(response)
                })
        }
        else{
            await axiosInstance.post(
                "trips/create", finalBooking)
                .then(() => this.backToDashboard())
                .catch(response => {
                    formSubmitButton.target.disabled = false
                    console.log(response)
                })
        }
    }

    async submitRecurringForm(formSubmitButton = this.state.recurringBookingSubmissionParts.formSubmitButton,
        finalBooking = this.state.recurringBookingSubmissionParts.finalBooking){
        await axiosInstance.post(
            "routines/create", finalBooking)
            .then(() => this.backToDashboard())
            .catch(response => {
                if (this.state.view.recurringPT1Visible === false){
                    formSubmitButton.disabled = false
                }
                console.log(response)
            })
    }

    async getPT1s(){
        let loader = document.createElement("div")
        loader.classList.add("loader")

        await axiosInstance.get(`pt1/get/rider-fk/${this.props.rider.id}`)
            .then(response => {  
                console.log(response.data.data);
                if (response.data.status === 404){
                    this.setState({ pt1s: false })
                } else {
                    this.setState({ pt1s: response.data.data })
                }                  
                loader.remove()
            })
            .catch(error => {
                console.log(error);
            })
    }

    toPartnerPT1(formSubmitButton, finalBooking, r_ptime){
        this.setState({partnerBookingSubmissionParts: {
            "formSubmitButton": formSubmitButton,
            "finalBooking":finalBooking,
            "r_ptime":r_ptime,
        }},  () => {
            this.changeFormVisibility("partnerPT1Visible")
        })
    }

    toRecurringPT1(formSubmitButton, finalBooking){
        this.setState({recurringBookingSubmissionParts: {
            "formSubmitButton": formSubmitButton,
            "finalBooking":finalBooking,
        }},  () => {
            this.changeFormVisibility("recurringPT1Visible")
        })
    }

    usePT1(pt1){
        if (this.state.view.partnerPT1Visible){
            const validation = validatePT1MatchesTripBooking(pt1, this.state.partnerBookingSubmissionParts.finalBooking, this.state.riderTripHistory)
            if (validation.validationStatus === false){
                this.displayErrors(validation.validationMessages)
            } else {
                let stateCopy = this.state
                stateCopy.partnerBookingSubmissionParts.finalBooking.pt1_fk = pt1.pt1_fk
                stateCopy.errorMessages = ""
                this.setState(stateCopy)
            }
        } else {
            const validation = validatePT1MatchesRecurringTripBooking(pt1, this.state.recurringBookingSubmissionParts.finalBooking, this.state.riderTripHistory)
            if (validation.validationStatus === false){
                this.displayErrors(validation.validationMessages)
            } else {
                let stateCopy = this.state
                stateCopy.recurringBookingSubmissionParts.finalBooking.pt1_fk = pt1.pt1_fk
                stateCopy.errorMessages = ""
                this.setState(stateCopy)
            }
        }
        
    }

    displayErrors(messages){
        let stateCopy = this.state
        let errors = messages.map((message, count) => {
            return <li key={count}>{message}</li>
        })
        stateCopy.errorMessages = errors
        stateCopy.userSelectedAPT1 = true
        this.setState(stateCopy)
    }

    checkErrors(){
        if(this.state.userSelectedAPT1 && this.state.errorMessages !== ""){
            if (window.confirm("The PT1 you selected will not be applied. Are you sure you want to submit?")){
                this.state.view.partnerPT1Visible
                ? this.submitPartnerForm()
                : this.submitRecurringForm()
            }
        } else {
            this.state.view.partnerPT1Visible
                ? this.submitPartnerForm()
                : this.submitRecurringForm()
        }
        
    }

    render(){
        return(
            <>
                {this.state.view.partnerFormVisible 
                    && <PartnerBookingForm
                            handleClick={this.backToDashboard.bind(this)}
                            key={this.props.riderId}
                            riderName={this.props.riderName}
                            riderId={this.props.riderId}
                            riderAddressId={this.props.riderAddressId}
                            addPT1={this.toPartnerPT1.bind(this)}
                            submitForm={this.submitPartnerForm.bind(this)}
                            riderHasPT1s={this.state.pt1s === false ? false : true} />
                }

                {this.state.view.recurringFormVisible 
                && <RecurringTripBookingForm
                        handleClick={this.backToDashboard.bind(this)}
                        key={this.props.riderId}
                        riderName={this.props.riderName}
                        riderId={this.props.riderId}
                        riderAddressId={this.props.riderAddressId} 
                        addPT1={this.toRecurringPT1.bind(this)}
                        submitForm={this.submitRecurringForm.bind(this)}
                        riderHasPT1s={this.state.pt1s === false ? false : true} />
                }
        
                {this.state.view.partnerPT1Visible 
                    && <section className={"pt1Select form"}>
                        <section>
                            <button onClick={(e) => {
                                e.preventDefault()
                                this.changeFormVisibility("partnerFormVisible")
                            }}
                            >Back to Trip Details</button>
                            <h1> Select a PT1</h1>
                        </section>
                        <RiderPT1sPanel
                            rider={this.props.rider}
                            usePT1={this.usePT1.bind(this)}
                            PT1s={this.state.pt1s} />
                        {(this.state.userSelectedAPT1 && this.state.errorMessages !== "")
                        && <>
                            <article>
                                <h1>Errors:</h1>
                                <ul>                            
                                    {this.state.errorMessages}
                                </ul>
                            </article>
                        </> }                     
                        <input 
                            type={"submit"} 
                            value={"Book Trip"} 
                            id={"bookingFormSubmit"}
                            onClick={() => {
                                this.checkErrors()}}></input>
                    </section>
                }
        
                {this.state.view.formSelectorVisible 
                && <fieldset   
                        className={"bookingChoice"} 
                        name={this.props.name}>
                        <h1>I want to book a...</h1>
                        <input 
                            type={"button"} 
                            value={"One Day Trip"}
                            onClick={(e) => {
                                this.changeFormVisibility("partnerFormVisible")
                                this.getSingleDayTripRiderHistory()
                            }}/>
                        <input 
                            type={"button"} 
                            value={"Recurring Trip"} 
                            onClick={(e) => {
                                this.changeFormVisibility("recurringFormVisible")
                                this.getRecurringTripRiderHistory()
                            }} />
                    </fieldset>
                }

                {this.state.view.recurringPT1Visible 
                    && <section className={"pt1Select form"}>
                        <section>
                            <button onClick={(e) => {
                                e.preventDefault()
                                this.changeFormVisibility("recurringFormVisible")
                            }}
                            >Back to Trip Details</button>
                            <h1> Select a PT1</h1>
                        </section>
                        <RiderPT1sPanel
                            rider={this.props.rider}
                            usePT1={this.usePT1.bind(this)}
                            PT1s={this.state.pt1s} />
                        {(this.state.userSelectedAPT1 && this.state.errorMessages !== "")
                        && <>
                            <article>
                                <h1>Errors:</h1>
                                <ul>                            
                                    {this.state.errorMessages}
                                </ul>
                            </article>
                        </> }                     
                        <input 
                            type={"submit"} 
                            value={"Book Trip"} 
                            id={"bookingFormSubmit"}
                            onClick={() => {
                                this.checkErrors()}}></input>
                    </section>
                }
            </>
        )
    }
}

export default BookingFormSelector