import React, { Component } from "react";


class Steps extends Component {
    constructor(props) {
        super(props)
        this.state = {
            steps:[],
        }
    }

    componentDidMount(){
        this.computeSteps(this.props.selected)        
    }

    computeSteps(selected = 1){
        let computedSteps = []
        for (let step of this.props.steps){
            computedSteps.push(
                this.makeStep(
                    step, 
                    this.props.steps.indexOf(step) + 1,
                    selected)
                )
        }
        this.setState({steps:computedSteps})
    }

    makeStep(stepName, key, selected){
        let cursor = "pointer"

        if (Object.keys(this.props).includes(`clickable`)){
            if (this.props.clickable[key] === false)
            cursor = "not-allowed"
        }

        const stepWidth = {
            "width": `${100/(this.props.steps.length)-1}%`,
            "cursor": cursor,
        }
        return (
            <li
                key={key}
                style={stepWidth}
                className={selected === key
                    ? `selectedStep`
                    : undefined
                    }
                onClick={
                    () => {
                        if (Object.keys(this.props).includes(`onClickStep`)){
                            this.props.onClickStep(key)
                            
                        }
                        if (Object.keys(this.props).includes(`clickable`)){
                            if (this.props.clickable[key] !== false){
                                this.computeSteps(key)
                            }
                        } else {
                            this.computeSteps(key)
                        }   
                        
                    }
                } >
                {this.props.numbers && `${key}. `}
                {stepName}</li>
        )
    }


    render() {
        return(
            <ul className={"steps"}>
                {this.state.steps}
            </ul>
        )
    }
}

export default Steps