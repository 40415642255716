import React, { Component } from "react"

class DayOfWeekPicker extends Component{
    constructor(props){
        super(props)
        this.state = {
            daysOfTheWeek: 
                [
                    "SUN", 
                    "MON", 
                    "TUE", 
                    "WED",
                    "THU",
                    "FRI",
                    "SAT",
                ],
            selectedDay:"",
            daysOfTheWeekIndex: 
                {
                    SUN: 0, 
                    MON: 1, 
                    TUE: 2, 
                    WED: 3, 
                    THU: 4, 
                    FRI: 5, 
                    SAT: 6, 
                },
        }
    }

    componentDidMount(){
        if (this.props.daysOfTheWeek !== undefined){
            let activeDays = []
            for (let dayObj of this.props.daysOfTheWeek){
                if (dayObj.active === true){
                    activeDays.push(dayObj.day)
                }
            }
            this.setState({daysOfTheWeek:activeDays})
        }
    }

    checked(target){
        for (let label of target.parentElement.parentElement.children){
            label.className = ""
        }

        if (target.checked){
            target.parentElement.className = "radioChecked"
        }
    }

    render(){
        return(
            <fieldset
                key={this.props.name}>
                
                <label><span>* </span>Pick a day of the week</label>
                
                {this.state.daysOfTheWeek.map((day, index) => {
                    return (
                    <React.Fragment key={`${this.props.id}${day}`}>                        
                        <label 
                            htmlFor={`${this.props.id}${day}`}
                            data-name={"dayOfWeek"}
                            data-id={this.state.daysOfTheWeekIndex[day]} 
                            >{day}
                                <input 
                                type={"radio"} 
                                id={`${this.props.id}${day}`} 
                                name={"dayOfWeek"} 
                                data-id={this.state.daysOfTheWeekIndex[day]}
                                value={day}
                                onClick={async e => {
                                    this.checked(e.target)
                                    await this.props.onClick(e.target)
                                }} 
                                required />
                        </label>
                    </React.Fragment>
                    )})
                }
                
            </fieldset> 
        )
    }
}

export default DayOfWeekPicker