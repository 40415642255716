import React, { Component } from "react"
import axiosInstance from "../../axios"

class Ticket extends Component {
    constructor(props) {
        super(props)
        this.state = {
            trips:[],
            tickets:""
        }
    }

    componentDidMount(){
        this.getTicketDetails()
    }
    
    async getTicketDetails(){
        let trips = []
        const type = window.ticketDetails[0] === "routines" ? "routines/" : "trips/"
        await axiosInstance.get(`${type}${window.ticketDetails[1]}`)
            .then(async response => {
                trips.push(response.data)
                await axiosInstance.get(`${type}${window.ticketDetails[2]}`)
                    .then(response => {
                        trips.push(response.data)
                    })
                    .catch(error => {
                        console.log(error);
                    }) 
            })
            .catch(error => {
                console.log(error);
            })

        this.setState({trips:trips}, () => {
            this.generateTickets()
        })
    }

    generateTickets(){
            const tripHolder = this.state.trips[0]
            const tripHolder2 = this.state.trips[1]
            let finalTickets = (
            <article className={"ticketHolder"}>
                    <section id={"ticket"}>
                        <section>
                            <span>{`${tripHolder.rider.lastname}, ${tripHolder.rider.firstname} ${tripHolder.rider.middlename == null ? "" : tripHolder.rider.middlename} (${tripHolder.rider.id})`}</span>
                            <h1>ATS Journeyman</h1>
                            <span>{window.ticketDetails[0] === "routines" ?  tripHolder.start_date: tripHolder.tripdate}</span>
                        </section>
                        <section>
                            <section>
                                <h1>Pick Up</h1>
                                <span>{`${tripHolder.pickup.address0 !== "" ? `${tripHolder.pickup.address0}, `: ""}${tripHolder.pickup.address1 !== "" ? `${tripHolder.pickup.address1}, `: ""}${(tripHolder.pickup.address2 !== "" ? `${tripHolder.pickup.address2}, ` : "")}${tripHolder.pickup.city}, ${tripHolder.pickup.state} ${tripHolder.pickup.zipcode}`}</span>
                                <span>{tripHolder.ptime}</span>
                            </section>
                            <section>
                                <h1>Drop off</h1>
                                <span>{`${tripHolder.dropoff.address0 !== "" ? `${tripHolder.dropoff.address0}, `: ""}${tripHolder.dropoff.address1 !== "" ? `${tripHolder.dropoff.address1}, `: ""}${(tripHolder.dropoff.address2 !== "" ? `${tripHolder.dropoff.address2}, ` : "")}${tripHolder.dropoff.city}, ${tripHolder.dropoff.state} ${tripHolder.dropoff.zipcode}`}</span>
                                <span>{tripHolder.dtime}</span>
                            </section>
                        </section>
                        <section>
                            <span>Transport: {tripHolder.mode}</span>
                            <span>Service: {tripHolder.service}</span>
                            <span>Type: {tripHolder.leg}</span>
                            <span>ID: {tripHolder.id}</span>
                        </section>
                    </section>
                    
                {window.ticketDetails[2] && <section id={"ticket"}>
                        <section>
                            <span>{`${tripHolder2.rider.lastname}, ${tripHolder2.rider.firstname} ${tripHolder2.rider.middlename == null ? "" : tripHolder2.rider.middlename} (${tripHolder2.rider.id})`}</span>
                            <h1>ATS Journeyman</h1>
                            <span>{tripHolder2.tripdate}</span>
                        </section>
                        <section>
                            <section>
                                <h1>Pick Up</h1>
                                <span>{`${tripHolder2.pickup.address0 !== "" ? `${tripHolder2.pickup.address0}, `: ""}${tripHolder2.pickup.address1 !== "" ? `${tripHolder2.pickup.address1}, `: ""}${(tripHolder2.pickup.address2 !== "" ? `${tripHolder2.pickup.address2}, ` : "")}${tripHolder2.pickup.city}, ${tripHolder2.pickup.state} ${tripHolder2.pickup.zipcode}`}</span>
                                <span>{tripHolder2.ptime}</span>
                            </section>
                            <section>
                                <h1>Drop off</h1>
                                <span>{`${tripHolder2.dropoff.address0 !== "" ? `${tripHolder2.dropoff.address0}, `: ""}${tripHolder2.dropoff.address1 !== "" ? `${tripHolder2.dropoff.address1}, `: ""}${(tripHolder2.dropoff.address2 !== "" ? `${tripHolder2.dropoff.address2}, ` : "")}${tripHolder2.dropoff.city}, ${tripHolder2.dropoff.state} ${tripHolder2.dropoff.zipcode}`}</span>
                                <span>{tripHolder2.dtime}</span>
                            </section>
                        </section>
                        <section>
                            <span>Transport: {tripHolder2.mode}</span>
                            <span>Service: {tripHolder2.service}</span>
                            <span>Type: {tripHolder2.leg}</span>
                            <span>ID: {tripHolder2.id}</span>
                        </section>
                    </section> }

                    <input 
                        type={"button"}
                        value={"Print Ticket(s)"}
                        onClick={() =>window.print()} />
                </article>
        )
        this.setState({tickets : finalTickets})
    }

        render(){
            return (
            window.ticketDetails 
            ? 
                this.state.tickets
            : <a href="/">Back to Journeyman</a>
        )
    }
}

export default Ticket