import React, { Component } from "react"

class GooglePlaceSearch extends Component {
    constructor(props) {
        super(props)
        this.autoCompleteRef = React.createRef();
        this.state = {
            query: "",
            autoCompleteField: "",
            autoComplete: {},
        }
    }

    componentDidMount(){
        this.loadScript(
            `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`,
            () => this.handleScriptLoad(this.props.setQuery, this.autoCompleteRef)
        )
    }

    loadScript(url, callback){
        let script = document.createElement("script")
        script.type = "text/javascript"

        if (script.readyState) {
            script.onreadystatechange = function() {
            if (script.readyState === "loaded" || script.readyState === "complete") {
                script.onreadystatechange = null
                callback()
            }
            };
        } else {
            script.onload = () => callback()
        }
        script.src = url;
        document.getElementsByTagName("head")[0].appendChild(script)
    }

    handleScriptLoad(updateQuery, autoCompleteRef) {
        let autoComplete = new window.google.maps.places.Autocomplete(
          autoCompleteRef.current,
          { 
              types: ["address"], 
              componentRestrictions: { 
                  country: "us" 
              },
              fields:["formatted_address", "address_components"]
          }
        )
        autoComplete.addListener("place_changed", () => {
            this.handlePlaceSelect(updateQuery, autoComplete)
        }          
        )
        this.setState({autoComplete: autoComplete})
        
    }

    async handlePlaceSelect(updateQuery, autoComplete) {
        const addressObject = autoComplete.getPlace()
        const query = addressObject.formatted_address
        updateQuery(query)
        this.mapComponents(addressObject.address_components)
        this.props.complete(true)
    }


    mapComponents(addressObject){
        let newAddress = {
            address0: "",
                address1: "",
                address2: "",                        
                city: "",
                state: "",
                zipcode: "",
        }
        addressObject.forEach(function(c) {
            switch(c.types[0]){
                case 'street_number':
                    newAddress.address0 = c.long_name
                    break
                case 'route':
                    newAddress.address1 = c.long_name
                    break
                case 'neighborhood': case 'locality':
                    newAddress.city = c.long_name
                    break
                case 'administrative_area_level_1':  
                    newAddress.state = c.long_name
                    break
                case 'postal_code':
                    newAddress.zipcode = c.long_name
                    break
                default:
                    break
            }
        })
        this.props.updateAddress(newAddress)
    }

    render(){
          return(
            <>     
                <label htmlFor={"address"}> {this.props.label} </label>      
                <input
                    name={"address"}
                    ref={this.autoCompleteRef}
                    onKeyPress={e => {
                        e.key === 'Enter' && e.preventDefault(); 
                    }}
                    onChange={event => {
                        event.preventDefault()
                        this.props.setQuery(event.target.value)}}
                    placeholder="Enter address"
                    value={this.props.query}
                />
            </>
        )
    }

}

export default GooglePlaceSearch