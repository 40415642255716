import React, { Component } from "react"

class SearchInput extends Component {
    constructor(props) {
        super(props)
        this.state = {
            currentOptions: this.props.options, // this will be a prop passed in as an array of objects with keys name and value
            visibleOptions: [],
            selectedId: this.props.currentId ? this.props.currentId : "" ,
            inputFill: this.props.currentValue ? this.props.currentValue : "",
            optionIsSelected: false,
        }
    }

    componentDidMount(){
        if (this.state.inputFill !== ""){
            this.setElement({
                fake:0,
                target: {
                    value: this.props.currentValue,
                    dataset: {
                        value: this.props.currentId,
                    }
                }
            })
        }
    }

    componentDidUpdate(prevProps){
        if (prevProps.options !== this.props.options){
            this.setState({currentOptions: this.props.options})
        }
    }

    cleanSearchQuery(query){
       return query.toUpperCase().replace(/[^\w\s]/, "")
    }

    /**
     * Search the options in state for options matching the value in inputSearch currently 
     * @param {Event} inputSearch the search box
     */
    searchList(inputSearch) {
        let prevState = this.state

        // update box as when typing
        prevState.inputFill= inputSearch.target.value
        prevState.selectedId= null

        // keep the options in clean array each time
        let visibleOptionsCopy = []
        this.state.currentOptions.forEach(
            option => {
                if ((this.cleanSearchQuery(option.name)).includes(this.cleanSearchQuery(inputSearch.target.value))) {
                    visibleOptionsCopy.push(option)
                }
            }
        )

        prevState.visibleOptions = visibleOptionsCopy

        prevState.optionIsSelected= false
        
        this.setState(prevState, 
            () => {
                if (this.state.visibleOptions.length === 0){
                    this.props.insertNewOptionButton(true, inputSearch.target.value)
                } else {
                    this.props.insertNewOptionButton(false)
                }
            })
    }

    /**
     * Add the option to the search box, and update the state of parent renders with choice
     * @param {Event} option the event of the button that is clicked 
     */
    setElement(option) {
        if(!("fake" in option)) option.preventDefault()
        let prevState = this.state

        prevState.optionIsSelected = true

        // set the search box to match name and fk
        prevState.inputFill= option.target.value
        prevState.selectedId= option.target.dataset.value 
    
        // clear the drop down
        prevState.visibleOptions = []
        this.setState(prevState, () => {
            this.props.onChange(document.getElementsByClassName(`${this.props.name}SearchInput`)[0])
            this.props.errorCheck(document.getElementsByClassName(`${this.props.name}SearchInput`)[0], true)
        })
    }


    

    render() {
        return (
            <fieldset data-id={this.props.dataPointer && this.props.dataPointer}>
                <input
                    type={"search"}
                    className={`${this.props.name}SearchInput`}
                    name={this.props.name}
                    value={this.state.inputFill}
                    placeholder={"Type here to search"}
                    data-value={this.state.selectedId}
                    onFocus={() => this.setState({visibleOptions: this.state.currentOptions})}
                    onBlur={(e) => {                            
                            setTimeout(() =>{
                                this.setState({visibleOptions: []})
                            }, 2000)// set delay so that the option can be clicked before disappearing
                        }
                    } 
                    onChange={(e) => {
                        this.searchList(e)
                        this.props.onChange(e.target)
                        this.props.errorCheck(e.target, this.state.optionIsSelected ? true : false);
                    }}
                    autoComplete={"off"} 
                    required={this.props.required}/>
                <ul className={"searchResults"}>
                    {this.state.visibleOptions.map(
                        options =>
                            <li key={options.value}>
                                <button
                                    data-value={options.value}
                                    value={options.name}
                                    onClick={(e) => {
                                        this.setElement(e)
                                    }}>{options.name}</button>
                            </li>
                    )}
                </ul>
            </fieldset>
        )
    }

}

export default SearchInput