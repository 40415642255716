import React, { Component } from "react";
import axiosInstance from "../../axios";
import AddRiderModal from "../modals/AddRiderModal";
import UpdateRiderModal from "../modals/UpdateRiderModal";
import SearchIcon from '@material-ui/icons/Search';
import RiderListItem from "../partsAndFields/RiderListItem";
import {withRouter} from 'react-router-dom';
import RiderListItemMenu from "../partsAndFields/RiderListItemMenu";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

class RiderPanel extends Component {
    constructor(props) {
        super(props);
        this.togglePanelButton = React.createRef();
        this.state = {
            currentRiders: [],
            addRiderModalVisible: false,
            updateRiderModalVisible:false,
            updateRiderObject:{},
            visibleRiders: [],
            searchActive: false,
            hideRiders: false,
            toggleListKeyChange: 0,
            openedRiderKey:0,
        }
    }

    componentDidMount() {
        this.getRiders()
        this.resize();
    }

    resize() {
        if (window.innerWidth <= 760){
            this.setState({hideRiders: true})
        } else {
            this.setState({hideRiders: false})
        }
    }

    async getRiders() {
        let loader = document.createElement("div")
        loader.classList.add("loader")
        localStorage.getItem("access_token") && document.body.appendChild(loader) 
        await axiosInstance.get("riders")
            .then(response => {
                this.setState({ currentRiders: response.data.riders })
                this.displayRiders();
                loader.remove()
            })
            .catch(error => {
                if (error.response.status === 401){
                    localStorage.clear()
                    this.props.history.push("/")
                }
            })
    }

    /**
     * Change the visibility of the RiderModal
     * @param {Event} addRiderButton The button node for opening the modal
     */
    openRiderModal(addRiderButton) {
        addRiderButton.preventDefault();
        this.setState({ addRiderModalVisible: !this.state.addRiderModalVisible });
    }

    openUpdateRiderModal(rider){
        let stateCopy = this.state
        stateCopy.updateRiderModalVisible = !stateCopy.updateRiderModalVisible
        stateCopy.updateRiderObject = rider
        this.setState(stateCopy);
    }

    /**
     * Let the parent element open a new booking form
     * @param {Node} bookRiderButton the button node for booking a rider
     * @param {String} riderName the name of the rider
     */
    openForm(rider) {
        this.props.bookRider(rider)
    }

    addPT1(rider) {
        this.props.addPT1(rider)
    }

    openRiderDashboard(rider){
        this.props.openRiderDashboard(rider)
    }

    mapRiders(ridersToMap){
        return ridersToMap.map(
                rider =>
                    <RiderListItem 
                        rider={rider}
                        key={parseInt(rider.id)}
                        keyId={parseInt(rider.id)}
                        menu={parseInt(this.state.openedRiderKey)}
                        closeRiderMenu={this.closeRiderMenu.bind(this)}
                        expandRiderMenu={this.expandRiderMenu.bind(this)}/>
            )
    }

    displayRiders(){
        let prevState = this.state

        prevState.visibleRiders = this.mapRiders(this.state.currentRiders)

        this.setState(prevState)
    }


    async expandRiderMenu(selectedRider){
        let prevState = this.state

        // close the other menus
        // let parent know which menu is open
        prevState.openedRiderKey = selectedRider.id
        
        // reset the props of rider RiderListItems, so they know which menu is open
        if (!prevState.searchActive){
            prevState.visibleRiders = this.mapRiders(this.state.currentRiders)
        }
        
        // set search off
        prevState.searchActive = false
        
        // get the index of the selectedRider RiderListItem in by is ordered index on screen
        const selectedRiderIndex = 
        prevState.visibleRiders.indexOf(prevState.visibleRiders
            .filter(rider => 
                rider.key === selectedRider.id.toString())[0])

        // insert the Menu, in the correct on screen position
        prevState.visibleRiders.splice(selectedRiderIndex+1, 0,
            <RiderListItemMenu 
                key = {`${selectedRider.id}.1`}
                rider={selectedRider}
                shrinkPanel={this.shrinkPanel.bind(this)}
                expandPanel={this.expandPanel.bind(this)}
                openFormClick={this.openForm.bind(this)}
                addPT1={this.addPT1.bind(this)}
                openRiderDashboard={this.openRiderDashboard.bind(this)}
                openUpdateRiderModal={this.openUpdateRiderModal.bind(this)}
             /> )

        // set state
        prevState.hideRiders = false
        this.setState(prevState)       
    }

    closeRiderMenu(){
        this.setState({openedRiderKey:0}, () => {
            this.displayRiders()
        })
    }

    openSearch(){
        this.setState({searchActive: true}, () => document.getElementById("riderSearch").focus())
    }

    searchRiders(searchQuery){
        let prevState = this.state

         prevState.visibleRiders = this.mapRiders(
            this.state.currentRiders.filter(rider => 
                `${rider.firstname}${rider.middlename == null 
                    ? "" 
                    : rider.middlename}${rider.lastname}${rider.id}`.toUpperCase()
            .includes(searchQuery.toUpperCase())))
        prevState.hideRiders = false
        this.setState(prevState)
    }

    expandPanel(){
        this.props.expandPanel()
    }

    shrinkPanel(){
        this.props.shrinkPanel()
    }

    render() {
        return (
            <section className={"riderPanel"}>
                {this.state.addRiderModalVisible && (
                    <AddRiderModal
                        toggle={this.state.addRiderModalVisible}
                        handleClick={this.openRiderModal.bind(this)}
                        onAddRider={this.getRiders.bind(this)}
                    />
                )}

                {this.state.updateRiderModalVisible && (
                    <UpdateRiderModal
                        rider={this.state.updateRiderObject}
                        handleClick={() => this.setState({updateRiderModalVisible:!this.state.updateRiderModalVisible})}
                        onAddRider={this.getRiders.bind(this)}
                    />
                )}

                {this.state.searchActive 
                    ? <input 
                            type={"search"}
                            onChange={(e) => { 
                                if(e.key === 'Enter' && e.target.value === ""){
                                    this.setState({searchActive: false})
                                } else {
                                    this.searchRiders(e.target.value)
                                }
                            }}
                            placeholder={"Type to search for a rider..."}
                            onBlur={(e) => {e.target.value === "" && this.setState({searchActive: false})}}
                            id={"riderSearch"}/>
                    : <h1 onClick={() => {
                            this.openSearch()
                        }
                        }>SEARCH RIDERS <SearchIcon/> </h1>    
                }

                {this.state.currentRiders.length > 0
                    ? this.state.hideRiders === false && 
                        <ul>
                            {this.state.visibleRiders}
                        </ul>
                    : 
                        <ul>
                            <li className={"noRiders"}>
                                <p>No riders booked currently.</p>
                                <p>To add a rider press the <b>"ADD NEW RIDER"</b> button below OR click  
                                    <input 
                                        type={"button"}
                                        value={"HERE"}
                                        onClick={(e) => this.openRiderModal(e)} /></p>
                            </li>
                        </ul>
                }
                

                <button onClick={(e) => this.openRiderModal(e)}>ADD NEW RIDER</button>
                <button id={"toggleRiderPanel"} 
                    ref={this.togglePanelButton}
                    onClick={(e) => {
                        this.props.togglePanel()}
                        }><ExpandMoreIcon/></button>

            </section>
        )
    }

}

export default withRouter(RiderPanel)