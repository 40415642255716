import React, { Component } from "react"
import {
    validatePhone, 
    validatePhoneExtension
} from "../../validation.js"

class Phone extends Component{
    constructor(props){
        super(props)
        this.state = {
            name: this.props.name,
            displayName: this.props.displayName,
            phoneMinLength: this.props.phoneMinLength ? this.props.phoneMinLength : 10,
            phoneMaxLength: this.props.phoneMaxLength ? this.props.phoneMaxLength : 24,
            extensionMinLength: this.props.extensionMinLength ? this.props.extensionMinLength : 1,
            extensionMaxLength: this.props.extensionMaxLength ? this.props.extensionMaxLength : 5,
            phonePlaceholder: this.props.phonePlaceholder ? this.props.phonePlaceholder : "1111111111",
            extensionPlaceholder: this.props.extensionPlaceholder ? this.props.extensionPlaceholder : "11111",
            phoneRequired: this.props.phoneRequired ? this.props.phoneRequired : false,
            extensionRequired: this.props.extensionRequired ? this.props.extensionRequired : false,
            currentPhone:"",
            currentExtension: "",
            phoneExtensionVisible: false,
            phoneIsValid: false,
        }
    }

    updateParent(target){
        let stateCopy = this.state
        switch (target.name){   
            case this.state.name:
                stateCopy.phoneIsValid = false
                if (validatePhone(target, target,`${target.name}Label`)){
                    stateCopy.phoneIsValid = true
                    stateCopy.currentPhone = target.value
                }
                break
            case `${this.state.name}Extension`:
                if (validatePhoneExtension(target, target, `${target.name}ExtensionLabel`)){
                    stateCopy.currentExtension = target.value
                }
                break
            default:
                console.log(`error on ${target}`);
        }
        this.setState(stateCopy, () => {
            this.props.collectValue(target.parentElement)
        })
    }

    render(){
        return(
            <fieldset 
                className={"phoneInput"} 
                name={this.state.name}
                data-value={this.state.phoneExtensionVisible 
                    ? `${this.state.currentPhone}x${this.state.currentExtension}` 
                    : this.state.currentPhone}
                readOnly={this.state.readOnly} >
                <legend className={"semanticVisible"}>Phone Input</legend>

                <label id={`${this.state.name}Label`} htmlFor={this.state.name}>
                    {this.state.phoneRequired && <span>* </span>}{this.state.displayName}:</label>
                <input 
                    type={"tel"} 
                    name={this.state.name} 
                    placeholder={this.state.phonePlaceholder}
                    defaultValue={this.props.defaultValue ? this.props.defaultValue : ""}
                    minLength={this.state.phoneMinLength}
                    maxLength={this.state.phoneMaxLength}
                    onChange={(e) => this.updateParent(e.target)}
                    onBlur={(e) => this.updateParent(e.target)}
                    required={this.state.phoneRequired} />

                {!this.state.phoneExtensionVisible &&
                    <input
                        type="button"
                        value="+ Add an extension"
                        name="addExtension"
                        onClick={e => {
                            const phone = e.target.previousElementSibling
                            if (validatePhone(phone, phone, phone.previousElementSibling.id)){
                                this.setState({phoneExtensionVisible: !this.state.phoneExtensionVisible})
                            }                           
                        }}/>}

                {this.state.phoneExtensionVisible &&
                <React.Fragment>
                    <label id={`${this.state.name}ExtensionLabel`} htmlFor={`${this.state.name}Extension`}>
                        {this.state.extensionRequired && <span>* </span>}{this.state.displayName} Extension:</label>
                    <input 
                        type={"text"} 
                        name={`${this.state.name}Extension`}
                        placeholder={this.state.extensionPlaceholder}
                        minLength={this.state.extensionMinLength}
                        maxLength={this.state.extensionMaxLength} 
                        onChange={(e) => this.updateParent(e.target)}
                        onBlur={(e) => this.updateParent(e.target)}
                        required={this.state.extensionRequired} />
                </React.Fragment>}

            </fieldset>
        )
    }
}

export default Phone