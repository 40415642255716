import React, { Component } from "react";
import BookingFormSelector from "./components/forms/BookingFormSelector";
import RiderPanel from "./components/screens/RiderPanel";
import ItinsPanel from "./components/screens/ItinsPanel";
import Nav from './components/partsAndFields/Nav';
import AddPT1 from "./components/forms/AddPT1";
import RiderDashboard from "./components/screens/RiderDashboard";
import {withRouter} from 'react-router-dom';


class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      onScreen: {
        dashboard: true,
        formSelector: false,
        pt1Form:false,
        riderDashboard: false,
      },
      rider:{},
      riderId: 0,
      riderName: "",
      riderAddressId:"",
      tripCategory: "upcoming", // upcoming by default 
      panelExpanded: false,
    }
  }


  componentDidMount(){
    if (!localStorage.getItem("access_token")){
      this.props.history.push("/")   
    }
  }

  /**
   * Set all keys of an object to point to false
   * @param {object} onScreenNow 
   * @returns the updated object
   */
  clearScreen(onScreenNow) {
    for (let key of Object.keys(onScreenNow)) {
      onScreenNow[key] = false
    }
    return onScreenNow
  }

  updateCurrentRider(rider){
    let prevState = this.state
    prevState.riderId = rider.id
    prevState.riderAddressId = rider.address
    prevState.riderName = `${rider.firstname} ${rider.middlename} ${rider.lastname}`
    prevState.rider = rider
    this.setState(prevState)                   
  }


  openFormSelector(rider) {
    this.updateCurrentRider(rider)
    let onScreenNow = this.clearScreen(this.state.onScreen)
    onScreenNow.formSelector = !onScreenNow.formSelector
    this.setState({onScreen : onScreenNow})
  }

  openPT1Form(rider){
    this.updateCurrentRider(rider)
    let onScreenNow = this.clearScreen(this.state.onScreen)
    onScreenNow.pt1Form = !onScreenNow.pt1Form
    this.setState({onScreen : onScreenNow})
  }


  openRiderDashboard(rider){
    this.updateCurrentRider(rider)
    let onScreenNow = this.clearScreen(this.state.onScreen)
    onScreenNow.riderDashboard = !onScreenNow.riderDashboard
    this.setState({onScreen : onScreenNow})
  }


  /**
   * Clear all elements then revert back to default dashboard view
   * @param {Event} e the event being changed
   */
  backToDashboard() {
    let onScreenNow = this.clearScreen(this.state.onScreen)
    onScreenNow.dashboard = !onScreenNow.dashboard
    this.setState(onScreenNow)
    this.props.history.push("/ride")
    this.expandPanel()
  }


  togglePanel(){
    const toggleButtonEvent = document.getElementById("toggleRiderPanel")
    const panel = toggleButtonEvent.parentElement

    if (panel.style.width === "2%"){
        this.expandPanel(toggleButtonEvent)
    }
    else{
        this.shrinkPanel(toggleButtonEvent)
    }
  }

  expandPanel(){
      const toggleButtonEvent = document.getElementById("toggleRiderPanel")
      const panel = toggleButtonEvent.parentElement
      const nav = panel.previousElementSibling
      const mainContent = panel.nextElementSibling

      for (let child of panel.children){
          if (child.id !== "toggleRiderPanel"){
              child.style.visibility = "visible"
          }
      }
      panel.style.width = "23%"
      mainContent.style.width = "77%"
      nav.style.width = "77%"
      toggleButtonEvent.style.left = "23%"
      // toggleButtonEvent.currentTarget.style.top = "0%"
      toggleButtonEvent.style.transform = "rotate(0deg)"
      this.setState({panelExpanded: true})
  }

  shrinkPanel(){
      const toggleButtonEvent = document.getElementById("toggleRiderPanel")
      const panel = toggleButtonEvent.parentElement
      const nav = panel.previousElementSibling
      const mainContent = panel.nextElementSibling
      for (let child of panel.children){
          if (child.id !== "toggleRiderPanel"){
              child.style.visibility = "hidden"
          }
      }
      panel.style.width = "2%"
      mainContent.style.width = "98%"
      nav.style.width = "98%"
      toggleButtonEvent.style.left = "2%"
      // toggleButtonEvent.currentTarget.style.top = "45%"
      toggleButtonEvent.style.transform = "rotate(180deg)"
      this.setState({panelExpanded: false})
  }

  render() {
    return (
      <>
      <Nav backToTrips={this.backToDashboard.bind(this)}/>
      <RiderPanel 
        togglePanel={this.togglePanel.bind(this)}
        shrinkPanel={this.shrinkPanel.bind(this)}
        expandPanel={this.expandPanel.bind(this)}
        bookRider={this.openFormSelector.bind(this)} 
        addPT1={this.openPT1Form.bind(this)}
        openRiderDashboard={this.openRiderDashboard.bind(this)} />
        <main>
        {this.state.onScreen.dashboard && (
            <section className={"dashboard"}>
              <ItinsPanel category={this.state.tripCategory} />
            </section>)}
          
          {this.state.onScreen.formSelector && (
            <BookingFormSelector
              handleClick={this.backToDashboard.bind(this)}
              key={this.state.riderId}
              rider={this.state.rider}
              riderName={this.state.riderName}
              riderId={this.state.riderId}
              riderAddressId={this.state.riderAddressId} />)}

          {this.state.onScreen.pt1Form && (
            <AddPT1
              handleClick={this.backToDashboard.bind(this)}
              openRiderDashboard={this.openRiderDashboard.bind(this)}
              expandPanel={this.expandPanel.bind(this)}
              key={this.state.riderId}
              rider={this.state.rider} />)}
          
          {this.state.onScreen.riderDashboard && (
            <RiderDashboard
              handleClick={this.backToDashboard.bind(this)}
              expandPanel={this.expandPanel.bind(this)}
              bookRider={this.openFormSelector.bind(this)} 
              key={this.state.riderId}
              rider={this.state.rider}
              addPT1={this.openPT1Form.bind(this)} />)}
        </main>
      </>
    )
  }
}

export default withRouter(App);