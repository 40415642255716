import axiosInstance from "./axios"

export function placeError(field, label, errorMessage, type = "input"){
    let errorElement = document.createElement("strong")
    errorElement.innerHTML = errorMessage
    switch(type){
        case "select":
            for (let t_field of field.querySelectorAll("select")){
                t_field.classList.add("errorField")
            }
            field.previousElementSibling.appendChild(errorElement)
            break
        default:
            field.classList.add("errorField")
            document.getElementById(label) &&  document.getElementById(label).appendChild(errorElement)
    }
}

export function validatePhone(field, target, label){
    // remove visual errors
    field.classList.remove("errorField")
    document.querySelector(`#${label} strong`) !== null &&  document.querySelector(`#${label} strong`).remove()
    
    target.setCustomValidity("")

    // check characters
    if (isNotOnlyNumbers(target.value)) {
        limitToOnlyNumbers(target)  
        placeError(field, label, " - Invalid characters")
        target.setCustomValidity("Invalid characters")
    // check if empty
    } else if (target.value.length === 0){
        if (target.name === "phone1"){
            // target.setCustomValidity("Required")
            placeError(field, label, " - Required")
            return false
        }
        return true
    // check length
    } else if (target.value.length < 10 || target.value.length > 24){
        limitMaxCharactersTo(target, 24)
        placeError(field, label, " - Must be numbers between 10-24 digits")
        target.setCustomValidity("Must be numbers between 10-24 digits")
    }
     else {
        return true
    }
    return false
}

export function validatePhoneWithExtension(field, target, label){
    // remove visual errors
    field.classList.remove("errorField")
    document.querySelector(`#${label} strong`) !== null &&  document.querySelector(`#${label} strong`).remove()
    
    target.setCustomValidity("")

    // check characters
    if (isNotOnlyNumbersOrCharOf(target.value, "x")) {
        limitToOnlyNumbersOrCharOf(target, "x")
        placeError(field, label, " - Invalid characters")
        target.setCustomValidity("Invalid characters")
    // check if empty
    } else if (target.value.length === 0){
        if (target.name === "phone1"){
            // target.setCustomValidity("Required")
            placeError(field, label, " - Required")
            return false
        }
        return true
    // check length
    } else if (target.value.length < 10 || target.value.length > 24){
        limitMaxCharactersTo(target, 24)
        placeError(field, label, " - Must be numbers between 10-24 digits")
        target.setCustomValidity("Must be numbers between 10-24 digits")
    }
     else {
        return true
    }
    return false
}


export function validatePhoneExtension(field, target, label){
    // remove visual errors
    field.classList.remove("errorField")
    document.querySelector(`#${label} strong`) !== null &&  document.querySelector(`#${label} strong`).remove()
    target.setCustomValidity("")

    // check characters
    if (isNotOnlyNumbers(target.value)) {
        limitToOnlyNumbers(target)
        placeError(field, label, " - Invalid characters")
        target.setCustomValidity("Invalid characters")
    // check if empty
    } else if (target.value.length === 0){
        return true
    // check length
    } else if (target.value.length < 1 || target.value.length > 5){
        placeError(field, label, " - Must be numbers between 1-5 digits")
        target.setCustomValidity("Must be numbers between 1-5 digits")
    }
     else {
        return true
    }
    return false
}

export function validateTime(field, target, label, state){
    //clear errors
    const timeFields = [
        document.getElementsByName("ptime")[0], 
        document.getElementsByName("r_ptime")[0]
    ]
    for (let timeFieldset of timeFields){
        for (let t_field of timeFieldset.querySelectorAll("select")){
            t_field.classList.remove("errorField")
        }
        timeFieldset.previousElementSibling.querySelector("strong") != null && timeFieldset.previousElementSibling.querySelector("strong").remove()
        document.getElementsByName(`${timeFieldset.name}_hour`)[0].setCustomValidity("")
        document.getElementsByName(`${timeFieldset.name}_minute`)[0].setCustomValidity("")
    }

    const compareTime = target.name === "ptime" ? state.r_ptime : state.newBooking.ptime

    if (target.value === compareTime) {
        placeError(field, label, " - The pick up and return pick up times must be different", "select")
        document.getElementsByName(`${target.name}_hour`)[0].setCustomValidity("The pick up and return pick up times must be different")
        document.getElementsByName(`${target.name}_minute`)[0].setCustomValidity("The pick up and return pick up times must be different")
    } else if (
        (target.name === "ptime" && parseInt(target.value) > parseInt(compareTime)) 
        || (target.name === "r_ptime" && parseInt(target.value) < parseInt(state.newBooking.ptime))) {
        placeError(field, label, " - Pick up time must be before return pickup time", "select")
        document.getElementsByName(`${target.name}_hour`)[0].setCustomValidity("Pick up time must be before return pickup time")
        document.getElementsByName(`${target.name}_minute`)[0].setCustomValidity("Pick up time must be before return pickup time")
    } else if (
        (target.name === "r_ptime" && parseInt(target.value) < parseInt(compareTime)) 
        || (target.name === "ptime" && parseInt(target.value) > parseInt(state.r_ptime))) {
        placeError(field, label, " - Pick up time must be before return pickup time", "select")
        document.getElementsByName(`${target.name}_hour`)[0].setCustomValidity("Pick up time must be before return pickup time")
        document.getElementsByName(`${target.name}_minute`)[0].setCustomValidity("Pick up time must be before return pickup time")
    } else {
        return true
    }
    return false
}

export function validateReturnTime(field, target, label, state){
    //clear errors
    const timeFields = [
        document.getElementsByName("ptime")[0], 
        document.getElementsByName("r_ptime")[0]
    ]
    for (let timeFieldset of timeFields){
        for (let t_field of timeFieldset.querySelectorAll("select")){
            t_field.classList.remove("errorField")
        }
        timeFieldset.previousElementSibling.querySelector("strong") != null && timeFieldset.previousElementSibling.querySelector("strong").remove()
        document.getElementsByName(`${timeFieldset.name}_hour`)[0].setCustomValidity("")
        document.getElementsByName(`${timeFieldset.name}_minute`)[0].setCustomValidity("")
    }

    const compareTime = target.name === "ptime" ? state.r_ptime : state.newBooking.ptime

    if (target.value === compareTime) {
        placeError(field, label, " - The pick up and return pick up times must be different", "select")
        document.getElementsByName(`${target.name}_hour`)[0].setCustomValidity("The pick up and return pick up times must be different")
        document.getElementsByName(`${target.name}_minute`)[0].setCustomValidity("The pick up and return pick up times must be different")
    } else if (
        (target.name === "ptime" && parseInt(target.value) > parseInt(compareTime)) 
        || (target.name === "r_ptime" && parseInt(target.value) < parseInt(state.newBooking.ptime))) {
        placeError(field, label, " - Return trip can not be before first trip", "select")
        document.getElementsByName(`${target.name}_hour`)[0].setCustomValidity("Return trip can not be before first trip")
        document.getElementsByName(`${target.name}_minute`)[0].setCustomValidity("Return trip can not be before first trip")
    } else if (
        (target.name === "r_ptime" && parseInt(target.value) < parseInt(compareTime)) 
        || (target.name === "ptime" && parseInt(target.value) > parseInt(state.r_ptime))) {
        placeError(field, label, " - Return trip can not be before first trip", "select")
        document.getElementsByName(`${target.name}_hour`)[0].setCustomValidity("Return trip can not be before first trip")
        document.getElementsByName(`${target.name}_minute`)[0].setCustomValidity("Return trip can not be before first trip")
    } else {
        return true
    }
    return false
}


export function validateNotes(field, target, label){
// remove visual errors
    field.classList.remove("errorField")
    document.querySelector(`#${label} strong`) !== null &&  document.querySelector(`#${label} strong`).remove()
    target.setCustomValidity("")

    // check characters
    if (hasSpecialCharacters(target.value)) {
        limitToNonSpecialCharacters(target)
        placeError(field, label, " - Invalid characters")
        target.setCustomValidity("Invalid characters")
    // check if empty
    } else if (target.value.length > 231){
        limitMaxCharactersTo(target, 231)
        placeError(field, label, " - Only 231 characters allowed")
        target.setCustomValidity("Only 231 characters allowed")
    }
     else {
        return true
    }
    return false
}


export function validateStreetNumber(field){
    // remove visual errors
    field.previousSibling.querySelector("strong") !== null && field.previousSibling.querySelector("strong").remove()
    field.classList.remove("errorField")

    if (isNotOnlyNumbers(field.value)) {
        limitToOnlyNumbers(field)
        placeError(field, field.name, " - Must not have any special characters or letters")
    } else {
        return true
    }
}

export function validateAddress(field){
    // remove visual errors
    field.previousSibling.querySelector("strong") !== null && field.previousSibling.querySelector("strong").remove()
    field.classList.remove("errorField")

    if (hasSpecialCharacters(field.value)) {
        limitToNonSpecialCharacters(field)
        placeError(field, field.name, " - Must not have any special characters")
    } else {
        return true
    }
    return false
}

export function validateZipcode(field){
    // remove visual errors
    field.previousSibling.querySelector("strong") !== null && field.previousSibling.querySelector("strong").remove()
    field.classList.remove("errorField")

    if (isNotOnlyNumbers(field.value)) {
        limitToOnlyNumbers(field)
        placeError(field, field.name, " - Must be only numbers")
    } else if (field.value.length !== 5){
        limitMaxCharactersTo(field, 5)
        placeError(field, field.name, " - Must be 5 digits")
    } else {
        return true
    }
    return false
}

export function validateCity(field){
    // remove visual errors
    field.previousSibling.querySelector("strong") !== null && field.previousSibling.querySelector("strong").remove()
    field.classList.remove("errorField")

    if (hasNonCharacters(field.value)) {
        limitToCharacters(field)
        placeError(field, field.name, " - Must not have any numbers or special characters")
    } else {
        return true
    }
    return false
}


export function validateName(target){
    // remove visual errors
    target.previousSibling.querySelector("strong") !== null && target.previousSibling.querySelector("strong").remove()
    target.classList.remove("errorField")

    if (hasNonCharacters(target.value)) {
        limitToCharacters(target)
        placeError(target, target.name, " - Must not have any numbers or special characters")
    } else {
        return true
    }
    return false
}

export function validateNameWithExtraCharacters(target){
    // remove visual errors
    target.previousSibling.querySelector("strong") !== null && target.previousSibling.querySelector("strong").remove()
    target.classList.remove("errorField")

    if (!(isBetweenMinAndMaxLength(target.value, 2, 26))){
        limitMaxCharactersTo(target, 26)
        placeError(target, target.name, " - Must be between 2 and 26 characters")
    }
    else if (!containsNameCharacters(target.value)) {
        limitToNameCharacters(target)
        placeError(target, target.name, " - Must not have any numbers or special characters")
    } else {
        return true
    }
    return false
}


export function validateRecurringTripPicker(target){
    target.querySelector(`#${target.name}Label strong`) !== null && target.querySelector(`#${target.name}Label strong`).remove()
    target.classList.remove("errorField")

    const {endDate, r_ptime, ...dataset}= target.dataset
    for (let data in dataset){
        if (dataset[data] === ""){
            placeError(target, `${target.name}Label`, " - Must finish one trip before booking another")
            target.setCustomValidity("Must finish one trip before booking another")
            return false
        }
    }
    return true
}

export function isTripDateAndTimeAfter2pmAtLeastADayBeforeSelectedDay(selectedDate){
    if (selectedDate === getDate(0) ||
        selectedDate === getDate(1)) {
            console.log(parseInt(getTime().replace(/:/g,'')) )
            if (parseInt(getTime().replace(/:/g,'')) >= 1500){                
                window.alert("ATS cannot accommodate trips requested after 15:00 (3pm) the day before the scheduled trip. Please reschedule.")
                return true
            }
        }
    return false
}

export function isAfter3PMOnFridayForMondayBooking(selectedDate){
    let todayAsDate = new Date(getDate())

    /**
     * IF TIME AFTER 3PM
     * IF DAY IS FRIDAY
     * IF BOOKING DATE IS MONDAY
     */

    if (
            (   
                (
                    (
                        selectedDate === getDate(3) 
                        || selectedDate === getDate(2)
                        || selectedDate === getDate(1)
                    ) && 
                    todayAsDate.getDay() === 4
                ) 
                || 
                (
                    ( 
                        selectedDate === getDate(2) 
                        || selectedDate === getDate(2)
                ) && 
                    todayAsDate.getDay() === 5
                ) 
                ||
                (
                    selectedDate === getDate(1) &&
                    todayAsDate.getDay() === 6
                )
            )
        &&
        parseInt(getTime().replace(/:/g,'')) >= 1500) {             
            window.alert("ATS cannot accommodate trips requested after 15:00 (3pm) the day before the scheduled trip. Please reschedule.")
            return true
        }
    return false
}

export async function dateIsValidForBooking(dateInQuestion, alert = true){
    const events = await getEvents()    
    
    const setDateAsDate = (selectedDate) => {
        let date = new Date(selectedDate)
        date.setHours(0,0,0,0)
        
        return date.getTime()
    }

    // convert date in question into date object at 00:00
    // convert that Date into miliseconds from j1,1970
    const datetimeToCheck = setDateAsDate(dateInQuestion)

    // convert all the event dates into milliseconds from j1,1970
    events.forEach(event => {
            event.event_seconds = setDateAsDate(event.event_date) 
        })

    // if any block dates === Date, date not valid
    let hits = events.filter(event => {
        return event.event_seconds === datetimeToCheck
    })

    
    if (hits.length > 0){
        console.log(alert)
        if (alert) {
            window.alert(`ATS cannot accommodate a trip on ${dateInQuestion}`)
        }
        return false
    }
    return true
  }

async function getEvents() {
  let data = []
  await axiosInstance.get('events/get/')
      .then(response => {
          data = response.data
      })
      .catch(response => console.log(response))
  return data
}

export function validateMemberId(target){
    // remove visual errors
    target.previousSibling.querySelector("strong") !== null && target.previousSibling.querySelector("strong").remove()
    target.classList.remove("errorField")

    if (isNotOnlyNumbers(target.value)) {
        limitToOnlyNumbers(target)
        placeError(target, target.name, " - Must be only numbers")
    }
    else if (!isBetweenMinAndMaxLength(target.value, 12, 12)) {
        limitMaxCharactersTo(target, 12)
        placeError(target, target.name, " - Must be 12 numbers")
    } else {
        return true
    }
    return false
}

export function validateFacilityName(target){
    // remove visual errors
    target.previousSibling.querySelector("strong") !== null && target.previousSibling.querySelector("strong").remove()
    target.classList.remove("errorField")

    if (!(isBetweenMinAndMaxLength(target.value, 2, 26))){
        limitMaxCharactersTo(target, 26)
        placeError(target, target.name, " - Must be between 2 and 26 characters")
    }
    else if (hasSpecialCharacters(target.value)) {
        limitToCharacters(target)
        placeError(target, target.name, " - Must not have any numbers or special characters")
    } else {
        return true
    }
    return false
}


export function validateMassHealthProviderIDOrSL(target){
    // remove visual errors
    target.previousSibling.querySelector("strong") !== null && target.previousSibling.querySelector("strong").remove()
    target.classList.remove("errorField")

    const error = " - Must be 9 digits followed by 1 character"

    if (hasSpecialCharacters(target.value)){
        limitToNonSpecialCharacters(target)
        placeError(target, target.name, error)
    } else if (!(isBetweenMinAndMaxLength(target.value, 10, 10))){
        limitMaxCharactersTo(target, 10)
        placeError(target, target.name, error)
    } else if (!(isNumDigitsFollowedByNumCharacters(target.value, 9, 1))){
        limitToNumDigitsFollowedByNumCharacters(target, 9, 1)
        placeError(target, target.name, error)
    } else {
        return true
    }
    return false
}

export function validateNPI(target){
    // remove visual errors
    target.previousSibling.querySelector("strong") !== null && target.previousSibling.querySelector("strong").remove()
    target.classList.remove("errorField")

    const error = " - Must be 10 digits"

    if (isNotOnlyNumbers(target.value)){
        limitToOnlyNumbers(target)
        placeError(target, target.name, error)
    } else if (!(isBetweenMinAndMaxLength(target.value, 10, 10))){
        limitMaxCharactersTo(target, 10)
        placeError(target, target.name, error)
    } else {
        return true
    }
    return false
}

export function validateLongShortTextField(target){
    // remove visual errors
    target.previousSibling.querySelector("strong") !== null && target.previousSibling.querySelector("strong").remove()
    target.classList.remove("errorField")

    if (hasSpecialCharacters(target.value)){
        limitToNonSpecialCharacters(target)
        placeError(target, target.name, " - Must not have any special characters")
    } else if (!(isBetweenMinAndMaxLength(target.value, 2, 42))){
        limitMaxCharactersTo(target, 42)
        placeError(target, target.name, " - Must be between 2-42 characters")
    } else {
        return true
    }
    return false
}


export function validateAboveZeroNumber(target){
    // remove visual errors
    target.previousSibling.querySelector("strong") !== null && target.previousSibling.querySelector("strong").remove()
    target.classList.remove("errorField")

    if (isNotOnlyNumbers(target.value)){
        limitToOnlyNumbers(target)
        placeError(target, target.name, " - Must be a number above 0")
    } else if (parseInt(target.value) < 1){
        limitMinNumberTo(target, 1)
        placeError(target, target.name, " - Must be a number above 0")
    } else {
        return true
    }
    return false
}


export function validateMassHealthTrackingNumber(target){
    // remove visual errors
    target.previousSibling.querySelector("strong") !== null && target.previousSibling.querySelector("strong").remove()
    target.classList.remove("errorField")

    if (hasSpecialCharacters(target.value)){
        limitToNonSpecialCharacters(target)
        placeError(target, target.name, " - Must not have any special characters")
    } else if (!(isBetweenMinAndMaxLength(target.value, 2, 26))){
        limitMaxCharactersTo(target, 26)
        placeError(target, target.name, " - Must be between 2-26 characters")
    } else {
        return true
    }
    return false
}

export async function validatePT1MatchesRecurringTripBooking(pt1, booking, tripHistory){
    let validationStatus = true
    let validationMessages = []

    for (let day of booking.recurringDates){
        if (!date1IsBeforeDate2(new Date(day.last_date), new Date(pt1.authorized_expired_date))){
            validationStatus = false
            validationMessages.push("PT1 expires before last date")
        }
    }

    if (parseInt(pt1.visit_left) === 0){
        validationStatus = false
        validationMessages.push("PT1 is out of uses")
    }

    if (!date1IsBeforeDate2(new Date(), new Date(pt1.authorized_expired_date))){
        validationStatus = false
        validationMessages.push("PT1 is expired")
    }

    if (parseInt(booking.pu_place_fk) !== parseInt(pt1.home_address_fk)){
        if (parseInt(booking.pu_place_fk) !== parseInt(pt1.apu_address_fk)){
            validationStatus =  false
            validationMessages.push("Home Address or Alternative Pick-Up Address do not match the booking Pick-up Address")
        }
    }

    if (parseInt(booking.do_place_fk) !== parseInt(pt1.provider_address_fk)){
        validationStatus = false
        validationMessages.push("Facility Address does not match the booking Drop-off Address")
    }

    if (pt1.visit_per_month !== ""){
        validationStatus = false
        validationMessages.push("Monthly PT1s must be booked as separate One-day Trips")
    }

    // if checking week frequency
    if (pt1.visit_per_week !== ""){
        const filteredTripHistory = await filterRecurringTripHistoryByPT1(tripHistory, pt1.pt1_fk)
        // find the sum of the total days on each recurring
        const total_days = await booking.recurringDates
                            .filter(day => !isNaN(parseInt(day.total_days)))
                            .map(day => parseInt(day.total_days))
                            .reduce((a, b) => a + b, 0)

        if (total_days > pt1.uses_left){
            validationStatus = false
            validationMessages.push("PT1 does not have enough uses left for this booking")
        } else {
            for (let day of booking.recurringDates){
                console.log(day)
                // get the all the date between a single day of the week recurring
                let dateRange = (await getDateRange(day.id, day.start_date, day.last_date)).map(date => new Date(date).getTime())
                // get all the dates day booked through recurring
                let filteredTripHistoryDateRangeNest = await Promise.all(filteredTripHistory
                                                    .map(async date => {
                                                        return await getDateRange(date[3], date[1], date[2])                                                      
                                                    }))
                                                    
                const filteredTripHistoryDateRange = filteredTripHistoryDateRangeNest.flat()
                                                    .map(rDate => new Date(rDate).getTime())
                                                                    // get all the dates that match past recurring bookings
                let sameDates = filteredTripHistoryDateRange.filter(element => dateRange.includes(element))
                console.log(sameDates)
                // if there are some matching dates
                if (sameDates.length > 0){
                    // get all the weeks of each booked date
                    let sameWeekDates = sameDates.map(date => selectWeekRange(new Date(date)))
                    console.log(sameWeekDates);
                    // filter it to only have 1 week occurrence per date
                    sameWeekDates = Array.from(new Set(sameWeekDates))
                    console.log(sameWeekDates);
                    // go through each week
                    for (let week of sameWeekDates){
                        // get all trips in the booking history that are in that week 
                        let sameDatesInWeek = filteredTripHistoryDateRange
                                                .filter(element => week.includes(element))
                        console.log(sameDatesInWeek);
                        // if the total booked days in that week are more than the allowed frequency 
                        if (sameDatesInWeek.length > parseInt(pt1.visit_per_week)){
                            validationStatus = false
                            validationMessages.push("PT1 does not allow more visits per week")
                        }
                    }                 
                }
            }
        }        
    }

    return {
        "validationStatus": validationStatus,
        "validationMessages": validationMessages,
     }
}

async function getDateRange(dayID, startDate, endDate){
    if (endDate === ""){
        return ""
    }
    
    const startDateAsDate = new Date(startDate)
    const endDateAsDate = new Date(endDate)

    let dateRange = await getFullDateRange(startDateAsDate, endDateAsDate)

    // get the dates that are the same as chosen
    let filteredRange = dateRange
        .filter(date => parseInt(date.getDay()) === parseInt(dayID))
        .map(day => day.getTime())

    return filteredRange
}

async function getFullDateRange(startDateAsDate, endDateAsDate){
    let dateRange = []
    // loop though all dates between first and last days
    let totalDays = parseInt(
        Math.ceil(
            Math.abs(endDateAsDate -startDateAsDate)
            / (1000 * 60 * 60 * 24)
        )
    )            
    for (let day = 0; day <= totalDays; day++){
        const tempDate = new Date(startDateAsDate)
        tempDate.setDate(tempDate.getDate() + day)
        dateRange.push(tempDate)
    }
    return dateRange
}

export function validatePT1MatchesTripBooking(pt1, booking, tripHistory){
    let validationStatus = true
    let validationMessages = []
    
    if (parseInt(pt1.visit_left) === 0){
        validationStatus = false
        validationMessages.push("PT1 is out of uses")
    }

    if (!date1IsBeforeDate2(new Date(booking.tripdate), new Date(pt1.authorized_expired_date))){
        validationStatus = false
        validationMessages.push("PT1 is expired")
    }

    if (parseInt(booking.pu_place_fk) !== parseInt(pt1.home_address_fk)){
        if (parseInt(booking.pu_place_fk) !== parseInt(pt1.apu_address_fk)){
            validationStatus =  false
            validationMessages.push("Home Address or Alternative Pick-Up Address do not match the booking Pick-up Address")
        }
    }

    if (parseInt(booking.do_place_fk) !== parseInt(pt1.provider_address_fk)){
        validationStatus = false
        validationMessages.push("Facility Address does not match the booking Drop-off Address")
    }
    
    // if checking week frequency
    if (pt1.visit_per_week !== ""){
        const filteredTripHistory = filterTripHistoryByPT1(tripHistory, pt1.pt1_fk)
        const bookedDateParsed = new Date(booking.tripdate)
        // assumes week starts on Sunday and ends on Saturday
        const weekRange = selectWeekRange(bookedDateParsed)
        // get all the occurrences where a past or upcoming trip is booked in the week of the booked trip date
        const sameDates = filteredTripHistory.filter(element => weekRange.includes(element))
        //  if the total occurrences is greater than or equal to the pt1 frequency the pt1 can't be used 
        if (sameDates.length >= parseInt(pt1.visit_per_week)){
            validationStatus = false
            validationMessages.push("PT1 can not be used anymore for week of booked trip date")
        }
    }
        
     // if checking month frequency
     if (pt1.visit_per_month !== ""){
        const filteredTripHistory = filterTripHistoryByPT1(tripHistory, pt1.pt1_fk)
        const bookedDateParsed =  new Date(booking.tripdate)
        const monthRange = selectMonthRange(bookedDateParsed)
        // get all the occurrences where a past or upcoming trip is booked in the month of the booked trip date
        const sameDates = filteredTripHistory.filter(element => monthRange.includes(element))
        //  if the total occurrences is greater than or equal to the pt1 frequency the pt1 can't be used 
        if (sameDates.length >= parseInt(pt1.visit_per_month)){
            validationStatus = false
            validationMessages.push("PT1 can not be used anymore for month of booked trip date")
        }
    }

    return {
        "validationStatus": validationStatus,
        "validationMessages": validationMessages,
     }
}


function filterTripHistoryByPT1(tripHistory, pt1){
    return tripHistory
        .filter(date => date[0] === pt1)
        .map(date => date[1])
}

function filterRecurringTripHistoryByPT1(tripHistory, pt1){
    return tripHistory
        .filter(date => date[0] === pt1)
}

function selectWeekRange(date){
    let range = []

    // set date to the beginning of the week
    date.setDate(date.getDate() - date.getDay())

    // push all the dates in that week to the range
    for (let day = 0; day < 7; day++){
        const tempDate = new Date(date)
        range.push((new Date(tempDate.setDate(tempDate.getDate() + day))).getTime())
    } 

    return range
}

function selectMonthRange(date){
    let range = []

    // get the first and last days of the month
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    // loop though all dates between first and last days of month
    for (let day = parseInt(firstDay.getDate()); day < parseInt(lastDay.getDate()); day++){
        const tempDate = new Date(firstDay)
        tempDate.setDate(tempDate.getDate() + day)
        range.push(tempDate.getTime())
    } 

    return range
}


/**
     * 
     * @param {Number} ahead how far ahead in days the date should be
     * @returns String date in 0000-00-00 (y-d-m) format
     */
 const getDate = (ahead = 0) => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + ahead);
    return `${currentDate.getFullYear()}-${('0' + (currentDate.getMonth() + 1)).slice(-2)}-${('0' + currentDate.getDate()).slice(-2)}`
}


const getTime = () =>{ 
    const today = new Date();
    const minutes = (Math.round(today.getMinutes()/15) * 15) % 60
    return `${(today.getHours() <= 9 ? `0${today.getHours()}` : today.getHours())}:${minutes === 0 ? `${minutes}0` : minutes}`
}

function containsNameCharacters(value){
    if (/^[a-z,.'-]+$/i.test(value)){
        return true
    }
    return false
}

// function isDateAnytimeBeforeToday(value){
//     const valueAsADate = Date.parse(value)
//     if (valueAsADate <= getDate(0)){
//         return true
//     }
//     return false
// }

function isBetweenMinAndMaxLength(value, min, max){
    if (value.length >= min && value.length <= max){
        return true
    }
    return false
}

function isNotOnlyNumbers(value){
    if (/[^0-9]/g.test(value)) {
        return true
    }
    return false
}

function isNotOnlyNumbersOrCharOf(value, char){
    const extra = new RegExp(`[^0-9${char}]`, "g")
    if (extra.test(value)) {
        return true
    }
    return false
}

function isNumDigitsFollowedByNumCharacters(value, digits, characters){
    const tester = new RegExp(`[1-9]{${digits}}[a-zA-Z]{${characters}}`)
    if (tester.test(value)){
        return true
    }
    return false
}

function limitToOnlyNumbersOrCharOf(target, char){
    const extra = new RegExp(`[^0-9${char}]`, "g")
    target.value = target.value.replace(extra, "")
}

function limitToOnlyNumbers(target){
    target.value = target.value.replace(/[^0-9]/g, "")
}

function hasSpecialCharacters(value){
    if (/[^a-zA-Z0-9 ]/g.test(value)) {
        return true
    }
    return false
}

function hasNonCharacters(value){
    if (/[^a-zA-Z ]/g.test(value)) {
        return true
    }
    return false
}

function date1IsBeforeDate2(date1, date2){
    if (date1 < date2){
        return true
    }
    return false
}

function limitToNonSpecialCharacters(target){
    target.value = target.value.replace(/[^a-zA-Z0-9 ]/g, "")
}

function limitMaxCharactersTo(target, length){
    target.value = target.value.substr(0,length);
}

function limitToCharacters(target){
    target.value = target.value.replace(/[^a-zA-Z ]/, "")
}

function limitToNameCharacters(target){
    target.value = target.value.replace(/^[a-z,.'-]+$/i, "")
}

function limitToNumDigitsFollowedByNumCharacters(target, digits, characters){
    const replacer = new RegExp(`[1-9]{${digits}}[a-zA-Z]{${characters}}`)
    target.value = target.value.replace(replacer, "")
}

// function limitMaxNumberTo(target, number){
//     target.value = number
// }

function limitMinNumberTo(target, number){
    target.value = number
}