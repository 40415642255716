import React, { Component } from "react"
import axiosInstance from "../../axios";
import CloseIcon from '@material-ui/icons/Close';
import GooglePlaceSearch from "../GooglePlaceSearch";
import {validatePhone} from "../../validation.js"


class LocationModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            addressObject: {
                address0: "",
                address1: "",
                address2: "",                        
                city: "",
                state: "",
                zipcode: "",
                phone: ""
            },
            addressFormatted: this.props.firstAddress,
            addressReady: false,
        }
    }

    componentDidMount(){
        document.getElementsByName("address")[0].focus()
    }

    setFormatAddress(newAddress){
        if (this.state.addressReady === true
            && newAddress !== "") {
                this.setState({addressReady : false})
            }
        this.setState({addressFormatted:newAddress}, () => {
            if (this.state.addressFormatted === ""){
                this.setState({addressReady : false})
            } 
        })
               
    }

    updateAddress(newAddress){
        let currentAddressCopy = this.state.addressObject
        currentAddressCopy.address0 = newAddress.address0
        currentAddressCopy.address1 = newAddress.address1
        currentAddressCopy.address2 = ""
        currentAddressCopy.city = newAddress.city
        currentAddressCopy.state = newAddress.state
        currentAddressCopy.zipcode = newAddress.zipcode
        this.setState({addressObject:currentAddressCopy})
    }

    /**
     * Submit the values in the add location modal. Then re-render the list od locations
     * 
     * @param {Event} submitButton 
     */
    async onFormSubmit(submitButton) {
        submitButton.preventDefault();
        submitButton.target.disabled = true
        
        let newLocationFinal = this.state.addressObject;
        if (this.state.addressReady === true)
        {
            let loader = document.createElement("div")
            loader.classList.add("loader")
            document.body.appendChild(loader)
            
            await axiosInstance.post(
                "places/",
                newLocationFinal)
                .then(response => {
                    this.props.onAddLocation()
                    this.props.updateSelectedState(
                        {
                            dataset:{
                                value:response.data.frequentplace_pk,
                                id:this.props.updateId
                            }, 
                            name:this.props.addressType,
                            value:this.state.addressFormatted,
                        }
                    )
                    this.props.updateSelectedVisibility(newLocationFinal, response.data.frequentplace_pk)
                    this.props.handleClick(submitButton)
                    loader.remove()
                })
                .catch(response => submitButton.target.disabled = false)
            }
    }

    addressReady(readiness){
        this.setState({addressReady : readiness})
    }

    phoneChange(target){
        let oldAddress = this.state.addressObject
        let field = document.getElementsByName(target.name)[0]
        if (validatePhone(field, target, "phoneLabel")) {
            oldAddress.phone = target.value
        }
        this.setState({addressObject: oldAddress})
    }

    render() {
        return (
            <dialog open={this.props.toggle}>
                <h1>Add a new address
                <button onClick={this.props.handleClick}> <CloseIcon/> </button>
                </h1>

                    <GooglePlaceSearch 
                        query={this.state.addressFormatted} 
                        setQuery={this.setFormatAddress.bind(this)} 
                        updateAddress= {this.updateAddress.bind(this)}
                        complete={this.addressReady.bind(this)} 
                        label={"Address:"} />
                    
                    <label 
                        htmlFor={"phone"}
                        id={"phoneLabel"}>Phone number</label>
                    <input 
                        type={"tel"} 
                        name={"phone"} 
                        placeholder={"111-111-1111"} 
                        onChange={(e) => this.phoneChange(e.target)} 
                        onBlur={(e) => this.phoneChange(e.target)} />


                    <input 
                        type={"submit"} 
                        value={"Add New Address"} 
                        id={"addLocationSubmit"}
                        onClick={(e) => {
                            e.preventDefault()
                            this.onFormSubmit(e)
                            }} />

            </dialog>
        )
    }
}

export default LocationModal