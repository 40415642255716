import React, { Component } from "react";
import axiosInstance from "../../axios";
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import ItinRow from "../partsAndFields/ItinRow";
import RecurringTripRow from "../partsAndFields/RecurringTripRow";

class ItinsPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentItins: [], // all itins from get
            visibleItins: [], // itins as HTML on screen now
            firstRideVisible: 0, // first visible itin on screen now
            prevPages: false, // prev button visibility 
            nextPages: true, // next button visibility
            itinCategory:this.props.category,
            abbreviateColumns: false,
            fromModal:false,
            page:0,
        }
    }

    componentDidMount(prevState) {
        this.getItins()
        this.resize()
    }



    resize() {
        if (window.innerWidth <= 760){
            this.setState({abbreviateColumns: true})
        } else {
            this.setState({abbreviateColumns: false})
        }
    }

    async getItins() {
        let loader = document.createElement("div")
        loader.classList.add("loader")
        localStorage.getItem("access_token") && document.body.appendChild(loader) 

        const itin_sort = (a, b) => {
            return new Date(a.trips[0].tripdate).getTime() - new Date(b.trips[0].tripdate).getTime();
        }

        const recur_sort = (a, b) => {
            return new Date(a.start_date).getTime() - new Date(b.start_date).getTime();
        }

        const endpoint = this.state.itinCategory === "routines"
        ? `/${this.state.itinCategory}`
        : `trips/${this.state.itinCategory}`
        await axiosInstance.get(endpoint)
            .then(response => {
                
                if (this.state.itinCategory === "routines"){
                    response.data.sort(recur_sort)
                } else {
                    response.data.sort(itin_sort)
                }
                
                this.setState({ currentItins: response.data }, 
                    () => {
                        this.setState({ firstRideVisible: this.state.fromModal ? this.state.page :0},
                        () => {
                            this.getItinPage(this.state.firstRideVisible, true)
                            loader.remove()
                        }
                    )
                    }
                )
            })
            .catch(error => {
                console.log(error);
                if (error.response.status === 401){
                    localStorage.clear()
                }
            })
    }

    onModalClose(firstItin){
        this.setState({fromModal:true}, ()=> {
            this.setState({page:firstItin}, () =>{
                this.getItins()
            }
            )
        })  
    }


    /**
     * update the first ride so that state knows what itins to get next
     * @param {React.state} prevState the entire state of your object
     * @param {num} page the id of the itin you are updating
     */
    updateFirstVisibleRide(prevState, page) {
        prevState.firstRideVisible = page
        this.setState(prevState)
    }

    /**
     * Send HTML of the itin you want to the state under {visibleItins}
     * @param {num} itin the id of first itin in your page 
     * @param {boolean} next true if a next button click false if a prev button click
     */
    getItinPage(itinNumber, next) {
        // a page has the ids of the number of itins visible
        const page = [] 
        // fill with the next id in current itins 
        for(let x = 0; x <= 6 ;x++){
            if (this.state.currentItins[itinNumber + x]){
                page[x] = this.state.currentItins[itinNumber + x].id
            }
        }

        let prevState = this.state
        prevState.visibleItins = this.state.currentItins.filter(itin => page.includes(itin.id)).map(
            itin => 
                this.state.itinCategory !== "routines"
                ? <ItinRow 
                    currentItins={itin}
                    abbreviateColumns ={this.state.abbreviateColumns}
                    updateItins={this.onModalClose.bind(this)}
                    page={itinNumber}
                    key={`${itinNumber}-${itin.id}`} />
                : <RecurringTripRow 
                    currentItins={itin}
                    abbreviateColumns ={this.state.abbreviateColumns}
                    updateItins={this.onModalClose.bind(this)}
                    page={itinNumber}
                    key={`${itinNumber}-${itin.id}`} />
            )

        // update the state to reflect the first itin
        this.updateFirstVisibleRide(this.state, itinNumber)
        
        // reset the buttons
        prevState.nextPages = true
        prevState.prevPages = false

        // check if there there should still be a next button by checking if you have the correct amount of itins
        if (!this.state.currentItins[ next ? itinNumber + 7 : itinNumber]) {
            prevState.nextPages = false
        }

        // check if there there should still be a prev button by checking if you are on the first page or not
        if (this.state.firstRideVisible !== 0 ) {
            prevState.prevPages = true 
        }

        this.setState(prevState)
    }

    capitalizeFirstLetter(string) {
        return string[0].toUpperCase() + string.slice(1)
    }

    setItinCategory(itinButton){
        let prevState = this.state

        prevState.itinCategory = itinButton.target.id
        prevState.fromModal = false

        this.setState(prevState, () => this.getItins())
    }

    render() {
        return (
            <section className={`itinsPanel`}>
                <ul>
                    <li>
                        <input 
                            className={this.state.itinCategory === "upcoming"
                                ? "subSelected"
                                : ""} 
                            type="button" 
                            value="Upcoming"
                            id="upcoming"
                            onClick={(e) => {
                                this.setItinCategory(e)
                            }}/>
                    </li>
                    <li>
                        <input className={this.state.itinCategory === "recent"
                                ? "subSelected"
                                : ""}
                            type="button" 
                            value="Recent"
                            id="recent"
                            onClick={(e) => {
                                this.setItinCategory(e)
                            }}/>
                    </li>
                    <li>
                        <input className={this.state.itinCategory === "routines"
                                ? "subSelected"
                                : ""} 
                            type="button" 
                            value="Recurring"
                            id="routines"
                            onClick={(e) => {
                                this.setItinCategory(e)
                            }} />
                    </li>
                </ul>
                {this.state.currentItins.length > 0 
                ? 
                    <table>
                    <thead>
                        <tr>
                            <th>Rider</th>
                            {this.state.itinCategory === "routines" ? <th>Day</th> : <th>Date</th>}
                            <th>Pickup / Drop off</th>
                            {this.state.abbreviateColumns === false && <th>Time</th>}
                            {this.state.abbreviateColumns === false && <th>Type</th>}
                            {this.state.abbreviateColumns === false &&<th>Status</th>}
                            <th>Details</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.visibleItins}
                    </tbody>
                    </table>
                :
                    <section className="noItins">
                        <p>No {this.state.itinCategory} itineraries currently. To book an itinerary:
                            <ol>
                                <li>Click a rider from the list to the left</li>   
                                <li>Click "BOOK A TRIP FOR RIDER"</li> 
                            </ol>
                        </p>
                    </section>
                }
                <ul>
                    <li>
                        {this.state.prevPages && (
                            <button
                                onClick={() => {
                                    this.getItinPage(this.state.firstRideVisible - 7, false)}}>
                                <PlayArrowIcon style={{transform: "rotate(180deg)"}} /> Prev Page
                                </button>
                        )}
                    </li>
                <li>
                    {this.state.nextPages && (
                        <button
                        onClick={() => {
                            this.getItinPage(this.state.firstRideVisible + 7, true)}}>
                            Next Page <PlayArrowIcon/>
                        </button>
                    )}
                </li>
                </ul>
            </section>
        )
    }

}

export default ItinsPanel