import React, { Component } from "react";
import axiosInstance from "../../axios";
import RiderItinRow from "../partsAndFields/RiderItinRow";
import RiderRecurringTripRow from "../partsAndFields/RiderRecurringTripRow";


class RiderItinsPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentItins: [], // all itins from get
            visibleItins: [], // itins as HTML on screen now
            firstRideVisible: 0, // first visible itin on screen now
            prevPages: false, // prev button visibility 
            nextPages: true, // next button visibility
            itinCategory:this.props.category,
            abbreviateColumns: false,
            fromModal:false,
            page:0,
        }
    }

    componentDidMount(prevState) {
        this.getItins()
        this.resize()
    }



    resize() {
        if (window.innerWidth <= 760){
            this.setState({abbreviateColumns: true})
        } else {
            this.setState({abbreviateColumns: false})
        }
    }

    async getItins() {
        let loader = document.createElement("div")
        loader.classList.add("loader")
        localStorage.getItem("access_token") && document.body.appendChild(loader) 

        const itin_sort = (a, b) => {
            return new Date(a.trips[0].tripdate).getTime() - new Date(b.trips[0].tripdate).getTime();
        }

        const recur_sort = (a, b) => {
            return new Date(a.start_date).getTime() - new Date(b.start_date).getTime();
        }

        const endpoint = 
        this.state.itinCategory === "routines"
        ? `/routines/rider-fk/${this.props.rider.id}`
        : 
        `trips/${this.state.itinCategory}-intins/rider-fk/${this.props.rider.id}`
        await axiosInstance.get(endpoint)
            .then(response => {
                
                if (this.state.itinCategory === "routines"){
                    response.data.sort(recur_sort)
                } else {
                    response.data.sort(itin_sort)
                }
                
                this.setState({ currentItins: response.data }, 
                    () => {
                        this.setState({ firstRideVisible: this.state.fromModal ? this.state.page :0},
                        () => {
                            this.getItinPage(this.state.firstRideVisible, true)
                            loader.remove()
                        }
                    )
                    }
                )
            })
            .catch(error => {
                if (error.response.status === 404){
                    loader.remove()
                }
            })
    }

    onModalClose(firstItin){
        this.setState({fromModal:true}, ()=> {
            this.setState({page:firstItin}, () =>{
                this.getItins()
            }
            )
        })  
    }


    /**
     * update the first ride so that state knows what itins to get next
     * @param {React.state} prevState the entire state of your object
     * @param {num} page the id of the itin you are updating
     */
    updateFirstVisibleRide(prevState, page) {
        prevState.firstRideVisible = page
        this.setState(prevState)
    }

    /**
     * Send HTML of the itin you want to the state under {visibleItins}
     * @param {num} itin the id of first itin in your page 
     * @param {boolean} next true if a next button click false if a prev button click
     */
    getItinPage(itinNumber) {
        let prevState = this.state
        prevState.visibleItins = this.state.currentItins.map(
            itin => 
                this.state.itinCategory !== "routines"
                ? <RiderItinRow 
                    currentItins={itin}
                    abbreviateColumns ={this.state.abbreviateColumns}
                    updateItins={this.onModalClose.bind(this)}
                    page={itinNumber}
                    key={`${itinNumber}-${itin.id}`}
                    openPT1={this.openPT1.bind(this)} />
                : <RiderRecurringTripRow 
                    currentItins={itin}
                    abbreviateColumns ={this.state.abbreviateColumns}
                    updateItins={this.onModalClose.bind(this)}
                    page={itinNumber}
                    key={`${itinNumber}-${itin.id}`}
                    openPT1={this.openPT1.bind(this)} />
            )
        this.setState(prevState)
    }

    capitalizeFirstLetter(string) {
        return string[0].toUpperCase() + string.slice(1)
    }

    setItinCategory(itinButton){
        let prevState = this.state

        prevState.itinCategory = itinButton.target.id
        prevState.fromModal = false

        this.setState(prevState, () => this.getItins())
    }

    flattenObject(obj){
        const flattened = {}
      
        Object.keys(obj).forEach((key) => {
          const value = obj[key]
      
          if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
            Object.assign(flattened, this.flattenObject(value))
          } else {
            flattened[key] = value
          }
        })
      
        return flattened
    }

    async openPT1(pt1Fk){
        console.log( this.props.pt1s)
        // find matching fk in pt1s
        for (const pt1 of this.props.pt1s){
            if (parseInt(pt1.id) === parseInt(pt1Fk)){
                // open the window
                // const pt1URI = await makePT1()
                const pt1Window = 
                    window
                    .open(
                        window
                        .location
                        .origin 
                        + `/pt1`, '_blank', 'toolbar=0,location=0,menubar=0'
                    )
                pt1Window.window.pt1 = this.flattenObject(pt1)
            }
        }
    }

    render() {
        return (
            <section className={`itinsPanel riderItinsPanel`}>
                <ul>
                    <li>
                        <input 
                            className={this.state.itinCategory === "upcoming"
                                ? "subSelected"
                                : ""} 
                            type="button" 
                            value="Upcoming"
                            id="upcoming"
                            onClick={(e) => {
                                this.setItinCategory(e)
                            }}/>
                    </li>
                    <li>
                        <input className={this.state.itinCategory === "recent"
                                ? "subSelected"
                                : ""}
                            type="button" 
                            value="Recent"
                            id="recent"
                            onClick={(e) => {
                                this.setItinCategory(e)
                            }}/>
                    </li>
                    <li>
                        <input className={this.state.itinCategory === "routines"
                                ? "subSelected"
                                : ""} 
                            type="button" 
                            value="Recurring"
                            id="routines"
                            onClick={(e) => {
                                this.setItinCategory(e)
                            }}
                            // style={{"cursor":"not-allowed"}} 
                            />
                    </li>
                </ul>
                {this.state.currentItins.length > 0 
                ? 
                    <table>
                    <thead>
                        <tr>
                            
                            {this.state.itinCategory === "routines" ? <th>Day</th> : <th>Date</th>}
                            <th>Pickup / Drop off</th>
                            {this.state.abbreviateColumns === false && <th>Time</th>}
                            {this.state.abbreviateColumns === false && <th>Type</th>}
                            {this.state.abbreviateColumns === false &&<th>Status</th>}
                            <th>PT1</th>
                            <th>Details</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.visibleItins}
                    </tbody>
                    </table>
                :
                    <p>No {this.state.itinCategory} itineraries currently.
                    <button 
                        className={"bookRider"}
                        data-id={this.props.rider.id}
                        data-address-id={this.props.rider.address}
                        title={`Book a trip for ${this.props.rider.lastname}, ${this.props.rider.firstname} ${this.props.rider.middlename == null ? "" : this.props.rider.middlename} (${this.props.rider.id})`.toUpperCase()}
                        onClick={() => {
                            this.props.bookRider(this.props.rider)
                            }
                        }>
                    Book a trip for rider {`${this.props.rider.lastname}, ${this.props.rider.firstname} ${this.props.rider.middlename == null ? "" : this.props.rider.middlename} (${this.props.rider.id})`.toUpperCase()}
                    </button>
                    </p>
                }
            </section>
        )
    }

}

export default RiderItinsPanel