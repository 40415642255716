import React, { Component } from "react";
import LocationPicker from "../partsAndFields/LocationPicker";
import RecurringTripPicker from "../partsAndFields/RecurringTripPicker";
import {
    validateNotes,
    validateRecurringTripPicker
} from "../../validation.js"
import Phone from "../partsAndFields/Phone";
import LevelOfService from "../partsAndFields/LevelOfService";


class RecurringTripBookingForm extends Component {
    constructor(props) {
        super(props);
        this.submitForm = this.submitForm.bind(this);
        this.state = {
            newBooking: {
                rider_fk: parseInt(this.props.riderId),
                pu_place_fk: 0,
                do_place_fk: 0,
                passengers: 0,
                wheelchairs: 0,
                bringwheelchair: 0,
                ridealone: true,
                notes: "",
                phone1: "",
                phone2: "",
                mode: "",
                service: "RTR",
                pt1_fk:"",
                recurringDates:[],
            },
            returnTime: true,
            locations: {
                pickup: "",
                dropoff: "",
            },
            phone1Extension:"",
            phone2Extension:"",
            mobile: false,
            tripDateIsToday:false,
            daysOfTheWeekAvailable: 
            [
                {
                    day:"SUN",
                    active: true,
                },
                {
                    day:"MON",
                    active: true,
                },
                {
                    day:"TUE",
                    active: true,
                }, 
                {
                    day:"WED",
                    active: true,
                }, 
                {
                    day:"THU",
                    active: true,
                }, 
                {
                    day:"FRI",
                    active: true,
                }, 
                {
                    day:"SAT",
                    active: true,
                }, 
            ],
            recurringTripComponents:[],
            secondPhoneVisible: false,
            addPT1: false,
        }
    }

    componentDidMount(){
        this.resize()
        document.getElementsByClassName("bookingForm")[0].focus()
        let stateCopy = this.state
        this.addNewRecurringTrip(stateCopy)
        this.setState(stateCopy)
    }

    resize() {
        if (window.innerWidth <= 760){
            this.setState({mobile: true})
        } else {
            this.setState({mobile: false})
        }
    }

    /**
     * 
     * @param {Event} formSubmitButton 
     */
    async submitForm(formSubmitButton) {
        formSubmitButton.preventDefault()
        const form = formSubmitButton.target.parentElement
        
        
        if (!this.checkForm(form)){
            formSubmitButton.target.disabled = true
            const finalBooking = this.state.newBooking

            // add phone extensions to phone keys
            finalBooking.phone1 += this.state.phone1Extension
            finalBooking.phone2 += this.state.phone2Extension

            if (this.props.riderHasPT1s && this.state.addPT1){
                this.props.addPT1(formSubmitButton, finalBooking)
            } else {
                this.props.submitForm(formSubmitButton, finalBooking)
            }
        }
    }

    checkForm(form){
        let notReady = false

        form.checkValidity() ? notReady = false : notReady = true
        
        if (this.state.addPT1 === true){
            for (let day of this.state.newBooking.recurringDates){
                if (day.last_date === ""){
                    notReady =  true
                    window.alert("Recurring PT1 trips must have an end date")
                }
            }
        }
        
        form.reportValidity()
        return notReady
    }

    /**
     * Update state with form values
     * @param {EventTarget} target the change target of the form field
     */
    async collectFormValues(target) {
        let stateCopy = this.state
        let field = document.getElementsByName(target.name)[0]
        const compare = target.name ? target.name : target.dataset.name
        
        switch (compare) {
            case "phone1":
                stateCopy.newBooking.phone1 = target.dataset.value
                break
            case "phone2":
                stateCopy.newBooking.phone2 = target.value
                break                
            case "next_available":
                stateCopy.newBooking.next_available = !stateCopy.newBooking.next_available
                break
            case "do_place_fk":
                stateCopy.newBooking.do_place_fk = parseInt(target.dataset.value)
                stateCopy.locations.dropoff = target.value
                break
            case "pu_place_fk":
                stateCopy.newBooking.pu_place_fk = parseInt(target.dataset.value)
                stateCopy.locations.pickup = target.value
                break
            case "passengers":
                stateCopy.newBooking.passengers = parseInt(target.value)
                break
            case "mode":
                stateCopy.newBooking.mode = target.value
                break
            case "bringwheelchair":
                stateCopy.newBooking.bringwheelchair = parseInt(target.value)
                break
            case "service":
                stateCopy.newBooking.service = target.value
                break
            case "notes":
                if (validateNotes(field, target, "notes")){
                    stateCopy.newBooking.notes = target.value
                }
                break
            case "addNewTrip":
                const rt_target = document.getElementById(
                    `rt${this.state.recurringTripComponents[this.state.recurringTripComponents.length -1].key}`)
                

                if (validateRecurringTripPicker(rt_target)){
                    if (window.confirm("Once you add a new trip you will not be able to edit this one")){
                        // turn off the date that was selected
                        for (let dayObj of stateCopy.daysOfTheWeekAvailable){
                            if (stateCopy.newBooking.recurringDates[stateCopy.recurringTripComponents.length -1].day === dayObj.day){
                                dayObj.active = false
                            }
                        }
                        this.addNewRecurringTrip(stateCopy)
                        for(let child of rt_target.children){
                            child.disabled = true
                            if (child.dataset.id === "rtCloser"){
                                child.remove()
                            }
                        }
                    }
                }
                rt_target.checkValidity()
                rt_target.reportValidity()
                break
            case "recurringTripPicker":
                // add in the new date obj
                stateCopy.newBooking.recurringDates[stateCopy.recurringTripComponents.length -1] = await {
                            id: target.dataset.id,
                            day: target.dataset.day,
                            start_date: target.dataset.startDate,
                            last_date: target.dataset.endDate,
                            ptime:target.dataset.ptime,
                            r_ptime: target.dataset.r_ptime,
                            total_days: await this.totalDayFrequency(target.dataset.id, target.dataset.startDate, target.dataset.endDate)
                        } 
                break
            case "addPT1":
                stateCopy.addPT1 = target.checked
                break
            default:
                console.log(`error on ${target}`)
        }
        this.setState(stateCopy)
    }

    async totalDayFrequency(dayID, startDate, endDate){
        if (endDate === ""){
            return ""
        }
        
        const startDateAsDate = new Date(startDate)
        const endDateAsDate = new Date(endDate)
    
        let dateRange = await this.getDateRange(startDateAsDate, endDateAsDate)

        // get the dates that are the same as chosen
        let filteredRange = (dateRange.filter(date => date.getDay() === parseInt(dayID)))

        return filteredRange.length
    }

    async getDateRange(startDateAsDate, endDateAsDate){
        let dateRange = []
        // loop though all dates between first and last days
        let totalDays = parseInt(
            Math.ceil(
                Math.abs(endDateAsDate -startDateAsDate)
                / (1000 * 60 * 60 * 24)
            )
        )            
        for (let day = 0; day <= totalDays; day++){
            const tempDate = new Date(startDateAsDate)
            tempDate.setDate(tempDate.getDate() + day)
            dateRange.push(tempDate)
        }
        return dateRange
    }

    addNewRecurringTrip(stateCopy){
        stateCopy.recurringTripComponents.push(
            <RecurringTripPicker 
                name={"recurringTripPicker"}
                daysOfTheWeek={this.state.daysOfTheWeekAvailable}
                onChange={this.collectFormValues.bind(this)}
                key={stateCopy.recurringTripComponents.length +1}
                id={stateCopy.recurringTripComponents.length +1}
                removeRTrip={this.removeMostRecentRecurringTrip.bind(this)}
            />
        )
    }

    removeMostRecentRecurringTrip(){
        let prevState = this.state
        if (prevState.recurringTripComponents.length > 1){
            prevState.recurringTripComponents.pop()
        }
        if (prevState.newBooking.recurringDates.length > 1){
            prevState.newBooking.recurringDates.pop()
        }
        this.setState(prevState)
    }

    /**
     * 
     * @param {Number} ahead how far ahead in days the date should be
     * @returns String date in 0000-00-00 (y-d-m) format
     */
    getDate(ahead = 0){
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() + ahead);
        return `${currentDate.getFullYear()}-${('0' + (currentDate.getMonth() + 1)).slice(-2)}-${('0' + currentDate.getDate()).slice(-2)}`
    }


    getTime(){
        const today = new Date();
        const minutes = (Math.round(today.getMinutes()/15) * 15) % 60
        return `${(today.getHours() <= 9 ? `0${today.getHours()}` : today.getHours())}:${minutes === 0 ? `${minutes}0` : minutes}`
    }

    render() {
        return (
            <section className={"recurringTripsForm bookingForm form"}>
               <form onSubmit={(e) => this.submitForm(e)}>

                    <fieldset className={"fieldTop"}>
                        <button onClick={(e) => {
                            e.preventDefault()
                            this.props.handleClick()
                        }}
                        >Back To Dashboard</button>
                        <h1> Book a Trip for {this.props.riderName}</h1>
                    </fieldset>

                    {this.state.recurringTripComponents.map(comp => {
                        return comp
                    })}

                    <input 
                        type={"button"}
                        name={"addNewTrip"}
                        value={"+ Add Another Trip Day"}
                        onClick={e => {
                            this.collectFormValues(e.target)
                        }}/>

                    
                    <LocationPicker 
                        type={"Pickup"} 
                        tripNumber={1}
                        key={"pu_place_fk"}
                        requiredSymbol={true}
                        getLocationFk={this.collectFormValues.bind(this)} 
                        name={"pu_place_fk"}
                        defaultLocationId={this.props.riderAddressId}
                        filterOut={[this.state.newBooking.do_place_fk]} 
                        required={true} />

                    <LocationPicker 
                        type={"Drop-off"} 
                        tripNumber={1} 
                        key={"do_place_fk" }
                        requiredSymbol={true}
                        getLocationFk={this.collectFormValues.bind(this)} 
                        name={"do_place_fk" }
                        filterOut={[this.state.newBooking.pu_place_fk]}
                        required={true} />

                    <label id={"passengersLabel"} htmlFor={"passengers"}><span>*</span> How many escorts will be riding?</label>
                    <select 
                        name={"passengers"} 
                        onChange={(e) => this.collectFormValues(e.target)}
                        onBlur={(e) => this.collectFormValues(e.target)}
                        defaultValue={""}
                        required>
                        <option disabled value={""}> -- select a number -- </option>
                        <option value={"0"}>0</option>
                        <option value={"1"}>1</option>
                        <option value={"2"}>2</option>
                        <option value={"3"}>3</option>
                        <option value={"4"}>4</option>
                        <option value={"5"}>5</option>
                    </select>
                    
                    <label htmlFor={"mode"}><span>*</span> Mobility Status:</label>
                    <select 
                        name={"mode"} 
                        onChange={(e) => this.collectFormValues(e.target)}
                        onBlur={(e) => this.collectFormValues(e.target)}
                        defaultValue={""}
                        required>
                        <option disabled value={""}> -- select an option -- </option>
                        <option value={"AMB"}>Ambulatory</option>
                        <option value={"WC"}>Wheelchair</option>
                        <option value={"PWC"}>Power Wheelchair</option>
                    </select>

                    <LevelOfService 
                            onChange={this.collectFormValues.bind(this)}
                            onBlur={this.collectFormValues.bind(this)} />
                    
                    <Phone
                        name="phone1"
                        displayName="Phone" 
                        collectValue={this.collectFormValues.bind(this)}
                        phoneRequired={true}/>
                    
                    {!this.state.secondPhoneVisible &&
                        <input 
                            type={"button"}
                            id={"secondPhoneButton"}
                            value={"+ Add a second phone"}
                            onClick={ () =>
                                this.setState({secondPhoneVisible : true})
                            }/>
                    }

                    {this.state.secondPhoneVisible &&
                    <Phone
                        name="phone2"
                        displayName="Phone 2" 
                        collectValue={this.collectFormValues.bind(this)}
                        phoneRequired={false}/>
                    }


                    <label 
                       htmlFor={"notes"}
                       id={"notes"} >Driver Notes:</label>
                    <textarea 
                        name={"notes"}
                        onChange={(e) => this.collectFormValues(e.target)}
                        onBlur={(e) => this.collectFormValues(e.target)} />

                    {this.props.riderHasPT1s && 
                    <>
                        <label htmlFor="addPT1">
                        <input 
                            type={"checkbox"} 
                            name={"addPT1"} 
                            onChange={(e) => this.collectFormValues(e.target)}
                            onBlur={(e) => this.collectFormValues(e.target)} />
                            &nbsp; Add a PT1 Form to this Trip (MassHealth)?
                        </label>
                    </>}

                    <input 
                        type={"submit"} 
                        value={this.state.addPT1 ? "Next" : "Book Trip"} 
                        id={"bookingFormSubmit"}
                        onClick={(e) => {
                            this.submitForm(e)}}></input>
                </form>
            </section>
        )
    }
}

export default RecurringTripBookingForm