import React, { Component } from "react"
import axiosInstance from "../../axios";
import CloseIcon from '@material-ui/icons/Close';
import LocationPicker from "../partsAndFields/LocationPicker";
import Phone from '../partsAndFields/Phone'
import {
    validateName,
    validateMassHealthProviderIDOrSL,
} from "../../validation.js"

class UpdateRiderModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            riderUpdate: {
                id:parseInt(this.props.rider.id),
                firstname: this.props.rider.firstname,
                middlename: this.props.rider.middlename,
                lastname: this.props.rider.lastname,
                address: isNaN(parseInt(this.props.rider.address_fk)) ? "" : parseInt(this.props.rider.address_fk),
                mh_member_id:this.props.rider.mh_member_id,
                phone: this.props.rider.phone,
                dob: this.props.rider.dob,
                sex: this.props.rider.sex,
                agency: localStorage.getItem("agn"),
                veteran: this.props.rider.veteran === "true" ? true : false,
            },
            duplicateError:"",
            address:this.props.rider.address === null ? "" : this.props.rider.address,
        }
    }

    componentDidMount(){
        document.getElementsByTagName("button")[0].focus()
    }

    /**
     * Submit the values in the add rider modal. Then re-render the list of riders
     * 
     * @param {Event} submitButton 
     */
     async onFormSubmit(e) {
        e.preventDefault();
        let newRiderFinal = this.state.riderUpdate
        if (isNaN(newRiderFinal.address)){
            newRiderFinal.address = ""
        }
        e.target.disabled = true
        
        if (!this.checkForm())
        {
            let loader = document.createElement("div")
            loader.classList.add("loader")
            document.body.appendChild(loader)      
            
            await axiosInstance.post(
                "riders/create",
                newRiderFinal)
                .then((res) => {
                    this.props.handleClick(e);
                    this.props.onAddRider(e) // will pass up to parent to get the riders again    
                    loader.remove()
                })
                .catch(error => {
                    if (error.response.status === 400){
                        this.setState({duplicateError:"Rider already exists"})
                    }
                    e.target.disabled = false
                    loader.remove()                   
                })
        }
        e.target.disabled = false 
    }

    checkForm(){
        let notReady = false

        // reset server errors
        this.setState({duplicateError:""})

        const fieldApply = (errorField) => {
            errorField.classList.add("errorField")
            errorField.previousSibling.classList.add("errorLabel")
            notReady = true            
        }

        // check for empty fields
        const requiredStrings = ["firstname", "lastname", "dob"]
        for (const field of requiredStrings){
            
            let errorFields = document.getElementsByName(field)
            for (let errorField of errorFields){
                errorField.classList.remove("errorField")
                if (errorField.value === "" || errorField.value === 0){
                    fieldApply(errorField)
                }
            }
        }
        // for radio inputs, as they dont follow standard input/label structure
        let requiredRadios = ["sex"]
        requiredRadios = Array
            .from(new Set(requiredRadios
                .map(string => {
                    return document.getElementsByName(string)[0].parentElement
                })
            )
        )
        for (let radParent of requiredRadios){
            radParent.classList.remove("errorField")
            radParent.children[0].classList.remove("errorLabel")
            let checkedInput = Array.from(radParent.children)
                                .map(elem => {
                                    if (elem.tagName === 'INPUT' 
                                    && elem.checked === true){                                        
                                        return elem
                                    }
                                    return undefined
                                    }).filter(elem => elem !== undefined)
            if (radParent.classList.contains('radioContainer')
            && checkedInput.length !== 1){
                radParent.classList.add("errorField")
                radParent.children[0].classList.add("errorLabel")
            }
        }

        

        return notReady
    }

    /**
     * Update state with form values
     * @param {EventTarget} event the change of the form field
     */
    inputChange(target) {
        let riderUpdateCopy = this.state.riderUpdate
        switch (target.name) {
            case "firstname":
                riderUpdateCopy.firstname = ""
                if(validateName(target)){
                    riderUpdateCopy.firstname = target.value
                }
                break
            case "middlename":
                riderUpdateCopy.middlename = ""
                if(validateName(target)){
                    riderUpdateCopy.middlename = target.value
                }
                break
            case "lastname":
                riderUpdateCopy.lastname = ""
                if(validateName(target)){
                    riderUpdateCopy.lastname = target.value
                }
                break
            case "address":
                riderUpdateCopy.address = parseInt(target.dataset.value)
                    break
            case "mh_member_id":
                riderUpdateCopy.mh_member_id = ""
                if(validateMassHealthProviderIDOrSL(target)){
                    riderUpdateCopy.mh_member_id = target.value
                }
                break
            case "phone":
                riderUpdateCopy.phone = parseInt(target.dataset.value)
                break
            case "sex":
                riderUpdateCopy.sex = target.value
                break
            case "dob":
                riderUpdateCopy.dob = target.value
                break
            case "veteran":
                riderUpdateCopy.veteran = !riderUpdateCopy.veteran
                break
            default:
                console.log("error")
        }
        this.setState({ riderUpdate: riderUpdateCopy })
    }

    getDate(ahead = 0){
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() + ahead);
        return `${currentDate.getFullYear()}-${('0' + (currentDate.getMonth() + 1)).slice(-2)}-${('0' + currentDate.getDate()).slice(-2)}`
    }

    render() {
        return (
            <dialog 
                open={this.props.toggle} 
                className={"addRiderModal"}>
                
                <h1>Update Rider - {`${this.props.rider.lastname}, ${this.props.rider.firstname} (${this.props.rider.id})`}
                    <button 
                    onKeyDown={(e) => e.preventDefault()}
                        onClick={(e) => {
                            e.preventDefault()
                            this.props.handleClick()
                            }}><CloseIcon/></button>
                </h1>

                <form className={"modal"} onSubmit={(e) => e.preventDefault()}>
                    <label 
                        htmlFor={"firstname"}
                        id={"firstname"} ><span>* </span>First name</label>
                    <input 
                        type={"text"} 
                        name={"firstname"} 
                        defaultValue={this.state.riderUpdate.firstname} 
                        onChange={(e) => this.inputChange(e.target)} 
                        onBlur={(e) => this.inputChange(e.target)} 
                        required/>

                    <label 
                        htmlFor={"middlename"}
                        id={"middlename"}>Middle name</label>
                    <input 
                        type={"text"} 
                        name={"middlename"} 
                        defaultValue={this.state.riderUpdate.middlename}
                        onChange={(e) => this.inputChange(e.target)}
                        onBlur={(e) => this.inputChange(e.target)} />

                    <label 
                        htmlFor={"lastname"}
                        id={"lastname"} ><span>* </span>Last name</label>
                    <input 
                        type={"text"} 
                        name={"lastname"} 
                        defaultValue={this.state.riderUpdate.lastname}
                        onChange={(e) => this.inputChange(e.target)} 
                        onBlur={(e) => this.inputChange(e.target)} 
                        required />
                    
                    <label 
                        htmlFor={"mh_member_id"}
                        id={"mh_member_id"} >MassHealth provider ID/service location</label>
                    <input 
                        type={"text"} 
                        name={"mh_member_id"} 
                        defaultValue={this.state.riderUpdate.mh_member_id}
                        onChange={(e) => this.inputChange(e.target)} 
                        onBlur={(e) => this.inputChange(e.target)} 
                        required />
                    
                    <label htmlFor={"dob"}
                        id={"dob"}><span>*</span> Date of birth:</label>
                    <input 
                        type={"date"} 
                        name={"dob"} 
                        max={this.getDate(0)}
                        defaultValue={this.state.riderUpdate.dob}
                        onChange={(e) => this.inputChange(e.target)}
                        onBlur={(e) => this.inputChange(e.target)}
                        required />

                    <fieldset className={"radioContainer"}>
                            <legend><span>*</span> Sex:</legend>
                            <input type={"radio" }
                                name={"sex" }
                                defaultChecked={this.state.riderUpdate.sex === 'M' ? true : false}
                                onChange={(e) => this.inputChange(e.target)}
                                onBlur={(e) => this.inputChange(e.target)}
                                value={"M"}
                                required/>
                            <label htmlFor={"sex"}>Male</label>

                            <input type={"radio" }
                                name="sex" 
                                defaultChecked={this.state.riderUpdate.sex === 'F' ? true : false}
                                onChange={(e) => this.inputChange(e.target)}
                                onBlur={(e) => this.inputChange(e.target)}
                                value={"F"}
                                required/>
                            <label htmlFor={"sex"}>Female</label>
                    </fieldset>

                    <Phone
                        name="phone"
                        displayName="Phone number" 
                        collectValue={this.inputChange.bind(this)}
                        phoneRequired={true}
                        defaultValue={this.state.riderUpdate.phone}/>
                    
                    {this.state.riderUpdate.address !== ""
                    ? <LocationPicker 
                        type={"Default"} 
                        tripNumber={0}
                        requiredSymbol={false}
                        getLocationFk={this.inputChange.bind(this)}
                        defaultLocationId={this.state.riderUpdate.address_fk}
                        defaultLocation={this.state.address}
                        name="address" 
                        required={false} />
                    : <LocationPicker 
                        type={"Default"} 
                        tripNumber={0}
                        requiredSymbol={false}
                        getLocationFk={this.inputChange.bind(this)}
                        name="address" 
                        required={false} /> }
                        
                    <label 
                        htmlFor={"veteran"}>Veteran</label>
                    <input 
                        type={"checkbox"} 
                        name={"veteran"}
                        defaultChecked={this.state.riderUpdate.veteran}
                        onChange={(e) => this.inputChange(e.target)} 
                        required />
                    
                    {this.state.duplicateError !== ""
                    && <span className={"errorSpan"} style={{"width":"100%", "float":"left", "margin":"0.5rem 2rem 3rem"}}>
                            {this.state.duplicateError}
                        </span>
                    }
                    

                    <input 
                        type={"submit"} 
                        value={"Update Rider"}
                        id={"addRiderSubmit"}
                        onClick={(e) =>{ 
                            e.preventDefault()
                        this.onFormSubmit(e)}}/>
                </form>
            </dialog>
        )
    }
}

export default UpdateRiderModal