import React, { Component } from "react"
import DayOfWeekPicker from "./DayOfWeekPicker"
import TimePicker from "./TimePicker";
import CloseIcon from '@material-ui/icons/Close';
import { dateIsValidForBooking } from "../../validation";


class RecurringTripPicker extends Component{
    constructor(props){
        super(props)
        this.state = {
            selectedDay:["", ""],
            weeksOut:2,
            currentDay:"",
            availableDates: [],
            startDate:"",
            endDate:this.formatDate(this.getDateSixMonthsFromNow()),
            ptime:"",
            r_ptime:"",
            returnTrip: false,
            startDatesReadOnly:true,
        }
    }

    componentDidMount(){
    }

    updateParent(){
    }

    async collectFormValues(target){
        let stateCopy = this.state
        let changer = target.parentElement
        switch (target.name){
            case "dayOfWeek":
                stateCopy.selectedDay= [target.value, target.dataset.id]
                    let potentialDates = await this.getStartDates(parseInt(target.dataset.id), new Date(), this.state.weeksOut)
                    stateCopy.availableDates = potentialDates.map( date => {
                                    return(
                                        <option key={date} value={date}>{date}</option>
                                    )
                                }
                        )
                stateCopy.startDatesReadOnly = false
                changer = target.parentElement.parentElement.parentElement
                break
            case "start_date":
                stateCopy.startDate = target.value
                break
            case "end_date":
                stateCopy.endDate = target.value
                break
            case "ptime":
                stateCopy.ptime = target.dataset.value
                break
            case "r_ptime":
                stateCopy.r_ptime = target.dataset.value
                break
            case "returnTrip":
                stateCopy.returnTrip = target.value === "true" ? true : false
                break
            default:
                console.log(`error on 1${target}`)
        }
        this.setState(stateCopy, () =>{
            this.props.onChange(changer)

        })
    }

    
    /**
     * 
     * @param {Number} dayOfWeek number that maps to day of week sun=0 - sat 0=6
     * @param {Date} currentDate current date
     * @param {Number} weeksOut how many weeks the dates can range too
     * @returns 
     */ 
    async getStartDates(dayOfWeek, currentDate, weeksOut){
        // holds all date for next two weeks
        currentDate.setDate(currentDate.getDate() + 1)
        let dates = Array((weeksOut*6)+1).fill(currentDate)
        // holds every occurrence of specified day of week in range of two weeks of current date
        let newDates = []      
        for (let newWeek = 0; newWeek < (weeksOut*6)+1; newWeek++){
            dates[newWeek].setDate(dates[newWeek].getDate() + 1)
            if (dates[newWeek].getDay() === dayOfWeek){
                if (await dateIsValidForBooking(this.formatDate(dates[newWeek]), false)){
                    newDates.push(this.formatDate(dates[newWeek]))
                }
            }
        }
        return newDates
    }

    getMinDate(date){
        let newDate = new Date(`${date}T00:00:00`)
        newDate.setDate(newDate.getDate() + 13)
        return this.formatDate(newDate)
    }

    formatDate(date){
       return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`
    }

    getTime(){
        const today = new Date();
        const minutes = (Math.round(today.getMinutes()/15) * 15) % 60
        return `${(today.getHours() <= 9 ? `0${today.getHours()}` : today.getHours())}:${minutes === 0 ? `${minutes}0` : minutes}`
    }

    getDateSixMonthsFromNow(){
        const today = new Date()
        let month = parseInt(today.getMonth())
        return new Date(`${today.getFullYear()}-${month+6}-${today.getDate()}`)
    }

    render(){
        return(
            <fieldset 
                className={"bookingForm recurringTripPicker"}
                id={`rt${this.props.id}`}
                data-id={this.state.selectedDay[1]}
                data-day={this.state.selectedDay[0]}
                data-start-date={this.state.startDate}
                data-end-date={this.state.endDate}
                data-ptime={this.state.ptime}
                data-r_ptime={this.state.r_ptime}
                name={"recurringTripPicker"}>

                {this.props.id !== 1 && <button 
                        onClick={ (e) => {
                            e.preventDefault()
                            this.props.removeRTrip()
                        }
                    }
                        data-id={"rtCloser"} ><CloseIcon/></button>}

                <legend id={`${this.props.name}Label`}>Recurring Trip {this.props.id !== 1 && this.props.id}</legend>
                
                <DayOfWeekPicker
                    onClick={this.collectFormValues.bind(this)} 
                    daysOfTheWeek={this.props.daysOfTheWeek} 
                    id={this.props.id} />

                <label 
                    id={"start_date_label"}
                    htmlFor={"start_date"}><span>*</span> Start Date</label>
                <select 
                    name={"start_date"}
                    className={this.state.selectedDay[0] === "" ? "noPoint" : ""}
                    onChange={async (e) => await this.collectFormValues(e.target)}
                    onBlur={async (e) => await this.collectFormValues(e.target)}
                    defaultValue={""}
                    readOnly={this.state.selectedDay[0] === "" ? true : false}
                    tabIndex={this.state.selectedDay[0] === "" ? -1 : 0}
                    required>
                    <option disabled value={""}> -- select a date -- </option>
                    {this.state.availableDates}
                </select>

                <label 
                    id={"end_date_label"}
                    htmlFor={"end_date"}>End Date</label>
                <input 
                    type={"date"}
                    name={"end_date"}
                    className={this.state.startDate === "" ? "noPoint" : ""}
                    readOnly={this.state.startDate === "" ? true : false}
                    tabIndex={this.state.startDate === "" ? -1 : 0}
                    min={
                        this.getMinDate(this.state.startDate)
                        }
                    onChange={async (e) => await this.collectFormValues(e.target)}
                    onBlur={async (e) => await this.collectFormValues(e.target)}
                    defaultValue={this.formatDate(this.getDateSixMonthsFromNow())} />
                
                <label 
                    id={"ptimeLabel"}
                    htmlFor={"ptime"}><span>* </span>Pickup time</label>
                <TimePicker 
                    currentTime={this.getTime()} 
                    defaultValue={""}
                    readOnly={this.state.startDate === "" ? true : false}
                    name={"ptime"} 
                    key={"ptime"} 
                    minTime={"00:00"}
                    collectValue={this.collectFormValues.bind(this)}
                    required={true} />
                

                <label 
                    id={"returnTripLabel"} 
                    htmlFor={"returnTrip"}><span>* </span>Return Trip?</label>
                <select 
                    name={"returnTrip"}
                    className={this.state.selectedDay[0] === "" ? "noPoint" : ""}
                    readOnly={this.state.selectedDay[0] === "" ? true : false}
                    tabIndex={this.state.selectedDay[0] === "" ? -1 : 0}
                    onChange={async (e) => await this.collectFormValues(e.target)}
                    onBlur={async (e) => await this.collectFormValues(e.target)}
                    defaultValue={"No"}
                    required>
                    <option disabled value={""}> -- select -- </option>
                    <option value={false}>No</option>
                    <option value={true}> Yes</option>
                </select>

                {this.state.returnTrip 
                && <React.Fragment>
                        <label 
                            id={"r_ptimeLabel"}
                            htmlFor={"r_ptime"}>Return Pickup time</label>
                        <TimePicker 
                            currentTime={this.getTime()} 
                            defaultValue={""}
                            name={"r_ptime"} 
                            key={"r_ptime"} 
                            minTime={this.state.ptime}
                            collectValue={this.collectFormValues.bind(this)}
                            required={false}
                            />
                    </React.Fragment>
                }
                

            </fieldset>
        )
    }
}

export default RecurringTripPicker