import React, { Component } from "react"
import axiosInstance  from "../../axios"

class Register extends Component {
    constructor(props) {
        super(props)
        this.state = {
           user: {
                name: "",
                email: "",
                password: "",
            },
            envName:process.env.REACT_APP_APP_NAME,
        }
    }
    
    componentDidMount(){
        this.testEnv()
    }

    testEnv(){
        const devEnvNames = ["test", "dev", "demo"]
        for (let name of devEnvNames){
            if (process.env.REACT_APP_API_DOMAIN.includes(name)){
                this.setState({envName : "DEV ENV"})
            }
        }
      }

    /**
     * Submit the values in the add location modal. Then re-render the list od locations
     * 
     * @param {Event} submitButton 
     */
    onFormSubmit(submitButton) {
        submitButton.preventDefault();

        let loader = document.createElement("div")
        loader.classList.add("loader")
        document.body.appendChild(loader)

        let newUserFinal = Object.freeze(this.state.user)
        axiosInstance.post(
            "users/register/",
            newUserFinal)
            .then(() => {
                this.props.history.push("/")
                loader.remove()                
            })
            .catch(response => {
                console.log(response)
                // keep the values in the state, as they are auto kept in the DOM
                this.setState(
                    {
                        user: {
                            name: newUserFinal.name,
                            email: newUserFinal.email,
                            password: newUserFinal.password,
                        }
                    }
                )
            })
    }

    /**
     * Update the state with form field values
     * @param {Event} event the input event that is changed
     */
    textInputChange(event) {
        let newUser= this.state.user
        switch (event.target.name) {
            case "username":
                newUser.name = event.target.value
                break
            case "email":
                newUser.email = event.target.value
                break   
            case "password":
                newUser.password = event.target.value
                break
            default:
                console.log(`Error on ${event}`)
        }
        this.setState({ user: newUser })
    }

    render() {
        return (
            <section className={"entry"}>
                <form>
                    <h1>Register for {this.state.envName}</h1>
                        <label 
                            htmlFor={"username"}
                            className={"semanticVisible"}
                            >Username</label>
                        <input 
                            type={"text"} 
                            name={"username"}
                            placeholder={"Username"}
                            onChange={(e) => this.textInputChange(e)} 
                            required />

                        <label 
                            htmlFor={"email"}
                            className={"semanticVisible"}
                            >Email</label>
                        <input 
                            type={"email"} 
                            name={"email"}
                            placeholder={"Email"}
                            onChange={(e) => this.textInputChange(e)} 
                            required />

                        <label 
                            htmlFor={"password"}
                            className={"semanticVisible"}
                            >Password</label>
                        <input 
                            type={"password"} 
                            name={"password"} 
                            placeholder="Password" 
                            onChange={(e) => this.textInputChange(e)} 
                            required />

                        <input 
                            type={"submit"} 
                            value={"Register"} 
                            onClick={(e) => this.onFormSubmit(e)} />
                </form>
            </section>
        )
    }
}

export default Register