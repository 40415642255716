import React, { Component } from "react";
import MenuIcon from '@material-ui/icons/Menu';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import { NavLink } from "react-router-dom";

class Nav extends Component {
    constructor(props) {
        super(props)
        this.state = {
            navOpen: false, 
            envName:process.env.REACT_APP_APP_NAME,
        }
    }

    componentDidMount(){
        this.testEnv()
    }

    toggleMenu() {
        this.setState({ navOpen: !this.state.navOpen })

    }

    testEnv(){
        const devEnvNames = ["test", "dev", "demo"]
        for (let name of devEnvNames){
            if (process.env.REACT_APP_API_DOMAIN.includes(name)){
                this.setState({envName : "DEV ENV"})
            }
        }
      }

    render() {
        return (
            <nav>
                <ul>
                    <li>{this.state.envName}</li>
                    <li>
                        <button onClick={() => this.props.backToTrips()}>
                        <DoubleArrowIcon />
                            <span>Back to Trips Dashboard</span>
                        </button>
                    </li>
                    <li>
                        <button onClick={() => this.toggleMenu()}>                             
                            {this.state.navOpen ? <MenuOpenIcon/> : <MenuIcon/>} </button>
                        {this.state.navOpen && (
                            <ul>
                                <li>
                                    <strong>User: </strong>{localStorage.getItem("user")}</li>
                                <li>
                                <strong>Agency: </strong>{localStorage.getItem("agn")}</li>
                                <li >
                                    <button onClick={() => this.props.backToTrips()}>
                                        <span style={{textDecoration:"underline", fontSize:"1rem"}}>Trips Dashboard</span>
                                    </button>
                                </li>
                                <li >
                                    <button onClick={() => localStorage.clear()}>
                                        <NavLink 
                                        to="/"> Logout </NavLink>
                                    </button>
                                    
                                </li>
                            </ul>)}
                    </li>
                </ul>
            </nav>
        )
    }
}

export default Nav